import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading/Loading";
import { useOrganizationContext } from "utils/userContext";

function AssessmentOnboardingPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = useCallback(
        (path: string) => {
            // Somehow we need to use setTimeout to make this work
            setTimeout(() => navigate(path), 0);
        },
        [navigate]
    );

    const organization = useOrganizationContext();

    const isZeroLocations = useMemo(
        () => organization?.allLocationsCount === 0,
        [organization]
    );

    useEffect(() => {
        if (organization && isZeroLocations) {
            handleNavigate("/secret");
        } else if (organization) {
            handleNavigate("/portfolio");
        }
    }, [handleNavigate, organization, isZeroLocations]);

    return <Loading description={t("loading.title", "Loading")} />;
}

export default AssessmentOnboardingPage;
