import { Box, styled, useTheme } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useActiveAssetGroup } from "components/AssetsGroup/hooks/useActiveAssetGroup";
import { AssessmentDataType } from "graphql-types/graphql";
import { useOrganizationContext } from "utils/userContext";

import TargetEmissionsLabels from "./TargetEmissionLabels";
import Loading from "../Loading/Loading";
import { AssetsShowingBox } from "../Report/ReportContent";
import TargetSettingModal from "../TargetSettings/TargetSettingsModal";
import useTargetSettings from "../TargetSettings/useTargetSettings";
import { i18nJoinItems } from "../TranslationHelpers/TranslationHelpers";
import YearAndAssessmentTypeFilter from "../YearAndAssessmentTypeFilter/YearAndAssessmentTypeFilter";

type InputProps = {
    activeTab: AssessmentDataType;
    baselineYear: number | null;
    handleChange: (value: AssessmentDataType) => void;
    activeIntegrationTypes: string[];
};

const NavigateInfo = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
}));

const ForecastDashboardHeader = ({
    activeTab,
    handleChange,
    baselineYear,
    activeIntegrationTypes,
}: InputProps) => {
    const theme = useTheme();

    const { t } = useTranslation(["translation", "report"]);

    const { loading } = useTargetSettings();
    const organization = useOrganizationContext();

    const [activeAssetGroup] = useActiveAssetGroup();

    return (
        <Box sx={{ mb: 4 }}>
            <NavigateInfo>
                <Box flexDirection="column">
                    <Box fontSize="26px" fontWeight="bold">
                        {t("pageheader.forecasting", "Forecasting")}
                    </Box>
                    <Box
                        color={theme.palette.grey[400]}
                        fontSize="14px"
                        fontStyle="italic"
                        marginTop={2}
                    >
                        {activeTab === AssessmentDataType.ACTUAL
                            ? `* ${t(
                                  "forecastDashboard.chartSubtitle.meterDataSources",
                                  "Values are based on Electricity, Heat, Gas, Cooling and Water from {{sources}}",
                                  {
                                      sources: i18nJoinItems(
                                          t,
                                          activeIntegrationTypes.map(
                                              _.capitalize
                                          )
                                      ),
                                  }
                              )}`
                            : `* ${t(
                                  "forecastDashboard.chartSubtitle.epcDataSources",
                                  "Values are based on Electricity and Heat from EPCs"
                              )}`}
                    </Box>
                    <TargetSettingModal baselineYear={baselineYear} />
                    <AssetsShowingBox>
                        {t(
                            "labels.outOfLocations",
                            "Showing {{count}} location out of {{total}} locations",
                            {
                                count: activeAssetGroup.count,
                                total: organization?.allLocationsCount,
                                ns: "report",
                            }
                        )}
                    </AssetsShowingBox>
                </Box>
                {loading && (
                    <Loading
                        horizontal={true}
                        description={t("loading.title", "Loading")}
                    />
                )}
                <YearAndAssessmentTypeFilter
                    isYearSelectorHidden
                    activeType={activeTab}
                    onAssessmentTypeChange={handleChange}
                />
            </NavigateInfo>
            {baselineYear && (
                <TargetEmissionsLabels baselineYear={baselineYear} />
            )}
        </Box>
    );
};

export default ForecastDashboardHeader;
