import { useQuery } from "@apollo/client";

import { FilterItemOption, FilterValue } from "components/AssetsGroup/types";
import {
    AssetGroupFilterCategory,
    GetAssetsGroupLocationsQuery,
} from "graphql-types/graphql";

import { LOCATIONS_QUERY } from "./location.query";
import { FilterOptionSelect } from "../../OptionLayout";

type Props = {
    onChange: (filter: FilterItemOption) => void;
};

export function FilterOptionLocationId(props: Props) {
    const { onChange } = props;
    const { data } = useQuery<GetAssetsGroupLocationsQuery>(LOCATIONS_QUERY);

    const locationList =
        data?.me.organization?.getLocations?.map((item) => ({
            key: item.id,
            value: item.displayName ?? item.externalId ?? item.id,
        })) || [];

    function onSelectedValue(value: FilterValue) {
        onChange({
            category: AssetGroupFilterCategory.ID,
            values: [value],
        });
    }

    return (
        <FilterOptionSelect
            list={locationList}
            onSelectedValue={onSelectedValue}
        />
    );
}
