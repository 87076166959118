import { useQuery } from "@apollo/client";
import { Box, Button, Paper } from "@mui/material";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { TFunction } from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DataStatusCell } from "components/Cells/DataStatusCell";
import { DownloadCell } from "components/Cells/DownloadCell";
import NationalBuildingIdsCell from "components/Cells/NationalBuildingIdsCell";
import { Table, TablePagination } from "components/Table";
import { dateSorter } from "containers/DataOverviewPage/dataOverview.helper";
import {
    GetAssetAutomaticSourcesQuery,
    GetAssetAutomaticSourcesQueryVariables,
} from "graphql-types/graphql";
import { assessmentVerticalToTitle } from "utils/assessment";
import { validateAndFormatDateString } from "utils/date.utils";

import {
    getAutomaticSourceStatus,
    getAutomaticSourceToDate,
    getAutomaticSourceType,
} from "./AssetSources.helpers";
import { ASSET_AUTOMATIC_SOURCES } from "./assetSourcesQuery";
import { ASSET_PAGE_HEADER_HEIGHT } from "./ManualSourcesTable";

const getColumns = (t: TFunction): GridColDef[] => {
    return [
        {
            field: "type",
            headerName: t("columns.type", "Type", { ns: "report" }),
            flex: 0.5,
            sortable: true,
        },
        {
            field: "addresses",
            headerName: t("columns.addresses", "Addresses", { ns: "report" }),
            flex: 1,
            sortable: true,
        },
        {
            field: "verticals",
            headerName: t("columns.verticals", "Verticals", { ns: "report" }),
            flex: 0.75,
            sortable: true,
        },
        {
            field: "source",
            headerName: t("common.labels.source", "Source"),
            flex: 0.75,
            sortable: true,
        },
        {
            field: "identifier",
            headerName: t("columns.identifierLong", "Identifier", {
                ns: "report",
            }),
            flex: 0.75,
            sortable: true,
        },
        {
            field: "nationalBuildingId",
            headerName: t(
                "columns.nationalBuildingId",
                "National Building ID",
                { ns: "report" }
            ),
            renderCell: (params) =>
                params.value &&
                NationalBuildingIdsCell(params.value.split(",")),
            flex: 0.75,
            sortable: true,
        },
        {
            field: "supplier",
            headerName: t("columns.supplier", "Supplier", { ns: "report" }),
            width: 150,
            sortable: true,
        },

        {
            field: "from",
            headerName: t("common.timeframe.from", "From", {
                ns: "translation",
            }),
            flex: 0.5,
            sortComparator: dateSorter,
            sortable: true,
        },
        {
            field: "to",
            headerName: t("common.timeframe.to", "To", { ns: "translation" }),
            flex: 0.5,
            sortComparator: dateSorter,
            sortable: true,
        },
        {
            field: "status",
            headerName: t("columns.status", "Status"),
            flex: 1,
            renderCell: ({ value }) => DataStatusCell({ status: value, t }),
            sortable: true,
        },
        {
            field: "documentation",
            headerName: t("columns.documentation", "Documentation", {
                ns: "report",
            }),
            headerAlign: "center",
            flex: 0.5,
            align: "right",
            disableExport: true,
            renderCell: ({ value }) =>
                value ? DownloadCell({ downloadLink: value }) : null,
        },
    ];
};

function AssetSourcesTable({ locationId }: { locationId: string }) {
    const { t } = useTranslation(["report"]);

    const { data, loading } = useQuery<
        GetAssetAutomaticSourcesQuery,
        GetAssetAutomaticSourcesQueryVariables
    >(ASSET_AUTOMATIC_SOURCES, {
        variables: { locationId },
    });

    const rows = useMemo(() => {
        const sources = data?.location.automaticDataSources ?? [];

        return sources.map((source) => ({
            ...source,
            type: getAutomaticSourceType(source, t),
            from: validateAndFormatDateString(source.from),
            to: getAutomaticSourceToDate(source, t),
            verticals: source.verticals.map((vertical) =>
                assessmentVerticalToTitle(vertical, t)
            ),
            status: getAutomaticSourceStatus(source.status, t),
        }));
    }, [data, t]);

    const columns = useMemo(() => getColumns(t), [t]);

    const apiRef = useGridApiRef();

    const fileName = `${data?.location.displayName} - ${t(
        "automaticSources.fileName",
        "automatic_sources",
        { ns: "translation" }
    )}`;

    return (
        <Box>
            <Box
                sx={{ display: "flex", justifyContent: "space-between", pb: 3 }}
            >
                <Box />
                <Box sx={{ display: "flex", gap: 5, alignItems: "center" }}>
                    <span>
                        {rows.length}{" "}
                        {t("pageheader.data", "Data Sources", {
                            ns: "translation",
                        })}
                    </span>
                    <Button
                        variant="contained"
                        onClick={() =>
                            apiRef.current.exportDataAsCsv({
                                fileName,
                            })
                        }
                    >
                        {t("common.download", "Download", {
                            ns: "translation",
                        })}
                    </Button>
                </Box>
            </Box>
            <Paper sx={{ p: 1 }}>
                <Table
                    columns={columns}
                    rows={rows}
                    setCustomHeight={`calc(100vh - ${ASSET_PAGE_HEADER_HEIGHT}px)`}
                    minHeight="400px"
                    apiRef={apiRef}
                    slotProps={{
                        pagination: {
                            ActionsComponent: TablePagination,
                        },
                    }}
                    isLoading={loading}
                />
            </Paper>
        </Box>
    );
}

export default AssetSourcesTable;
