import { DocumentNode, useQuery } from "@apollo/client";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useActiveAssetGroup } from "components/AssetsGroup/hooks/useActiveAssetGroup";
import { AssessmentDataType, ReportFramework } from "graphql-types/graphql";

import { getReportLanguageFromI18n } from "../ReportContentHelpers";
import { useReportDateContext } from "../reportDateContext";

export const useReportQuery = <T,>(
    query: DocumentNode,
    dateType: AssessmentDataType,
    framework: ReportFramework
) => {
    const { i18n } = useTranslation();
    const { from, to } = useReportDateContext();

    const [activeAssetGroup] = useActiveAssetGroup();

    const language = getReportLanguageFromI18n(i18n.language);

    const { data, loading, refetch, observable } = useQuery<T>(query, {
        variables: {
            input: {
                to,
                from,
                source: dateType,
                numberOfAssets: activeAssetGroup.count,
                segmentName: activeAssetGroup.name,
                framework,
                language,
            },
        },
        context: { headers: { "x-lang": i18n.language } },
    });

    const handleLanguageChange = useCallback(() => {
        observable.setOptions({
            context: { headers: { "x-lang": i18n.language } },
        });

        refetch();
    }, [i18n, observable, refetch]);

    useEffect(() => {
        i18n.on("languageChanged", handleLanguageChange);
        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, [i18n, handleLanguageChange]);

    return {
        data,
        loading,
    };
};
