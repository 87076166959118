import { TFunction } from "i18next";
import _ from "lodash";
import { DateTime } from "luxon";

import { AssessmentDataType, TargetEmission } from "graphql-types/graphql";
import {
    formatNumberLocale,
    getConvertedGramsToMassUnit,
} from "utils/report.helpers";
import { DASH_SEPARATOR } from "utils/strings.helpers";

import {
    emissionUnit,
    emissionLabel,
} from "../../PortfolioDashboard/helpers/chartHelpers";
import { getAssessmentTypeEmission } from "../../PortfolioDashboard/helpers/utilities";
import { AssessmentsSummaryUnion, Locations, ChartType } from "../types";

const co2eYearLabel = "CO2e / year";

const transformedEmission = (
    type: AssessmentDataType,
    assessments: AssessmentsSummaryUnion[],
    t: TFunction
) => {
    const emission = _.sumBy(assessments, (a) =>
        "emissionGrams" in a
            ? getAssessmentTypeEmission(type, a.emissionGrams) || 0
            : getAssessmentTypeEmission(type, a.emissionDataGrams.emission) || 0
    );

    const value = getConvertedGramsToMassUnit(emission, emissionUnit);
    return {
        value,
        label: `${formatNumberLocale(
            value,
            t
        )} ${emissionLabel} ${co2eYearLabel}`,
    };
};

const getIsStrandedFromTargetEmission = (
    targetEmission: AssessmentsSummaryUnion | undefined,
    type: AssessmentDataType
) => {
    if (!targetEmission) {
        return false;
    }

    const { emissionGrams } = targetEmission as TargetEmission;

    switch (type) {
        case AssessmentDataType.ESTIMATE:
            return emissionGrams.epc.isStranded || false;
        case AssessmentDataType.ACTUAL:
            return emissionGrams.meter.isStranded || false;
        case AssessmentDataType.COMBINED:
            return emissionGrams.bestEffort.isStranded || false;
    }
};

export const getYearlyEmissionSummary = (
    locations: Locations[],
    t: TFunction,
    type: AssessmentDataType,
    hasTargetSettings: boolean
) => {
    return _.chain(locations)
        .flatMap((l) => l.allEmissions)
        .groupBy((a) => DateTime.fromISO(a.from, { zone: "local" }).year)
        .map((a, year) => {
            const assessmentsByType = _.groupBy(a, (b) => b.__typename);

            const emission = transformedEmission(
                type,
                assessmentsByType["AssessmentSummary"],
                t
            );
            const projectedEmission = transformedEmission(
                type,
                assessmentsByType["ProjectedAssessmentSummary"],
                t
            );
            const targetEmission = hasTargetSettings
                ? transformedEmission(
                      type,
                      assessmentsByType["TargetEmission"],
                      t
                  )
                : null;

            const assessmentYear = parseInt(year, 10);
            const currentYear = DateTime.local().year;

            const isCurrentYear = assessmentYear === currentYear;
            const isFutureYear = assessmentYear > currentYear;
            const showForecastedValues = isFutureYear || isCurrentYear;

            const firstTargetEmission = _.first(
                assessmentsByType["TargetEmission"]
            );
            const showStrandedAsset =
                locations.length === 1 &&
                getIsStrandedFromTargetEmission(firstTargetEmission, type);

            return {
                label: year,
                id: year,
                actualAssessmentsValue: emission.value,
                forecastAssessmentsValue: showForecastedValues
                    ? Math.max(projectedEmission.value - emission.value, 0)
                    : 0,
                targetAssessmentsValue: targetEmission?.value || null,
                strandedAsset: showStrandedAsset
                    ? _.max([emission.value, projectedEmission.value])
                    : null,
                tooltipItem: {
                    heading: year,
                    body: [
                        {
                            key: ChartType.totalEmissions,
                            label: t(
                                "forecastDashboard.labels.totalEmissions",
                                "Total Emissions"
                            ),
                            value: isFutureYear
                                ? t("common.dashSeparator", DASH_SEPARATOR)
                                : emission.label,
                        },
                        {
                            key: ChartType.projectedEmissions,
                            label: t(
                                "forecastDashboard.labels.projectedEmissions",
                                "Projected Emissions"
                            ),
                            value: showForecastedValues
                                ? projectedEmission.label
                                : t("common.dashSeparator", DASH_SEPARATOR),
                        },
                        ...(targetEmission
                            ? [
                                  {
                                      key: ChartType.targetEmissions,
                                      label: t(
                                          "forecastDashboard.labels.targetEmissions",
                                          "Target Emissions"
                                      ),
                                      value: targetEmission.label,
                                  },
                              ]
                            : []),
                        ...(showStrandedAsset
                            ? [
                                  {
                                      key: ChartType.strandedAsset,
                                      label: t(
                                          "forecastDashboard.labels.potentialStrandedAssetInYear",
                                          "Potential Stranded Asset in {{year}}",
                                          { year }
                                      ),
                                      value: t(
                                          "forecastDashboard.labels.potentialStrandedAssetInYear",
                                          "Potential Stranded Asset in {{year}}",
                                          { year }
                                      ),
                                  },
                              ]
                            : []),
                    ],
                },
            };
        })
        .sortBy((data) => data.label)
        .value();
};
