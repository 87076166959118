import { useQuery } from "@apollo/client";
import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getDataQualityChartData } from "components/DataQuality/DataQuality.helpers";
import DataQualityBarCharts from "components/DataQuality/DataQualityBars/DataQualityBarChart";
import Loading, { LoadingOverlay } from "components/Loading/Loading";
import { getNoDataGraphText } from "components/PortfolioDashboard/helpers/emptyGraphs";
import { DATA_QUALITY_QUERY } from "components/PortfolioDashboard/Queries/dataQualityQuery";
import {
    AssessmentAggregation,
    AssessmentDataType,
    GetDataQualityQuery,
    GetDataQualityQueryVariables,
} from "graphql-types/graphql";

type Props = {
    activeTab: AssessmentDataType;
    activeYear: number;
};

export const MonthlyDataQualityChart = (props: Props) => {
    const { activeTab, activeYear } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const variables: GetDataQualityQueryVariables = useMemo(() => {
        return {
            from: `${activeYear}-01-01`,
            to: `${activeYear}-12-31`,
            assessmentDataType: activeTab,
            aggregation: AssessmentAggregation.MONTH,
        };
    }, [activeTab, activeYear]);

    const { data, loading, previousData } = useQuery<
        GetDataQualityQuery,
        GetDataQualityQueryVariables
    >(DATA_QUALITY_QUERY, {
        variables,
    });

    const dataQualityForOrganization = useMemo(() => {
        const dataLoader = data ?? previousData;

        return dataLoader?.me.organization?.dataQuality ?? [];
    }, [data, previousData]);

    const dataQualityChartData = useMemo(
        () =>
            getDataQualityChartData({
                dataQualityForOrganization,
                theme,
                byMonth: true,
            }),
        [dataQualityForOrganization, theme]
    );

    if (loading)
        return (
            <LoadingOverlay>
                <Loading description="" />
            </LoadingOverlay>
        );

    if (!loading && dataQualityChartData.data.length === 0)
        return getNoDataGraphText(t);

    if (dataQualityChartData.data.length > 0)
        return <DataQualityBarCharts dataQualityData={dataQualityChartData} />;
};
