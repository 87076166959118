import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";

import { BenchmarkMarkers } from "components/types";

import { BenchmarkButton } from "../BenchmarkButton";
import { BenchmarkDownload } from "../BenchmarkDownload";
import { BenchmarkDrawer } from "../BenchmarkDrawer";
import { BenchmarkListToggle } from "../BenchmarkListToggle";

type Props = {
    title: string;
    benchmarkMarkers: BenchmarkMarkers;
    onToggleMarkerVisibility: (markerId: string, isVisible: boolean) => void;
    allowDownload?: boolean;
};

export const BenchmarkPanel = (props: Props) => {
    const { title, benchmarkMarkers, onToggleMarkerVisibility, allowDownload } =
        props;

    const { useLegacyBenchmark } = useFlags();

    const [toggleBenchmarkDrawer, setToggleBenchmarkDrawer] = useState(false);

    function onToggleBenchmarkDrawer() {
        setToggleBenchmarkDrawer(!toggleBenchmarkDrawer);
    }

    if (!benchmarkMarkers.length) {
        return null;
    }

    return (
        <>
            <BenchmarkButton
                benchmarkCount={benchmarkMarkers.length}
                onClick={onToggleBenchmarkDrawer}
            />
            <BenchmarkDrawer
                title={title}
                isOpen={toggleBenchmarkDrawer}
                onClose={onToggleBenchmarkDrawer}
            >
                <BenchmarkListToggle
                    benchmarkMarkers={benchmarkMarkers}
                    onToggleMarkerVisibility={onToggleMarkerVisibility}
                />

                {useLegacyBenchmark && allowDownload && <BenchmarkDownload />}
            </BenchmarkDrawer>
        </>
    );
};
