import { TFunction } from "i18next";
import { DateTime } from "luxon";

import { DataSourceStatus } from "components/Cells/DataStatusCell";
import { GetAssetAutomaticSourcesQuery } from "graphql-types/graphql";
import { validateAndFormatDateString } from "utils/date.utils";


export type AutomaticSource =
    GetAssetAutomaticSourcesQuery["location"]["automaticDataSources"][0];

const isAutomaticSourceEPC = (source: AutomaticSource) => {
    return source.type.includes("EPC");
};

export const getAutomaticSourceType = (
    source: AutomaticSource,
    t: TFunction
) => {
    if (isAutomaticSourceEPC(source)) {
        return source.type.split("_").join(" ");
    }

    return t("dataQuality.source.automaticMeter", "Automatic Meter", {
        ns: "translation",
    });
};

export const getAutomaticSourceStatus = (
    status: string | null | undefined,
    t: TFunction
) => {
    switch (status) {
        case "true":
            return DataSourceStatus.ACTIVE;
        case "false":
            return DataSourceStatus.EXPIRED;
        default: {
            if (status) {
                const date = new Date(+status);

                if (!isNaN(date.getTime())) {
                    return `${t("common.lastReading", "Last reading:", {
                        ns: "translation",
                    })} ${DateTime.fromJSDate(date).toLocaleString(
                        DateTime.DATE_SHORT
                    )}`;
                }
            }

            return null;
        }
    }
};

export const getAutomaticSourceToDate = (
    source: AutomaticSource,
    t: TFunction
) => {
    if (isAutomaticSourceEPC(source)) {
        return validateAndFormatDateString(source.to);
    }

    const threeMonthsAgo = DateTime.local().minus({ months: 3 });

    return new Date(source.to) > threeMonthsAgo.toJSDate()
        ? t("dataLocationCategory.present", "Present", {
              ns: "translation",
          })
        : validateAndFormatDateString(source.to);
};
