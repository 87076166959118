import { Drawer, Box, styled, Typography, IconButton } from "@mui/material";

export const StyledDrawer = styled(Drawer)(() => ({
    "& .MuiDrawer-paper": {
        width: "100%",
        maxWidth: "600px",
    },
}));

export const DrawerHeader = styled(Box)(({ theme }) => ({
    padding: "2rem",
    position: "relative",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[100],
}));

export const StyledCloseButton = styled(IconButton)(() => ({
    position: "absolute",
    top: "2rem",
    right: "2rem",
}));

export const DrawerSubTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[500],
    fontWeight: "bold",
}));
