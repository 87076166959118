import { useMutation } from "@apollo/client";
import { TextField, Grid, Button, Alert, Box } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InfoPopover from "components/Popovers/InfoPopover";
import YearSelector from "components/YearSelector/YearSelector";
import {
    GetMeQuery,
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables,
} from "graphql-types/graphql";
import ME_QUERY from "queries/me";
import { createYearRange } from "utils/date.utils";
import { useOrganizationContext } from "utils/userContext";

import { UPDATE_ORGANIZATION_MUTATION } from "./companyProfileQueries";
import Loading from "../Loading/Loading";

const INITIAL_BASELINE_YEAR = 2016;

type OrgInput = {
    name: string;
    baselineYear?: number | null | undefined;
};

type Props = {
    defaultValues: OrgInput;
    onSubmit: (data: OrgInput) => void;
};

const CompanyProfileForm = (props: Props) => {
    const { defaultValues, onSubmit } = props;
    const { t } = useTranslation();
    const { register, handleSubmit, setValue } = useForm<OrgInput>();

    const [year, setYear] = useState<number | undefined>(
        defaultValues.baselineYear || undefined
    );

    const availableYears = useMemo(() => {
        const currentYear = new Date().getFullYear();
        return createYearRange(INITIAL_BASELINE_YEAR, currentYear - 1);
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={4}>
                <TextField
                    {...register("name")}
                    name="name"
                    disabled
                    fullWidth
                    variant="outlined"
                    defaultValue={defaultValues.name}
                    label={t("settings.profile.name", "Name")}
                />
            </Grid>
            <Grid item xs={4} sx={{ mt: 4, position: "relative" }}>
                <YearSelector
                    fullWidth
                    label={t("settings.profile.baselineYear", "Baseline year")}
                    selectedYear={year}
                    availableYears={availableYears}
                    onYearChange={(year) => {
                        setYear(year);
                        setValue("baselineYear", year);
                    }}
                />
                <Box sx={{ position: "absolute", right: -32, top: 16 }}>
                    <InfoPopover
                        element={t("settings.profile.baselineYearInfo")}
                    />
                </Box>
            </Grid>

            <Grid item xs={4} mt={4}>
                <Button type="submit" variant="contained" color="primary">
                    {t("settings.saveChanges", "Save Changes")}
                </Button>
            </Grid>
        </form>
    );
};

const CompanyProfile = () => {
    const { t } = useTranslation();

    const [success, setSuccess] = useState(false);

    const organizationContext = useOrganizationContext();

    const [updateOrganization] = useMutation<
        UpdateOrganizationMutation,
        UpdateOrganizationMutationVariables
    >(UPDATE_ORGANIZATION_MUTATION, {
        update(cache, { data }) {
            if (!data || !data.updateOneOrganization) {
                return;
            }

            cache.updateQuery<GetMeQuery>({ query: ME_QUERY }, (meData) => {
                if (!meData?.me.organization) {
                    return meData;
                }
                const { baselineYear } = data.updateOneOrganization;

                const organization = {
                    ...meData.me.organization,
                    baselineYear,
                };

                return {
                    ...meData,
                    me: { ...meData.me, organization },
                };
            });
        },
    });

    const organization = useMemo(() => {
        if (!organizationContext) {
            return null;
        }

        return organizationContext;
    }, [organizationContext]);

    const onSubmit = useCallback(
        async ({ baselineYear }: OrgInput) => {
            if (!organization) {
                return;
            }

            await updateOrganization({
                variables: {
                    input: {
                        id: organization.id,
                        update: { baselineYear },
                    },
                },
            }).then(() => {
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 5000);
            });
        },
        [organization, updateOrganization]
    );

    if (!organization || !onSubmit) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    return (
        <>
            <CompanyProfileForm
                defaultValues={organization}
                onSubmit={onSubmit}
            />
            <Alert
                severity="success"
                sx={{ mt: 4, p: "8px 24px" }}
                hidden={!success}
            >
                {t("settings.profile.success", "Profile updated successfully")}
            </Alert>
        </>
    );
};

export default CompanyProfile;
