import { Box, styled, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "recharts";

import { getCurrentYear } from "utils/date.utils";
import { formatNumberLocale } from "utils/report.helpers";

import { AssessmentStats } from "../../../AssessmentStats/types";

function getItem(
    props: TooltipProps<number, string>
): AssessmentStats | undefined {
    return _.first(props.payload)?.payload;
}

const GrayLabel = styled(Typography)(({ theme }) => ({
    color: "rgb(150,150,150)",
    fontWeight: "bold",
    fontSize: theme.spacing(3.5),
    marginBottom: theme.spacing(1),
}));

const TooltipBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
}));

const TooltipInner = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
}));

const TotalTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    fontSize: theme.spacing(5),
    marginBottom: theme.spacing(4),
}));

const MonthTypography = styled(Typography)(({ theme }) => ({
    textTransform: "capitalize",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
}));

const TooltipContainer = styled(Box)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0, 0.3)",
    boxShadow: "1px 1px 8px -3px rgba(0, 0, 0, 0.25)",
}));

function AssessmentChartTooltip(props: TooltipProps<number, string>) {
    const item = getItem(props);
    const { t } = useTranslation();
    const { palette } = useTheme();

    if (!item) {
        return null;
    }

    const isProjected = item.projectedTotal !== null;
    const isCurrentYear = item.id === getCurrentYear().toString();
    const isCurrentMonth = item.id === DateTime.now().toFormat("yyyy-MM");
    const isCurrentMonthOrYear = isCurrentYear || isCurrentMonth;

    const showTotalEmissions = !isProjected || isCurrentMonthOrYear;

    const fullProjectedTotal = (item.projectedTotal ?? 0) + item.total;
    return (
        <TooltipContainer>
            <MonthTypography>{item.tableRowLabel}</MonthTypography>

            <TooltipBox>
                {showTotalEmissions && (
                    <>
                        <GrayLabel>
                            {t(
                                "assessmentOverview.totalEmissions",
                                "Total Emissions"
                            )}
                        </GrayLabel>
                        <TotalTypography>
                            {formatNumberLocale(item.total, t)} kg CO2e
                        </TotalTypography>
                    </>
                )}
                {isProjected && (
                    <>
                        <GrayLabel>
                            {t(
                                "assessmentOverview.projectedTotalEmissions",
                                "Projected Total Emissions"
                            )}
                        </GrayLabel>
                        {item.projectedTotal && (
                            <TotalTypography>
                                {formatNumberLocale(fullProjectedTotal, t)}
                                {t(
                                    "portfolioDashboard.chartHelpers.kgCO2e",
                                    "kg CO2e"
                                )}
                            </TotalTypography>
                        )}
                    </>
                )}
            </TooltipBox>

            {showTotalEmissions && (
                <TooltipBox>
                    <GrayLabel>
                        {t("assessmentOverview.activity", "Activity")}
                    </GrayLabel>
                    {item.chartTooltips.map(
                        (item, i) =>
                            item.label && (
                                <TooltipInner key={i}>
                                    {item.preFix && (
                                        <Typography
                                            color={
                                                item.color
                                                    ? palette[item.color].main
                                                    : palette.primary.main
                                            }
                                        >
                                            {item.preFix}
                                        </Typography>
                                    )}
                                    <Typography color={item.color}>
                                        {item.label}
                                    </Typography>
                                </TooltipInner>
                            )
                    )}
                </TooltipBox>
            )}
        </TooltipContainer>
    );
}

export default AssessmentChartTooltip;
