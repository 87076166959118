import { TFunction } from "i18next";
import _ from "lodash";

import {
    roundTo,
    getLabel,
    BENCHMARK_LABEL,
} from "components/PortfolioDashboard/helpers/chartHelpers";
import { getBenchmark } from "components/PortfolioDashboard/helpers/utilities";
import { BuildingData } from "components/PortfolioDashboard/Sidepanel/PortfolioPerformanceSidepanel";
import {
    GetYearlyPerformanceQuery,
    Benchmark,
    BenchmarkType,
    AssessmentDataType,
    AssetAlertFlag,
} from "graphql-types/graphql";
import { getCurrentYear, getYTDAmountOfValue } from "utils/date.utils";
import { gramsToKg } from "utils/report.helpers";
import { DASH_SEPARATOR } from "utils/strings.helpers";

import { BenchmarkPerformanceChartData } from "../charts.types";

const getBenchmarkLineTitle = (benchmark: BenchmarkType, t: TFunction) => {
    switch (benchmark) {
        case BenchmarkType.BASELINE:
            return t(
                "portfolioDashboard.hoverBox.portfolioAverageEmissionPerArea",
                "Average Portfolio Emission"
            );
        case BenchmarkType.MARKET:
            return t(
                "portfolioDashboard.hoverBox.marketAverageEmissionPerArea",
                "Average Market Emission"
            );
        default:
            return t(
                "portfolioDashboard.hoverBox.averageEmissionPerArea",
                "Average Emission"
            );
    }
};

export const formatBenchmarkPerformanceLocation = (
    data: GetYearlyPerformanceQuery,
    dataType: AssessmentDataType
): BuildingData[] => {
    const locations = data.me.organization?.getLocations ?? [];

    return locations.flatMap((location) => {
        const { displayName, ownedArea, externalId, benchmarkPerformances } =
            location;

        const hasExpiredEpc = location.assetAlerts?.some(
            (a) => a.type === AssetAlertFlag.EPC_EXPIRED_IN_CURRENT_YEAR
        );

        return benchmarkPerformances
            .filter((performance) => performance.dataType === dataType)
            .map(({ ownedEmission, performance }) => {
                const emissionInKg = gramsToKg(ownedEmission);

                return {
                    hasExpiredEpc,
                    link: location.id,
                    address: displayName || DASH_SEPARATOR,
                    externalId: externalId || DASH_SEPARATOR,
                    emission: emissionInKg,
                    emissionPerArea: emissionInKg / (ownedArea ?? 1),
                    performance: _.round(performance * 100),
                };
            });
    });
};

export const getBenchmarkPerformanceChartData = (
    benchmark: Benchmark,
    activeYear: number,
    activeDataType: AssessmentDataType,
    benchmarkPerformance: BuildingData[],
    t: TFunction
): BenchmarkPerformanceChartData[] => {
    let benchmarkValue = getBenchmark(activeDataType, benchmark);
    if (!benchmarkValue) return [];

    const portfolioPerformanceChartData = _.chain(benchmarkPerformance)
        .groupBy(({ performance }) => _.round(roundTo(performance, 5)))
        .map((performance, percentage) => {
            const percentageNumber = parseInt(percentage, 10);
            const total = _.meanBy(performance, "emissionPerArea");
            const label = getLabel(percentageNumber);

            return {
                id: percentage,
                title: t(
                    "portfolioDashboard.hoverBox.averageEmissionPerArea",
                    "Average Emission"
                ),
                value: performance.length,
                total,
                label,
                percentage: percentageNumber,
                assetCount: performance.length,
                benchmark: benchmarkValue,
                sidePanelData: performance,
            };
        })
        .value();

    benchmarkValue =
        activeYear === getCurrentYear()
            ? getYTDAmountOfValue(benchmarkValue)
            : benchmarkValue;

    const hasAssetsOnBenchmark = portfolioPerformanceChartData.some(
        ({ percentage }) => percentage === 100
    );

    if (!hasAssetsOnBenchmark) {
        const benchmarkLine = {
            id: "100",
            value: 0,
            title: getBenchmarkLineTitle(benchmark.type, t),
            total: benchmarkValue,
            percentage: 100,
            label: BENCHMARK_LABEL,
            benchmark: benchmarkValue,
        };

        portfolioPerformanceChartData.push(benchmarkLine as any);
    }

    return _.sortBy(portfolioPerformanceChartData, "percentage");
};
