import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import MonthChart from "./MonthChart";
import {
    getAssetEmissionSummariesAverageEmissionByMonth,
    getAverageEmissionByMonth,
    getAverageEmissionByMonthChartData,
} from "./utils/buildingAverageByMonthChart.utils";
import { EmissionSummaryLocation } from "./utils/portfolioIntensityByMonthChart.utils";
import { useSelectedYearContext } from "../../YearSelector/YearContext";

type Props = {
    emissionSummariesAssets?: EmissionSummaryLocation[];
};
function BuildingAverageByMonthChart(props: Props) {
    const { emissionSummariesAssets = [] } = props;

    const { t } = useTranslation();

    const { activeYear } = useSelectedYearContext();

    const averageEmissionByMonthChartData = useMemo(() => {
        const assetEmissionByMonth = _.chain(emissionSummariesAssets)
            .flatMap((asset) =>
                getAssetEmissionSummariesAverageEmissionByMonth(asset)
            )
            .compact()
            .value();

        const averageEmissionsByMonth =
            getAverageEmissionByMonth(assetEmissionByMonth);

        return getAverageEmissionByMonthChartData(
            averageEmissionsByMonth,
            activeYear,
            t
        );
    }, [emissionSummariesAssets, activeYear, t]);

    return (
        <MonthChart
            chartData={averageEmissionByMonthChartData}
            yAxisLabel={`${t(
                "portfolioDashboard.chartHelpers.kgCO2em2",
                "kg CO2e / m²"
            )}`}
        />
    );
}

export default BuildingAverageByMonthChart;
