import { Theme, useTheme } from "@mui/material";
import { useMemo } from "react";

import {
    DataQualityDistribution,
    DataQualityFragment,
} from "graphql-types/graphql";

import {
    DataQualityEnum,
    sumDataQualities,
    getDataQualityColor,
} from "./DataQuality.helpers";
import ColorBar from "../ColorBar/ColorBar";

const getDataQualityGradient = (dataQuality: DataQualityDistribution[]) => {
    return [...dataQuality]
        .sort((a, b) => a.priorityDistribution - b.priorityDistribution)
        .map(({ priorityDistribution, assessmentDays }) => ({
            distribution: priorityDistribution,
            count: assessmentDays,
        }));
};

export const formatDataQualityForColorBar = (
    dataQuality: DataQualityFragment,
    theme: Theme
) => {
    const { highQuality, mediumQuality, lowQuality } = dataQuality;
    const { sumHighQuality, sumMediumQuality, sumLowQuality } =
        sumDataQualities(dataQuality);

    const dataQualityColorBar = [
        {
            value: sumHighQuality,
            color: getDataQualityColor(DataQualityEnum.HIGH, theme.palette),
            gradient: getDataQualityGradient(highQuality), // from dark to light
        },
        {
            value: sumMediumQuality,
            color: getDataQualityColor(DataQualityEnum.MEDIUM, theme.palette),
            gradient: getDataQualityGradient(mediumQuality).reverse(), // from light to dark
        },
        {
            value: sumLowQuality,
            color: getDataQualityColor(DataQualityEnum.LOW, theme.palette),
            gradient: getDataQualityGradient(lowQuality).reverse(), // from light to dark
        },
    ];

    return dataQualityColorBar;
};

function DataQualityDistributionBar(dataQuality: DataQualityFragment) {
    const theme = useTheme();

    const dataQualityColorBar = useMemo(
        () => formatDataQualityForColorBar(dataQuality, theme),
        [dataQuality, theme]
    );

    return <ColorBar items={dataQualityColorBar} />;
}

export default DataQualityDistributionBar;
