import { ChartsAxisContentProps } from "@mui/x-charts";
import { chain, some } from "lodash";
import { useTranslation } from "react-i18next";

import {
    DataQualityEnum,
    getDataQualityTitle,
} from "components/DataQuality/DataQuality.helpers";
import { ChartTooltip } from "glue/Chart/components/ChartTooltip";

export const DataQualityTooltip = (props: ChartsAxisContentProps) => {
    const { axis, axisValue, dataIndex, series } = props;
    const { t } = useTranslation();

    if (dataIndex === null || dataIndex === undefined) {
        return null;
    }

    const index = dataIndex;
    const qualitySeries = chain(series)
        .groupBy("quality")
        .map((serie: any, quality) => {
            const dataForIndex = some(serie, (obj) => {
                return obj.data[index] !== null;
            });
            if (!dataForIndex) return null;

            const items: any[] = serie.map((item: any) => {
                if (item.data[index] === null) return null;
                return {
                    color: item.color,
                    label: item.label,
                    value: item.valueFormatter(item.data[index]),
                };
            });

            return {
                header: getDataQualityTitle(
                    quality as DataQualityEnum,
                    true,
                    t
                ),
                items: items.filter((item) => item !== null),
            };
        })
        .compact()
        .value();

    const header =
        axis.valueFormatter && axisValue
            ? axis.valueFormatter(axisValue, {
                  location: "tooltip",
              })
            : axisValue?.toString();

    return (
        <ChartTooltip
            sections={[
                {
                    header: header ?? "",
                    items: [],
                },
                ...qualitySeries,
            ]}
        />
    );
};
