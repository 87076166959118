import { Box, Typography, useTheme } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Tooltip,
    CartesianGrid,
    Legend,
} from "recharts";

import { BenchmarkPanel } from "components/Benchmark";
import { getBenchmarkChartMarkers } from "components/PortfolioDashboard/Charts/PortfolioIntensity";
import { BenchmarkMarkers } from "components/types";
import { useAssessmentDataTypeContext } from "containers/AssetPage/AssessmentDataTypeContext";
import { AssetContext } from "containers/AssetPage/AssetPage";
import { BenchmarkType } from "graphql-types/graphql";
import { useBenchmarkMarker } from "hooks/useBenchmarkMarker";
import { BenchmarksContext } from "utils/benchmarksContext";

import { MUIAssessmentChart } from "./MUIAssessmentChart";
import AssessmentChartTooltip from "../../PortfolioDashboard/Charts/Tooltip/AssessmentChartTooltip";
import { AssessmentStats } from "../types";

type Props = {
    isYearly: boolean;
    assessmentStats: AssessmentStats[];
    barStats: {
        key: string;
        fill?: string;
    }[];
};

function AssessmentChart(props: Props) {
    const { assessmentStats, barStats, isYearly } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    const { useMuixCharts } = useFlags();

    const asset = useContext(AssetContext);
    const benchmarkContext = useContext(BenchmarksContext);

    const { assessmentType } = useAssessmentDataTypeContext();

    const { convertToBenchmarkMarker } = useBenchmarkMarker();

    const [markers, setMarkers] = useState<BenchmarkMarkers>([]);

    const showBenchmarks = !isYearly && asset.ownedArea;

    useEffect(() => {
        const { benchmarks, markers } = benchmarkContext;

        const area = asset.ownedArea;

        const assetBenchmarks = benchmarks.filter(
            (benchmark) =>
                benchmark.type === BenchmarkType.BASELINE ||
                (benchmark.type === BenchmarkType.MARKET &&
                    benchmark.countryCode === asset.countryCode)
        );

        if (!assetBenchmarks.length || !area) {
            return;
        }

        const pendingBenchmarkMarkers = convertToBenchmarkMarker(
            assessmentType,
            assetBenchmarks
        );

        const benchmarkMarkers = pendingBenchmarkMarkers.map((marker) => {
            return {
                ...marker,
                value: (marker.value ?? 0) * area,
                isVisible: true,
            };
        });

        const legacyBenchmarks = markers.legacyBenchmarks.map((marker) => {
            const values = marker.values.map((value) => {
                return {
                    ...value,
                    value: (value.value ?? 0) * area,
                };
            });

            return {
                ...marker,
                values,
                isVisible: true,
            };
        });

        setMarkers([...benchmarkMarkers, ...legacyBenchmarks]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onToggleMarkerVisibility(markerId: string, isVisible: boolean) {
        const updatedBenchmarkMarkers: BenchmarkMarkers = markers.map(
            (marker) => {
                if (marker.id === markerId) {
                    return {
                        ...marker,
                        isVisible,
                    };
                }

                return marker;
            }
        );

        setMarkers(updatedBenchmarkMarkers);
    }

    const benchmarkChartMarkers = getBenchmarkChartMarkers(markers, true, t);

    const chartData = assessmentStats.map((stat) => {
        const benchmarks = markers;

        return {
            ...stat,
            benchmarks,
        };
    });

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4">
                    {t("assessmentOverview.emissions", "Emissions")}
                </Typography>
                {showBenchmarks && (
                    <Box marginLeft="auto">
                        <BenchmarkPanel
                            title={asset.displayName}
                            benchmarkMarkers={markers}
                            onToggleMarkerVisibility={onToggleMarkerVisibility}
                        />
                    </Box>
                )}
            </Box>

            {useMuixCharts ? (
                <MUIAssessmentChart
                    isYearly={isYearly}
                    benchmarkMarkers={markers}
                    assessmentsData={assessmentStats}
                />
            ) : (
                <ResponsiveContainer width="100%" height={420}>
                    <ComposedChart
                        data={chartData}
                        margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                    >
                        <CartesianGrid
                            stroke={theme.palette.grey.A100}
                            strokeDasharray="100% 0.5"
                            vertical={false}
                        />

                        <YAxis
                            tick={{ fontSize: "10px" }}
                            label={{
                                value: `${t(
                                    "portfolioDashboard.chartHelpers.kgCO2e",
                                    "kg CO2e"
                                )}`,
                                fill: theme.palette.grey[600],
                                fontSize: "12px",
                                angle: -90,
                                position: "insideBottomLeft",
                                textAnchor: "start",
                                dx: 15,
                                props: { fontSize: "6px" },
                            }}
                        />

                        <Tooltip
                            cursor={false}
                            content={AssessmentChartTooltip}
                        />
                        {barStats.map((barStat, i) => (
                            <Bar
                                key={i}
                                dataKey={barStat.key}
                                radius={
                                    i === barStats.length - 1
                                        ? [5, 5, 0, 0]
                                        : undefined
                                }
                                barSize={40}
                                stackId="a"
                                xAxisId={1}
                                fill={
                                    barStat.fill || theme.palette.primary.main
                                }
                            />
                        ))}

                        <Bar
                            dataKey="projectedTotal"
                            barSize={40}
                            xAxisId={1}
                            stackId="a"
                            fill={theme.palette.secondary.main}
                            radius={[5, 5, 0, 0]}
                        />

                        {showBenchmarks && benchmarkChartMarkers}

                        {showBenchmarks && (
                            <Legend
                                payload={benchmarkChartMarkers.map(
                                    ({ props }) => ({
                                        value: props.name,
                                        color: props.stroke,
                                        inactive: props.hide,
                                        type: "line",
                                    })
                                )}
                                verticalAlign="bottom"
                                wrapperStyle={{
                                    fontSize: "0.85rem",
                                    marginTop: "10px",
                                }}
                            />
                        )}

                        <XAxis
                            dataKey="chartLabel"
                            tick={{ fontSize: "14px" }}
                            xAxisId={1}
                            dy={5}
                        />
                        <XAxis dataKey="chartLabel" xAxisId={0} hide />
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </>
    );
}

export default AssessmentChart;
