import { Box, styled } from "@mui/material";
import { DateTime } from "luxon";

import ReportsListItem from "components/ReportsList/ReportsListItem";
import { ReportFramework } from "graphql-types/graphql";

const ReportsGrid = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    gridGap: theme.spacing(6),
    marginTop: theme.spacing(8),
}));

export const ReportsFramework = () => {
    const lastUpdatedDate = DateTime.local().toFormat("D");
    const reports = [
        ReportFramework.GHG,
        ReportFramework.ESG,
        ReportFramework.CRREM,
    ];

    return (
        <ReportsGrid>
            {reports.map((report) => (
                <ReportsListItem
                    key={report}
                    reportType={report}
                    updatedDate={lastUpdatedDate}
                />
            ))}
        </ReportsGrid>
    );
};
