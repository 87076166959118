import {
    Box,
    Typography,
    styled,
    useTheme,
    List,
    ListItem,
} from "@mui/material";
import { round } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import {
    DataQualityEnum,
    getDataQualityColor,
    getDataQualityTitle,
} from "./DataQuality.helpers";

export interface DataQualityItem {
    type: DataQualityEnum;
    value: number;
    sources: {
        name: string;
    }[];
}

const InnerBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    gap: "2rem",
    width: "100%",
}));

export const DataQualitySummary = ({
    dataQuality,
    hasFullTitle,
    showPercentages = true,
}: {
    dataQuality: DataQualityItem[];
    hasFullTitle: boolean;
    showPercentages?: boolean;
}) => {
    const { palette } = useTheme();
    const { t } = useTranslation();

    const sortedDataQuality = dataQuality.sort((a, b) =>
        a.type === DataQualityEnum.HIGH ||
        (a.type === DataQualityEnum.MEDIUM && b.type === DataQualityEnum.LOW)
            ? -1
            : 1
    );

    const total = sortedDataQuality.reduce(
        (acc, item) => acc + (item.value || 0),
        0
    );

    return (
        <List disablePadding={true} sx={{ listStyleType: 'disc' }}>
            {sortedDataQuality.map((item, index) => (
                <React.Fragment key={item.type}>
                    <ListItem
                        key={`${item.type}-${index}`}
                        disableGutters={true}
                        dense={true}
                    >
                        <InnerBox>
                            <Typography
                                data-testid="data-quality-title"
                                fontWeight={700}
                                color={getDataQualityColor(item.type, palette)}
                            >
                                {getDataQualityTitle(
                                    item.type,
                                    hasFullTitle,
                                    t
                                )}
                            </Typography>
                            {showPercentages && (
                                <Typography
                                    data-testid="data-quality-value"
                                    fontWeight={700}
                                >
                                    {`${round((item.value / total) * 100, 2)}%`}
                                </Typography>
                            )}{" "}
                        </InnerBox>
                    </ListItem>
                    {item.sources.map((source, index) => (
                        <ListItem
                            key={`${source.name}-${index}`}
                            disableGutters={true}
                            dense={true}
                            sx={{ display: 'list-item', ml: 6 }}
                        >
                            <Typography
                                color={palette.text.secondary}
                                fontSize={14}
                            >
                                {source.name}
                            </Typography>
                        </ListItem>
                    ))}
                </React.Fragment>
            ))}
        </List>
    );
};
