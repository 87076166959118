import { startCase, toLower } from "lodash";
import { useTranslation } from "react-i18next";

import { useFilterTranslations } from "components/AssetsGroup";
import { getLegacyBenchmarkMarkerColor } from "components/PortfolioDashboard/Charts/utils";
import { LegacyBenchmarkMarker } from "components/types";
import { LegacyBenchmark } from "graphql-types/graphql";

import { useRegionTranslation } from "./useRegionTranslation";

function formatSegment(input: string): string {
    return input ? startCase(toLower(input.replace(/_/g, " "))) : "";
}

export function useLegacyBenchmarkMarker() {
    const { t } = useTranslation();
    const { getRegionName } = useRegionTranslation();
    const { translations } = useFilterTranslations();

    const convertToLegacyBenchmarkMarker = (
        legacyBenchmarks: LegacyBenchmark[]
    ): LegacyBenchmarkMarker[] => {
        return legacyBenchmarks.map((legacyBenchmark, index) => {
            const { benchmarkType, countryCode, segment } = legacyBenchmark;

            const color = getLegacyBenchmarkMarkerColor(benchmarkType);

            const title = `${t(
                "benchmark.legacyBenchmark",
                "legacy benchmark"
            )} ${index + 1}`;

            const subTitle = `${getRegionName(countryCode ?? "")}, ${
                translations[benchmarkType]
            } ${formatSegment(segment)}`;

            const description = `${t(
                "benchmark.legacyBenchmarkDescription",
                "Legacy’s Benchmarks are metrics designed to help property owners assess building emissions and energy performance using specific thresholds"
            )}`;

            return {
                ...legacyBenchmark,
                color,
                title,
                isVisible: false,
                subTitle,
                description,
            };
        });
    };

    return {
        convertToLegacyBenchmarkMarker,
    };
}
