import { Box, MenuItem, Select } from "@mui/material";
import { t } from "i18next";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";

import Card from "components/Cards/Card";
import Loading, { LoadingOverlay } from "components/Loading/Loading";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import {
    AssessmentDataType,
    Benchmark,
    EmissionSummary,
    Location,
    BenchmarkType,
} from "graphql-types/graphql";

import AssetsBenchmarkPerformanceChart from "./AssetsBenchmarkPerformanceChart/AssetsBenchmarkPerformanceChart";
import BuildingAverageByMonthChart from "./BuildingAverageByMonthChart";
import ChartContainer from "./ChartContainer";
import { MonthlyBuildingAverageChart } from "./MonthlyBuildingAverageChart";
import { MonthlyDataQualityChart } from "./MonthlyDataQualityChart";
import { MonthlyIntensityChart } from "./MonthlyIntensityChart";
import { MonthlyTotalEmissionsChart } from "./MonthlyTotalEmissionsChart";
import { BenchmarkPerformanceChart } from "./MuiBenchmarkPerformanceChart";
import { MuiDataQualityChart } from "./MuiDataQualityChart";
import PortfolioIntensityByMonthChart from "./PortfolioIntensityByMonthChart";
import TotalEmissionByMonthChart from "./TotalEmissionByMonthChart";
import {
    ActiveCountryBenchmarkProvider,
    useActiveCountryBenchmark,
} from "./utils/activeCountryBenchmarkContext";
import PortfolioSelector from "../PortfolioSelector";
import { ChartType } from "../types";

type EmissionSummaryLocation = {
    emissionSummaries: Pick<EmissionSummary, "id" | "from" | "ownedEmission">[];
    ownedArea?: Location["ownedArea"];
};

type Props = {
    activeChart: ChartType;
    setActiveChart: (chart: ChartType) => void;
    benchmarks: Benchmark[];
    activeTab: AssessmentDataType;
    emissionSummaries?: EmissionSummaryLocation[];
    isLoading: boolean;
};

const CountryBenchmarkSelector = () => {
    const { activeCountryCode, countryCodes, setCountryCode } =
        useActiveCountryBenchmark();

    return (
        <Select
            size="small"
            value={activeCountryCode}
            onChange={(e) => {
                setCountryCode(e.target.value as string);
            }}
        >
            {countryCodes.map((countryCode) => (
                <MenuItem key={countryCode} value={countryCode}>
                    {countryCode}
                </MenuItem>
            ))}
        </Select>
    );
};

const YearlyBreakdownCharts = (props: Props) => {
    const { activeChart, benchmarks, activeTab, emissionSummaries, isLoading } =
        props;

    const { useMuixCharts } = useFlags();
    const { activeYear } = useSelectedYearContext();

    const ActiveChartComponent = useCallback(() => {
        switch (activeChart) {
            case ChartType.PORTFOLIO_INTENSITY:
                return useMuixCharts ? (
                    <MonthlyIntensityChart
                        emissionSummaries={emissionSummaries}
                    />
                ) : (
                    <PortfolioIntensityByMonthChart
                        emissionSummariesAssets={emissionSummaries}
                    />
                );
            case ChartType.WEIGHTED_AVERAGE:
                return useMuixCharts ? (
                    <MonthlyBuildingAverageChart
                        emissionSummaries={emissionSummaries}
                    />
                ) : (
                    <BuildingAverageByMonthChart
                        emissionSummariesAssets={emissionSummaries}
                    />
                );
            case ChartType.MARKET_PERFORMANCE:
            case ChartType.BASELINE_PERFORMANCE: {
                const benchmarkType =
                    activeChart === ChartType.MARKET_PERFORMANCE
                        ? BenchmarkType.MARKET
                        : BenchmarkType.BASELINE;

                return useMuixCharts ? (
                    <BenchmarkPerformanceChart
                        benchmarks={benchmarks}
                        benchmarkType={benchmarkType}
                        activeDataType={activeTab}
                    />
                ) : (
                    <AssetsBenchmarkPerformanceChart
                        benchmarks={benchmarks}
                        activeDataType={activeTab}
                        benchmarkType={benchmarkType}
                    />
                );
            }
            case ChartType.MONTH_BY_MONTH:
                return useMuixCharts ? (
                    <MonthlyTotalEmissionsChart
                        emissionSummaries={emissionSummaries}
                    />
                ) : (
                    <TotalEmissionByMonthChart
                        emissionSummaries={emissionSummaries}
                    />
                );
            case ChartType.DATA_QUALITY_MONTH_BY_MONTH:
                return useMuixCharts ? (
                    <MuiDataQualityChart activeTab={activeTab} byMonth />
                ) : (
                    <MonthlyDataQualityChart
                        activeTab={activeTab}
                        activeYear={activeYear}
                    />
                );
            default:
                return <></>;
        }
    }, [
        activeChart,
        benchmarks,
        activeTab,
        emissionSummaries,
        activeYear,
        useMuixCharts,
    ]);

    const isAssetCountryBenchmarkChart =
        activeChart === ChartType.MARKET_PERFORMANCE;

    return (
        <Card>
            <ActiveCountryBenchmarkProvider benchmarks={benchmarks}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <PortfolioSelector
                        isYearlyBreakdown
                        onChange={props.setActiveChart}
                        activeChart={activeChart}
                    />

                    {isAssetCountryBenchmarkChart && (
                        <CountryBenchmarkSelector />
                    )}
                </Box>
                <ChartContainer>
                    {isLoading && (
                        <LoadingOverlay>
                            <Loading
                                description={t("loading.title", "Loading")}
                            />
                        </LoadingOverlay>
                    )}

                    {!isLoading && <ActiveChartComponent />}
                </ChartContainer>
            </ActiveCountryBenchmarkProvider>
        </Card>
    );
};

export default YearlyBreakdownCharts;
