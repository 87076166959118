import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import MonthChart from "./MonthChart";
import { EmissionSummaryLocation } from "./utils/portfolioIntensityByMonthChart.utils";
import {
    getTotalEmissionSummariesEmissionByMonth,
    getTotalEmissionByMonthChartData,
} from "./utils/totalEmissionByMonthChart.utils";
import { useSelectedYearContext } from "../../YearSelector/YearContext";

type Props = {
    emissionSummaries?: EmissionSummaryLocation[];
};

function TotalEmissionByMonthChart(props: Props) {
    const { emissionSummaries = [] } = props;

    const { t } = useTranslation();

    const { activeYear } = useSelectedYearContext();

    const totalEmissionsByMonthData = useMemo(() => {
        const totalEmissionsByMonth =
            getTotalEmissionSummariesEmissionByMonth(emissionSummaries);

        return getTotalEmissionByMonthChartData(
            totalEmissionsByMonth,
            activeYear,
            t
        );
    }, [emissionSummaries, activeYear, t]);

    return (
        <MonthChart
            chartData={totalEmissionsByMonthData}
            yAxisLabel={`${t(
                "portfolioDashboard.chartHelpers.kgCO2e",
                "kg CO2e"
            )}`}
        />
    );
}

export default TotalEmissionByMonthChart;
