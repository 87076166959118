import SearchIcon from "@mui/icons-material/Search";
import {
    Autocomplete,
    Box,
    ListItem,
    ListSubheader,
    Popper,
    TextField,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import _ from "lodash";
import React, {
    Children,
    cloneElement,
    createContext,
    forwardRef,
    isValidElement,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { VariableSizeList } from "react-window";
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Dot,
    Legend,
    Line,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from "recharts";

import { DASH_SEPARATOR } from "../../../utils/strings.helpers";
import { ChartType, LocationWithFilteringLabel } from "../types";

type LabelType = string | number;

interface ChartData {
    id: string;
    label: LabelType;
    actualAssessmentsValue: number;
    forecastAssessmentsValue: number;
    targetAssessmentsValue: number | null;
    tooltipItem: ToolTipItem;
}

interface ToolTipItem {
    heading: string;
    body: { key: ChartType; label: string; value: string }[];
}

function getItem(props: TooltipProps<number, string>): ChartData | undefined {
    return _.first(props.payload)?.payload;
}

const LISTBOX_PADDING = 8;

function renderRow(props: any) {
    const { data, index, style } = props;
    return cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref: any) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: number) {
    const ref: React.MutableRefObject<any> = useRef(null);

    useEffect(() => {
        if (!ref.current) return null;

        return ref.current.resetAfterIndex(0, true);
    }, [data]);
    return ref;
}

const ListboxComponent = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = Children.toArray(children);

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: any) => {
        if (isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };
    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index: number) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const renderGroup = (params: any) => [
    <ListSubheader key={params.key} component="div">
        {params.group}
    </ListSubheader>,
    params.children,
];

const TooltipContainer = styled(Box)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 3),
    backgroundColor: theme.palette.common.white,
    boxShadow: "1px 1px 8px -3px rgba(0, 0, 0, 0.25)",
    maxWidth: "200px",
}));

const getTooltipContent = (props: TooltipProps<number, string>) => {
    const item = getItem(props);
    if (!item) {
        return null;
    }

    return (
        <TooltipContainer>
            <TooltipHeadingContainer>
                {item.tooltipItem.heading}
            </TooltipHeadingContainer>
            {item.tooltipItem.body.map(({ key, label, value }) => (
                <React.Fragment key={key}>
                    {key === ChartType.strandedAsset ? (
                        <TooltipStrandedAssetContainer>
                            {label}
                        </TooltipStrandedAssetContainer>
                    ) : (
                        <>
                            <ToolTipBodyItemKeyContainer>
                                {label}
                            </ToolTipBodyItemKeyContainer>
                            <ToolTipBodyItemValueContainer>
                                {value}
                            </ToolTipBodyItemValueContainer>
                        </>
                    )}
                </React.Fragment>
            ))}
        </TooltipContainer>
    );
};

interface InputProps {
    chartData: ChartData[];
    onFilterSelection: (l: LocationWithFilteringLabel) => void;
    locationsWithFilteringLabel: LocationWithFilteringLabel[];
    hasTargetSettings: boolean;
}

const TooltipHeadingContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
    textAlign: "left",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
}));

const ToolTipBodyItemKeyContainer = styled(Box)(({ theme }) => ({
    color: theme.palette.grey[400],
}));

const ToolTipBodyItemValueContainer = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    fontWeight: "bold",
    color: theme.palette.text.primary,
}));

const TooltipStrandedAssetContainer = styled(TooltipHeadingContainer)(
    ({ theme }) => ({
        marginTop: theme.spacing(4),
        color: theme.palette.error.main,
    })
);

const FilteringItemIdNameContainer = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black,
    fontWeight: "bold",
}));

const AutocompleteRow = styled(ListItem)(({ theme }) => ({
    display: "grid",
    padding: theme.spacing(0, 4),
    gridGap: theme.spacing(2),
    gridTemplateColumns: "45% 35% 20%",
    color: theme.palette.grey[400],

    "& > *": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));

const AutocompleteContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
    backgroundColor: theme.palette.grey[100],
    borderRadius: "4px",
    width: "25%",
    minWidth: "450px",

    "& > .MuiAutocomplete-root": {
        flexGrow: 1,
        backgroundColor: "transparent",
        border: "none",

        "& > * ": {
            backgroundColor: "inherit",
        },
    },
}));

const CustomSearchIcon = styled(SearchIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

function BarChart(props: InputProps) {
    const { t } = useTranslation();

    const {
        chartData,
        onFilterSelection,
        locationsWithFilteringLabel,
        hasTargetSettings,
    } = props;
    const [showSearchIcon, setShowSearchIcon] = useState(true);

    const theme = useTheme();

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLDivElement>) =>
            setShowSearchIcon(e.target.innerText === ""),
        [setShowSearchIcon]
    );
    const handleChange = useCallback(
        (
            e: React.ChangeEvent<object>,
            l: string | LocationWithFilteringLabel | null
        ) => {
            onFilterSelection(l as LocationWithFilteringLabel);
        },
        [onFilterSelection]
    );

    return (
        <>
            <AutocompleteContainer>
                {showSearchIcon && <CustomSearchIcon />}
                <Autocomplete
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            outline: "none",
                            borderRadius: "0",
                            padding: "0",

                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                        },
                    }}
                    freeSolo
                    disablePortal
                    disableListWrap
                    selectOnFocus
                    onBlur={handleBlur}
                    onChange={handleChange}
                    PopperComponent={Popper}
                    ListboxComponent={ListboxComponent}
                    renderGroup={renderGroup}
                    options={locationsWithFilteringLabel}
                    groupBy={(option) =>
                        option.name ? option.name[0] : DASH_SEPARATOR
                    }
                    getOptionLabel={(option) =>
                        typeof option !== "string"
                            ? `${option.origin} ${option.externalId} ${option.name}`
                            : ""
                    }
                    renderOption={(props, option) => (
                        <AutocompleteRow {...props}>
                            <FilteringItemIdNameContainer>
                                {option.name}
                            </FilteringItemIdNameContainer>
                            <Typography>{option.origin}</Typography>
                            <Typography>{option.externalId}</Typography>
                        </AutocompleteRow>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={t(
                                "forecastDashboard.filtering.placeholder",
                                "Search by National Building ID, ID or Name"
                            )}
                        />
                    )}
                />
            </AutocompleteContainer>

            <ResponsiveContainer width="100%" height="100%" minHeight={"500px"}>
                <ComposedChart data={chartData}>
                    <CartesianGrid
                        stroke={theme.palette.grey[100]}
                        vertical={false}
                    />
                    <YAxis />
                    <Tooltip cursor={false} content={getTooltipContent} />
                    <Legend
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{ top: "-40px", width: "50%" }}
                        formatter={(value) => (
                            <span style={{ color: theme.palette.primary.main }}>
                                {value}
                            </span>
                        )}
                    />
                    <Bar
                        name={t(
                            "forecastDashboard.labels.totalEmissions",
                            "Total Emissions"
                        )}
                        dataKey="actualAssessmentsValue"
                        stackId="a"
                        fill={theme.palette.primary.main}
                        radius={[6, 6, 0, 0]}
                    />
                    <Bar
                        name={t(
                            "forecastDashboard.labels.projectedEmissions",
                            "Projected Emissions"
                        )}
                        dataKey="forecastAssessmentsValue"
                        stackId="a"
                        fill={theme.palette.primary.light}
                        radius={[6, 6, 0, 0]}
                    />
                    {hasTargetSettings && (
                        <Line
                            type="linear"
                            name={t(
                                "forecastDashboard.labels.targetEmissions",
                                "Target Emissions"
                            )}
                            dataKey="targetAssessmentsValue"
                            stroke={theme.palette.success.main}
                            strokeDasharray="20 5"
                        />
                    )}
                    {hasTargetSettings && (
                        <Scatter
                            dataKey="strandedAsset"
                            fill="red"
                            shape={({ cx, cy }: any) => (
                                <Dot cx={cx} cy={cy} fill="red" r={10} />
                            )}
                            legendType="none"
                        />
                    )}
                    <XAxis dataKey="label" />
                </ComposedChart>
            </ResponsiveContainer>
        </>
    );
}

export default BarChart;
