import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";

import { parseJSON } from "./strings.helpers";

type SetValue<T> = Dispatch<SetStateAction<T>>;

export function useBrowserStorage<T>(
    key: string,
    initialValue: T,
    replaceValue: T | null = null,
    storage: "local" | "session" = "local",
    enableEvents = false
): [T, SetValue<T>] {
    const windowStorage =
        storage === "local" ? window.localStorage : window.sessionStorage;

    const readValue = useCallback((): T => {
        // Prevent build error "window is undefined" but keeps working
        if (typeof window === "undefined") {
            return initialValue;
        }

        if (replaceValue) {
            windowStorage.setItem(key, JSON.stringify(replaceValue));
            return replaceValue;
        }

        try {
            const item = windowStorage.getItem(key);
            return item ? (parseJSON(item) as T) : initialValue;
        } catch (error) {
            return initialValue;
        }
    }, [windowStorage, initialValue, replaceValue, key]);

    const [storedValue, setStoredValue] = useState<T>(readValue);

    const setValue: SetValue<T> = (value) => {
        const newValue = value instanceof Function ? value(storedValue) : value;

        windowStorage.setItem(key, JSON.stringify(newValue));

        if (enableEvents) {
            window.dispatchEvent(new StorageEvent("storage", { key }));
        }
        setStoredValue(newValue);
    };

    useEffect(() => {
        setStoredValue(readValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [storedValue, setValue];
}
