import { TFunction } from "i18next";

import { dateSorter } from "containers/DataOverviewPage/dataOverview.helper";
import { customNumberSorter } from "utils/sorters";

export const getHeaderInfo = (key: string, t: TFunction) => {
    switch (key) {
        case "inclusion":
            return t(
                "columns.inclusionInfo",
                "Assets will be listed as included or excluded from the CRREM report. Some reasons the asset may be excluded is because we do not have enough information to make an assessment.",
                { ns: "report" }
            );
        case "crremPropertyType":
            return t(
                "columns.propertyTypeInfo",
                "Your property types will be mapped to a CRREM property type. If no appropriate category is found or if the property type is not supported, it will be marked as uncategorized and the asset will be excluded from the report.",
                { ns: "report" }
            );

        case "epcLink":
            return t(
                "dataLocationCategory.epcSourceHeaderInfo",
                "For some types of EPCs it will be necessary to login to the provider to be able to access the source. You will be redirected to the provider's login page instead in these cases.",
                { ns: "report" }
            );
        default:
            return undefined;
    }
};

export const getColumnWidth = (key: string) => {
    const lowerCaseKey = key.toLowerCase();

    if (lowerCaseKey.includes("unit") || lowerCaseKey.includes("identifier")) {
        return 100;
    }
    if (
        lowerCaseKey.includes("area") ||
        lowerCaseKey.includes("consumption") ||
        lowerCaseKey.includes("emission", 1) ||
        lowerCaseKey.includes("from") ||
        lowerCaseKey.includes("to")
    ) {
        return 150;
    }
    if (lowerCaseKey.includes("address")) {
        return 250;
    }

    return 200;
};

export const getColumnSorter = (key: string) => {
    if (key === "to" || key === "from") {
        return dateSorter;
    }
    if (key === "classification") {
        return (a: string, b: string) => {
            if (!a || !a.length) return -1;
            if (!b || !b.length) return 1;
            return a[0].localeCompare(b[0]);
        };
    }
    const lowerCaseKey = key.toLowerCase();

    if (
        lowerCaseKey.includes("area") ||
        lowerCaseKey.includes("consumption") ||
        lowerCaseKey.includes("emission", 1)
    ) {
        return customNumberSorter;
    }
};
