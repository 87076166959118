import { Edit, Delete, FileDownload } from "@mui/icons-material";
import { IconButton, Box, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { TFunction } from "i18next";
import { DateTime } from "luxon";

import { getEpcLinkFromSingleAssessment } from "containers/AssetPage/AssetDetails/AssetDetails.helpers";
import { dateSorter } from "containers/DataOverviewPage/dataOverview.helper";
import { ConsumptionType } from "graphql-types/graphql";
import { assessmentVerticalToTitle } from "utils/assessment";
import { isManualMeterData, isManualEpcData } from "utils/assessmentHelpers";
import { formatDate } from "utils/date.utils";
import { getUnitByKey } from "utils/report.helpers";
import { DASH_SEPARATOR } from "utils/strings.helpers";

import { ManualAssessment } from "./manualSourcesTable.types";

export const formatRows = (assessments: ManualAssessment[], t: TFunction) => {
    return assessments
        .map((assessment) => ({
            type: getManualSourceType(t, assessment),
            id: assessment.id,
            createdAt: assessment.createdAt,
            updatedAt: assessment.updatedAt,
            vertical: assessment.vertical,
            category: assessment.consumptionType,
            identifier: getManualIdentifier(assessment),
            from: assessment.from,
            to: assessment.to,
            consumption: assessment.consumption,
            unit: assessment.unit,
            actions: assessment,
            link: isManualEpcData(assessments[0])
                ? getEpcLinkFromSingleAssessment(assessment)
                : assessment.origin?.file?.url || null,
        }))
        .sort(
            (a, b) =>
                DateTime.fromISO(b.createdAt).toMillis() -
                DateTime.fromISO(a.createdAt).toMillis()
        );
};

export const getManualSourceType = (
    t: TFunction,
    assessment: ManualAssessment
) => {
    if (isManualMeterData(assessment)) {
        return t("dataQuality.source.manual", "Manual Meter", {
            ns: "translation",
        });
    }

    if (isManualEpcData(assessment)) {
        return t("dataQuality.source.manualEpc", "Manual EPC", {
            ns: "translation",
        });
    }

    return t("dataQuality.source.uncategorized", "Uncategorized", {
        ns: "translation",
    });
};

export const getManualIdentifier = (assessment: ManualAssessment) => {
    if (assessment.data && "identifier" in assessment.data) {
        return assessment.data.identifier;
    }

    if (assessment.data && "lmkKey" in assessment.data) {
        return assessment.data.lmkKey;
    }

    if (assessment.data && "classification" in assessment.data) {
        return assessment.data.classification;
    }

    return DASH_SEPARATOR;
};

export const getColumns = (
    t: TFunction,
    handleEdit: (entry: ManualAssessment) => void,
    setDeleteId: (value: string) => void
): GridColDef[] => [
    {
        field: "type",
        headerName: t("columns.type", "Type", { ns: "report" }),
        flex: 0.5,
    },
    {
        field: "createdAt",
        headerName: t("columns.created", "Created", { ns: "report" }),
        flex: 1,
        sortable: true,
        sortComparator: dateSorter,
        valueFormatter: (value: any) => formatDate(value),
    },
    {
        field: "updatedAt",
        headerName: t("columns.updated", "Updated", { ns: "report" }),
        flex: 1,
        sortable: true,
        sortComparator: dateSorter,
        valueFormatter: (value: any) => formatDate(value),
    },
    {
        field: "vertical",
        headerName: t("columns.vertical", "Vertical", {
            ns: "report",
        }),
        flex: 1,
        valueFormatter: (value: any) => assessmentVerticalToTitle(value, t),
    },
    {
        field: "category",
        headerName: t("columns.category", "Category", {
            ns: "report",
        }),
        flex: 1,
        valueFormatter: (value: ConsumptionType) =>
            t(`consumptionTypeCategory.${value}`, "ConsumptionType"),
    },
    {
        field: "identifier",
        headerName: t("columns.identifier", "Identifier", {
            ns: "report",
        }),
        flex: 1,
    },
    {
        field: "from",
        headerName: t("common.timeframe.from", "From"),
        flex: 1,
        sortable: true,
        sortComparator: dateSorter,
        valueFormatter: (value: any) => formatDate(value),
    },
    {
        field: "to",
        headerName: t("common.timeframe.to", "To"),
        flex: 1,
        sortable: true,
        sortComparator: dateSorter,
        valueFormatter: (value: any) => formatDate(value),
    },
    {
        field: "consumption",
        headerName: t("columns.consumption", "Consumption", {
            ns: "report",
        }),
        flex: 1,
        type: "number",
        sortable: true,
    },
    {
        field: "unit",
        headerName: t("columns.unit", "Unit", { ns: "report" }),
        flex: 1,
        valueFormatter: (value: any) => getUnitByKey(value, t),
    },
    {
        field: "source",
        headerName: t("common.labels.source", "Source"),
        flex: 1,
        disableExport: true,
        renderCell: (data) => {
            return (
                data.row?.link && (
                    <Link
                        href={data.row.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        margin="auto"
                    >
                        <IconButton color="primary">
                            <FileDownload />
                        </IconButton>
                    </Link>
                )
            );
        },
    },
    {
        field: "actions",
        flex: 2,
        headerName: t("columns.actions", "Actions", { ns: "report" }),
        headerAlign: "center",
        align: "right",
        disableExport: true,
        renderCell: (data) => (
            <Box>
                <IconButton
                    disabled={!data.value.isEditable}
                    onClick={() => handleEdit(data.value)}
                    color="primary"
                >
                    <Edit />
                </IconButton>
                <IconButton
                    onClick={() => setDeleteId(data.value.id)}
                    color="primary"
                >
                    <Delete />
                </IconButton>
            </Box>
        ),
    },
];
