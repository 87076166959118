import { GridToolbarContainer } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Table, TableToolbarExport } from "components/Table";
import { useOrganizationContext } from "utils/userContext";

import {
    formatFields,
    getChangeLogFileName,
    getTableColumns,
    mapFieldTranslation,
} from "./ChangeLog.helpers";
import { ChangeLogType } from "./useChangeLogQuery";

export const ChangeLogTable = ({
    changeLogs,
    isChangeLogPage = false,
    isLoading,
    assetName,
}: {
    changeLogs: ChangeLogType[];
    isChangeLogPage?: boolean;
    isLoading: boolean;
    assetName?: string;
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation(["translation", "report"]);
    const organization = useOrganizationContext();
    const fileName = getChangeLogFileName(
        t,
        isChangeLogPage,
        assetName,
        organization?.name
    );

    const CustomToolbar = useCallback(
        () => (
            <GridToolbarContainer
                sx={{ justifyContent: "flex-end", marginTop: "0.5rem" }}
            >
                <TableToolbarExport
                    csvOptions={{
                        fileName,
                    }}
                />
            </GridToolbarContainer>
        ),
        [fileName]
    );

    const rows = useMemo(() => {
        return changeLogs.flatMap((event) => {
            const { eventPayload, id, updatedBy, eventCreatedAt } = event;

            const labels =
                "recordLabels" in event
                    ? Object.assign(
                          {},
                          ...event.recordLabels.map(({ label, value }) => ({
                              [label]: value,
                          }))
                      )
                    : {};

            return eventPayload.changes.map((change) => ({
                id: `${id}-${change.field}`,
                timestamp: new Date(eventCreatedAt),
                before: formatFields(change.oldValue, change.field, t),
                after: formatFields(change.newValue, change.field, t),
                field: mapFieldTranslation(change.field, t),
                user: updatedBy?.name || eventPayload.user.email,

                ...labels,
            }));
        });
    }, [changeLogs, t]);

    return (
        <Table
            rows={rows}
            columns={getTableColumns(isChangeLogPage, t, language)}
            setCustomHeight={isChangeLogPage ? undefined : "95%"}
            isLoading={isLoading}
            slots={{ toolbar: CustomToolbar }}
        />
    );
};
