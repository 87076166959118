import { useQuery } from "@apollo/client";
import { AddBox, CheckCircle } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
    DataQualityEnum,
    getDataQualityColor,
    mapDataQualitySources,
} from "components/DataQuality/DataQuality.helpers";
import Loading from "components/Loading/Loading";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import {
    GetAssetDataCoverageQuery,
    GetAssetDataCoverageQueryVariables,
} from "graphql-types/graphql";
import {
    assessmentVerticalToColor,
    assessmentVerticalToTitle,
    verticalSorter,
} from "utils/assessment";
import { MONTHS_IN_YEAR, getStartAndEndOfYear } from "utils/date.utils";

import AssetBestEffortTimeline, {
    DataCoverageAssessments,
} from "./AssetBestEffortTimeline";
import {
    getBestEffortAssessmentsSources,
    getDataQualityByPriority,
    getMonthlyAssessmentCoverageData,
    isSourceInParentMonth,
} from "./AssetDataOverview.helper";
import { ASSET_DATA_COVERAGE } from "./assetDataQuery";

const alignCenter = {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "32px 132px 1fr",
};

const SourcesAccordion = ({
    sources,
    parentAssessments,
}: {
    sources: {
        source: string;
        dataQuality: DataQualityEnum;
        assessments: Record<string, DataCoverageAssessments>;
    }[];
    parentAssessments: Record<string, DataCoverageAssessments>;
}) => {
    const { t } = useTranslation();
    const { palette } = useTheme();

    return (
        <AccordionDetails>
            {sources.map(({ source, dataQuality, assessments }) => {
                const color = getDataQualityColor(dataQuality, palette);

                const isSourceInParentAssessments = _.range(
                    1,
                    MONTHS_IN_YEAR + 1
                ).some((month) =>
                    isSourceInParentMonth(month, assessments, parentAssessments)
                );

                return (
                    <Box key={source} sx={{ ...alignCenter, mb: 2 }}>
                        <CheckCircle
                            sx={{
                                fontSize: 20,
                                color: isSourceInParentAssessments
                                    ? color
                                    : "grey.300",
                            }}
                        />
                        <Typography
                            variant="body2"
                            color={
                                isSourceInParentAssessments
                                    ? "text.primary"
                                    : "text.disabled"
                            }
                        >
                            {mapDataQualitySources(source, t)}
                        </Typography>
                        <AssetBestEffortTimeline
                            color={color}
                            assessments={assessments}
                            parentAssessments={parentAssessments}
                        />
                    </Box>
                );
            })}
        </AccordionDetails>
    );
};

const AssetBestEffortCoverage = ({ locationId }: { locationId: string }) => {
    const { activeYear } = useSelectedYearContext();

    const { t } = useTranslation();

    const yearRange = getStartAndEndOfYear(activeYear);
    const { data, loading } = useQuery<
        GetAssetDataCoverageQuery,
        GetAssetDataCoverageQueryVariables
    >(ASSET_DATA_COVERAGE(false), {
        variables: { locationId, from: yearRange.from, to: yearRange.to },
    });

    const { data: bestEffortData, loading: bestEffortLoading } = useQuery<
        GetAssetDataCoverageQuery,
        GetAssetDataCoverageQueryVariables
    >(ASSET_DATA_COVERAGE(true), {
        variables: { locationId, from: yearRange.from, to: yearRange.to },
    });

    const verticals = useMemo(() => {
        const location = data?.location;
        const bestEffortLocation = bestEffortData?.location;

        if (!location) {
            return [];
        }

        return _.compact(
            location?.assessmentGroups.map((group) => {
                const bestEffort = bestEffortLocation?.assessmentGroups.find(
                    (bestEffortGroup) =>
                        bestEffortGroup.vertical === group.vertical
                );

                return {
                    vertical: group.vertical,
                    bestEffortAssessments: bestEffort
                        ? getBestEffortAssessmentsSources(
                              bestEffort.assessments,
                              bestEffort.maxHighPriority,
                              bestEffort.maxMediumPriority
                          )
                        : [],
                    sources: _.chain(group.assessments)
                        .sortBy((a) => a.bestEffortPriority)
                        .groupBy((a) => a.source)
                        .map((assessments, source) => ({
                            source,
                            assessments: getMonthlyAssessmentCoverageData(
                                assessments,
                                t
                            ),
                            dataQuality: getDataQualityByPriority(
                                assessments[0].bestEffortPriority,
                                group.maxHighPriority,
                                group.maxMediumPriority
                            ),
                        }))
                        .value(),
                };
            })
        ).sort(verticalSorter);
    }, [bestEffortData, data, t]);

    if (loading || bestEffortLoading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    if (!verticals.length) {
        return (
            <Paper sx={{ p: 2 }}>
                <Typography variant="body2">
                    {t(
                        "assessment.common.noData",
                        "No available data for this period."
                    )}
                </Typography>
            </Paper>
        );
    }

    return (
        <>
            <Typography sx={{ mb: 4, p: 2, pt: 0 }}>
                {t(
                    "assetCoverage.bestEffortCoverageDescription",
                    "This overview gives you a glanceable visualization of your monthly consumption coverage. The data source and quality are specified per vertical. Expand the columns to see all the data sources available and what is being used in your emissions calculations."
                )}
            </Typography>
            {verticals.map(({ vertical, sources, bestEffortAssessments }) => (
                <Accordion
                    key={vertical}
                    sx={{ ":before": { display: "none" }, p: 2, mb: 2 }}
                >
                    <AccordionSummary
                        sx={{ ".MuiAccordionSummary-content": alignCenter }}
                    >
                        <AddBox
                            sx={{
                                color: `${assessmentVerticalToColor(
                                    vertical
                                )}.main`,
                            }}
                        />
                        <Typography fontWeight={700}>
                            {assessmentVerticalToTitle(vertical, t)}
                        </Typography>
                        <AssetBestEffortTimeline
                            assessments={bestEffortAssessments}
                        />
                    </AccordionSummary>
                    <SourcesAccordion
                        sources={sources}
                        parentAssessments={bestEffortAssessments}
                    />
                </Accordion>
            ))}
        </>
    );
};

export default AssetBestEffortCoverage;
