import { Routes, Route, useParams } from "react-router-dom";

import AssetDataOverview from "./AssetDataOverview/AssetDataOverview";
import AssetDetails from "./AssetDetails/AssetDetails";
import AssetInventory from "./AssetInventory/AssetInventory";
import AssetOverview from "./AssetOverview/AssetOverview";
import AssetPage from "./AssetPage";

type AssetPageParams = {
    locationId: string;
};

const AssetPageRouter = () => {
    const { locationId } = useParams<AssetPageParams>();
    return (
        <Routes>
            <Route element={<AssetPage />}>
                <Route
                    path="/details"
                    element={<AssetDetails locationId={locationId ?? ""} />}
                />
                <Route
                    path="/inventory"
                    element={<AssetInventory locationId={locationId ?? ""} />}
                />
                <Route
                    path="/data-sources"
                    element={
                        <AssetDataOverview locationId={locationId ?? ""} />
                    }
                />
                <Route
                    path="/"
                    element={<AssetOverview locationId={locationId ?? ""} />}
                />
            </Route>
        </Routes>
    );
};

export default AssetPageRouter;
