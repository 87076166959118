import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
    BarChart,
    CartesianGrid,
    YAxis,
    Tooltip,
    Bar,
    XAxis,
    ResponsiveContainer,
    Legend,
} from "recharts";

import CustomLegend from "components/PortfolioDashboard/Charts/Legend/CustomLegend";
import DataQualityChartTooltip from "components/PortfolioDashboard/Charts/Tooltip/DataQualityChartTooltip";

import { mapDataQualitySources } from "../DataQuality.helpers";
import { DataQualityChartData } from "../dataQuality.types";

type Props = {
    dataQualityData: DataQualityChartData;
    containerHeight?: number;
};

const DataQualityBarCharts = (props: Props) => {
    const { dataQualityData, containerHeight = 500 } = props;
    const { sources, colors, data } = dataQualityData;
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ResponsiveContainer width="100%" height={containerHeight}>
            <BarChart data={data}>
                <CartesianGrid
                    stroke={theme.palette.grey.A100}
                    strokeDasharray="100% 0.5"
                    vertical={false}
                    dy={10}
                />
                <YAxis
                    type="number"
                    domain={[0, 100]}
                    allowDataOverflow={true}
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    tick={{ fontSize: "10px" }}
                    label={{
                        value: `${t(
                            "portfolioDashboard.chartHelpers.percentage",
                            "%"
                        )}`,
                        fill: theme.palette.grey[600],
                        fontSize: "12px",
                        angle: -90,
                        position: "insideBottomLeft",
                        textAnchor: "start",
                        dx: 15,
                        props: { fontSize: "6px" },
                    }}
                />

                <Legend
                    wrapperStyle={{ top: "-50px" }}
                    verticalAlign="top"
                    content={CustomLegend}
                />

                <Tooltip cursor={false} content={DataQualityChartTooltip} />

                {sources.map((source) => (
                    <Bar
                        key={source}
                        dataKey={source}
                        stackId="dataQuality"
                        fill={colors[source]}
                        maxBarSize={40}
                        name={mapDataQualitySources(source, t)}
                    />
                ))}

                <XAxis
                    dataKey="period"
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    xAxisId={1}
                    dy={5}
                    tick={{ fontSize: "14px" }}
                />
                <XAxis
                    dataKey="period"
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    xAxisId={0}
                    hide
                />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default DataQualityBarCharts;
