import { gql } from "@apollo/client";

export const LEGACY_BENCHMARKS_QUERY = gql`
    query GetLegacyBenchmarks($assetGroupId: ID) {
        getLegacyBenchmarks(assetGroupId: $assetGroupId) {
            id
            benchmarkType
            segment
            countryCode
            numberOfBuildings
            updatedAt
            values {
                id
                from
                to
                category
                value
                unit
                type
            }
        }
    }
`;
