import { Download } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useTranslation } from "react-i18next";

import { useActiveAssetGroup } from "components/AssetsGroup/hooks/useActiveAssetGroup";
import { BenchmarkPanel } from "components/Benchmark";
import { BenchmarkMarkers } from "components/types";

import { useDownloadContext } from "../helpers/useDownloadContext";
import PortfolioSelector from "../PortfolioSelector";
import { ChartType } from "../types";

type Props = {
    activeChart: ChartType;
    setActiveChart: (chart: ChartType) => void;
    markers: BenchmarkMarkers;
    setMarkers: (marker: BenchmarkMarkers) => void;
};

export const PortfolioOverviewChartHeader = (props: Props) => {
    const { activeChart, setActiveChart, markers, setMarkers } = props;

    const [activeGroup] = useActiveAssetGroup();
    const { onDownload } = useDownloadContext();

    const { useMuixCharts } = useFlags();
    const { t } = useTranslation();

    const isIntensityChart = activeChart === ChartType.PORTFOLIO_INTENSITY;

    function onToggleMarkerVisibility(markerId: string, isVisible: boolean) {
        const updatedBenchmarkMarkers = markers.map((marker) => {
            if (marker.id === markerId) {
                return { ...marker, isVisible };
            }

            return marker;
        });

        setMarkers(updatedBenchmarkMarkers);
    }

    return (
        <Box sx={{ display: "flex", gap: 2 }}>
            <PortfolioSelector
                isYearlyBreakdown={false}
                onChange={setActiveChart}
                activeChart={activeChart}
            />
            {isIntensityChart && useMuixCharts && (
                <Button
                    variant="outlined"
                    startIcon={<Download />}
                    onClick={() => onDownload()}
                >
                    {t("common.download", "Download")} .csv
                </Button>
            )}

            {isIntensityChart && (
                <Box sx={{ marginLeft: "auto" }}>
                    <BenchmarkPanel
                        allowDownload
                        title={activeGroup.name}
                        benchmarkMarkers={markers}
                        onToggleMarkerVisibility={onToggleMarkerVisibility}
                    />
                </Box>
            )}
        </Box>
    );
};
