import { Box, List, ListItem, Stack, styled, useTheme } from "@mui/material";

const LegendIcon = styled(Box)(({ theme }) => ({
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "4px",
    marginRight: theme.spacing(2),
}));

const LegendItem = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}));

function CustomLegend(props: any) {
    const { payload } = props;
    const theme = useTheme();

    return (
        <List
            component={Stack}
            justifyContent={"end"}
            direction="row"
            margin={{ bottom: theme.spacing(4) }}
        >
            {payload.map((entry: any, index: number) => (
                <ListItem style={{ width: "auto" }} key={`item-${index}`}>
                    <LegendItem>
                        <LegendIcon style={{ backgroundColor: entry.color }} />
                        {entry.value}
                    </LegendItem>
                </ListItem>
            ))}
        </List>
    );
}

export default CustomLegend;
