import { useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import Loading from "components/Loading/Loading";
import { BenchmarkMarkers } from "components/types";
import {
    AssessmentDataType,
    GetPortfolioOverviewAssessmentSummariesQuery,
    GetPortfolioOverviewAssessmentSummariesQueryVariables,
} from "graphql-types/graphql";

import Card from "../../Cards/Card";
import BuildingAverage from "../Charts/BuildingAverage";
import ChartContainer from "../Charts/ChartContainer";
import { DataQualityChart } from "../Charts/DataQualityChart";
import { MuiDataQualityChart } from "../Charts/MuiDataQualityChart";
import PortfolioIntensity from "../Charts/PortfolioIntensity";
import { PortfolioOverviewChartHeader } from "../Charts/PortfolioOverviewChartHeader";
import TotalEmissions from "../Charts/TotalEmissions";
import { YearlyBuildingAverageChart } from "../Charts/YearlyBuildingAverageChart";
import { YearlyIntensityChart } from "../Charts/YearlyIntensityChart";
import { YearlyTotalEmissionsChart } from "../Charts/YearlyTotalEmissionsChart";
import { DownloadProvider } from "../helpers/useDownloadContext";
import { getSummaryYearOverviewOnType } from "../helpers/utilities";
import { PORTFOLIO_OVERVIEW_QUERY } from "../portfolioQueries";
import { ChartType } from "../types";

type Props = {
    activeTab: AssessmentDataType;
    benchmarkMarkers: BenchmarkMarkers;
};

const Overview = (props: Props) => {
    const { activeTab, benchmarkMarkers } = props;
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const [markers, setMarkers] = useState<BenchmarkMarkers>([]);

    const { useMuixCharts } = useFlags();

    const { data, loading } = useQuery<
        GetPortfolioOverviewAssessmentSummariesQuery,
        GetPortfolioOverviewAssessmentSummariesQueryVariables
    >(PORTFOLIO_OVERVIEW_QUERY, {
        variables: { from: `${DateTime.local().year}` },
    });

    const assessmentSummaries = useMemo(() => {
        const locations = data?.me.organization?.getLocations;

        if (!locations) return [];

        return getSummaryYearOverviewOnType([...locations], activeTab);
    }, [data, activeTab]);

    const activeChartParams = searchParams.get("element");

    const [activeChart, setActiveChart] = useState(
        activeChartParams ? parseInt(activeChartParams, 10) : 0
    );

    useEffect(() => {
        if (!benchmarkMarkers) {
            return;
        }

        setMarkers(benchmarkMarkers);
    }, [benchmarkMarkers]);

    if (loading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    return (
        <DownloadProvider>
            <Card>
                <PortfolioOverviewChartHeader
                    markers={markers}
                    activeChart={activeChart}
                    setMarkers={setMarkers}
                    setActiveChart={setActiveChart}
                />

                <ChartContainer>
                    {activeChart === ChartType.PORTFOLIO_INTENSITY &&
                        (useMuixCharts ? (
                            <YearlyIntensityChart
                                activeTab={activeTab}
                                assessmentSummaries={assessmentSummaries}
                                benchmarkMarkers={markers}
                            />
                        ) : (
                            <PortfolioIntensity
                                activeTab={activeTab}
                                assessmentSummaries={assessmentSummaries}
                                benchmarkMarkers={markers}
                            />
                        ))}
                    {activeChart === ChartType.TOTAL_EMISSION &&
                        (useMuixCharts ? (
                            <YearlyTotalEmissionsChart
                                assessmentSummaries={assessmentSummaries}
                            />
                        ) : (
                            <TotalEmissions
                                assessmentSummaries={assessmentSummaries}
                            />
                        ))}
                    {activeChart === ChartType.WEIGHTED_AVERAGE &&
                        (useMuixCharts ? (
                            <YearlyBuildingAverageChart
                                activeTab={activeTab}
                                assessmentSummaries={assessmentSummaries}
                            />
                        ) : (
                            <BuildingAverage
                                activeTab={activeTab}
                                assessmentSummaries={assessmentSummaries}
                            />
                        ))}
                    {activeChart === ChartType.DATA_QUALITY &&
                        (useMuixCharts ? (
                            <MuiDataQualityChart activeTab={activeTab} />
                        ) : (
                            <DataQualityChart activeTab={activeTab} />
                        ))}
                </ChartContainer>
            </Card>
        </DownloadProvider>
    );
};

export default Overview;
