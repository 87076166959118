import { GridRowModel } from "@mui/x-data-grid";
import { ReactNode } from "react";

import {
    AssessmentVertical,
    OverviewAssessmentFragment,
} from "graphql-types/graphql";

type LabelType = string | number;

export enum UnitMass {
    KILOGRAM = "KILOGRAM",
    TON = "TON",
}

export type ColorProps =
    | "electricity"
    | "heat"
    | "water"
    | "transport"
    | "waste"
    | "materials"
    | "cooling"
    | "energy"
    | "uncategorized";

export const OrderedAssessmentVerticals = [
    AssessmentVertical.ELECTRICITY,
    AssessmentVertical.HEATING,
    AssessmentVertical.WATER,
    AssessmentVertical.COOLING,
    AssessmentVertical.WASTE,
    AssessmentVertical.TRANSPORT,
    AssessmentVertical.MATERIALS,
    AssessmentVertical.ENERGY,
    AssessmentVertical.UN_CATEGORIZED,
];

export type AssessmentOverviewVerticalValue = {
    emissionValue: string;
    consumptionValues: {
        consumptionFormatted: number;
        consumption: number;
        unit: string;
    }[];
    sources: string[];
    hasEmissionUnit: boolean;
};

export interface AssessmentsOnDate {
    color: ColorProps;
    vertical: AssessmentVertical;
    assessments: OverviewAssessmentFragment[];
}

interface ChartTooltipItem {
    label: ReactNode;
    preFix: ReactNode | null;
    color: ColorProps;
}

export interface AssessmentStats {
    id: LabelType;
    chartLabel: LabelType;
    tableRowLabel: LabelType;
    total: number;
    chartTooltips: ChartTooltipItem[];
    data?: Record<string, GridRowModel>;
    projectedTotal?: number | null;
}
