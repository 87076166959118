import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading/Loading";
import {
    getBaselineBenchmarkGraphText,
    getMarketBenchmarkGraphText,
} from "components/PortfolioDashboard/helpers/emptyGraphs";
import { YEARLY_PERFORMANCE_QUERY } from "components/PortfolioDashboard/performanceQuery";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import {
    AssessmentDataType,
    Benchmark,
    BenchmarkType,
    GetYearlyPerformanceQuery,
    GetYearlyPerformanceQueryVariables,
} from "graphql-types/graphql";
import { useOrganizationContext } from "utils/userContext";

import {
    formatBenchmarkPerformanceLocation,
    getBenchmarkPerformanceChartData,
} from "./assetsBenchmarkPerformance.helpers";
import PerformanceChart from "../BenchmarkPerformanceChart";
import { useActiveCountryBenchmark } from "../utils/activeCountryBenchmarkContext";

type Props = {
    benchmarks: Benchmark[];
    benchmarkType: BenchmarkType;
    activeDataType: AssessmentDataType;
};

function AssetsBenchmarkPerformanceChart(props: Props) {
    const { activeDataType, benchmarkType, benchmarks } = props;

    const { t } = useTranslation();
    const { activeYear } = useSelectedYearContext();
    const { activeCountryCode } = useActiveCountryBenchmark();

    const { baselineYear } = useOrganizationContext() ?? {};

    const activeBenchmark = benchmarks.find(
        (benchmark) =>
            benchmark.type === benchmarkType &&
            (benchmark.countryCode === activeCountryCode ||
                benchmarkType === BenchmarkType.BASELINE)
    );

    const { data, loading } = useQuery<
        GetYearlyPerformanceQuery,
        GetYearlyPerformanceQueryVariables
    >(YEARLY_PERFORMANCE_QUERY, {
        variables: {
            benchmarkId: activeBenchmark?.id ?? "",
            year: activeYear.toString(),
        },
        skip: !activeBenchmark,
    });

    const performanceChartData = useMemo(() => {
        if (!data || !activeBenchmark) return [];

        const formattedPerformance = formatBenchmarkPerformanceLocation(
            data,
            activeDataType
        );

        return (
            getBenchmarkPerformanceChartData(
                activeBenchmark,
                activeYear,
                activeDataType,
                formattedPerformance,
                t
            ) ?? []
        );
    }, [activeDataType, activeBenchmark, activeYear, data, t]);

    if (loading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    if (!performanceChartData.length) {
        return activeBenchmark?.type === BenchmarkType.MARKET
            ? getMarketBenchmarkGraphText(t)
            : getBaselineBenchmarkGraphText(!!baselineYear, false, t);
    }

    return <PerformanceChart performanceChartData={performanceChartData} />;
}

export default AssetsBenchmarkPerformanceChart;
