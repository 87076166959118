import { useQuery } from "@apollo/client";
import { setUser as setSentryUser } from "@sentry/react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import React, {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useMemo,
} from "react";

import { GetMeQuery } from "graphql-types/graphql";

import { getReportingYear } from "./date.utils";
import { updateFlagsWithContext } from "./featureFlags";
import ME_QUERY from "../queries/me";

type User = GetMeQuery["me"];

const UserContext = createContext<User | undefined>(undefined);

export const useReportingYearContext = () => {
    const context = useContext(UserContext);

    return getReportingYear(context?.organization?.reportingYear);
};

export const useOrganizationContext = () => {
    const context = useContext(UserContext);

    return context?.organization ?? undefined;
};

export const useUserContext = () => {
    const context = useContext(UserContext);

    return context || undefined;
};

export const UserProvider = (props: PropsWithChildren) => {
    const { data } = useQuery<GetMeQuery>(ME_QUERY);

    const user = useMemo(() => {
        if (!data) return undefined;

        return data.me;
    }, [data]);

    const ldClient = useLDClient();

    useEffect(() => {
        if (!ldClient || !user?.organization) return;

        const { organization, email, name } = user;

        updateFlagsWithContext(
            ldClient,
            organization.id,
            organization.name,
            user.id,
            user.name ?? ""
        );

        setSentryUser({
            id: user.id,
            username: name ?? "",
            email,
            organization: organization.name,
        });
    }, [ldClient, user]);

    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    );
};
