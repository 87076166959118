import { TFunction } from "i18next";
import _ from "lodash";
import { DateTime } from "luxon";

import { UnitMass } from "components/AssessmentStats/types";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { EmissionSummaryLocation } from "./portfolioIntensityByMonthChart.utils";
import { getFormattedConvertedGramsToMassUnit } from "../charts.helper";
import { MonthChartData } from "../MonthChart";

type TotalEmissionByMonth = {
    totalEmission: number;
    month: number;
};

export const getTotalEmissionByMonthChartData = (
    totalEmissionsByMonth: TotalEmissionByMonth[],
    activeYear: number,
    t: TFunction
) => {
    const chartData = _.chain(totalEmissionsByMonth)
        .map(({ totalEmission, month }): MonthChartData => {
            const date = DateTime.local(activeYear, month, 1);

            return {
                value: getConvertedGramsToMassUnit(
                    totalEmission,
                    UnitMass.KILOGRAM
                ),
                label: date.monthShort,
                title: t(
                    "portfolioDashboard.hoverBox.totalEmissions",
                    "Total Emissions"
                ),
                header: date.monthLong,
                formattedValue: getFormattedConvertedGramsToMassUnit(
                    totalEmission,
                    UnitMass.KILOGRAM,
                    t,
                    false
                ),
            };
        })
        .value();

    return chartData;
};

export const getTotalEmissionSummariesEmissionByMonth = (
    emissionSummariesLocations: EmissionSummaryLocation[]
): TotalEmissionByMonth[] => {
    const allEmissionSummaries = emissionSummariesLocations
        .map((esl) => esl.emissionSummaries)
        .flat();
    const emissionSummariesGroupedByMonth = _.chain(allEmissionSummaries)
        .groupBy(
            (emissionSummary) => DateTime.fromISO(emissionSummary.from).month
        )
        .map((emissionSummariesByMonth, month) => {
            return {
                month: Number(month),
                totalEmission: _.sumBy(
                    emissionSummariesByMonth,
                    (emissionSummary) => emissionSummary.ownedEmission || 0
                ),
            };
        })
        .sortBy((emissionSummariesByMonth) => emissionSummariesByMonth.month)
        .value();

    return emissionSummariesGroupedByMonth;
};
