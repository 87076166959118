import { TFunction } from "i18next";
import { UseFormSetValue } from "react-hook-form";
import { match, P } from "ts-pattern";

import {
    ConsumptionType,
    EmissionUnit,
    AssessmentDataType,
    CreateManualConsumptionInput,
    UpdateManualConsumptionInput,
    AssessmentVertical,
} from "graphql-types/graphql";
import { getIntervals, areIntervalsOverlapping } from "utils/date.utils";

import { ManualEntryInputs, Period } from "./manualConsumptionModal.types";
import { ManualAssessment } from "../ManualSourcesTable/manualSourcesTable.types";

export const getCategories = (t: TFunction) => [
    {
        label: t(
            "consumptionTypeCategory.ENERGY_HEATING_FUELS_GAS_NATURALGAS_CITYGAS",
            "City Gas"
        ),
        vertical: AssessmentVertical.HEATING,
        consumptionType:
            ConsumptionType.ENERGY_HEATING_FUELS_GAS_NATURALGAS_CITYGAS,
    },
    {
        label: t(
            "consumptionTypeCategory.ENERGY_COOLING_REMOTE",
            "Cooling Remote / District chilled water"
        ),
        vertical: AssessmentVertical.COOLING,
        consumptionType: ConsumptionType.ENERGY_COOLING_REMOTE,
    },
    {
        label: t(
            "consumptionTypeCategory.ENERGY_ELECTRICITY",
            "Electricity General"
        ),
        vertical: AssessmentVertical.ELECTRICITY,
        consumptionType: ConsumptionType.ENERGY_ELECTRICITY,
    },
    {
        label: t(
            "consumptionTypeCategory.ENERGY_ELECTRICITY_SOLAR",
            "Electricity Solar"
        ),
        vertical: AssessmentVertical.ELECTRICITY,
        consumptionType: ConsumptionType.ENERGY_ELECTRICITY_SOLAR,
    },
    {
        label: t("consumptionTypeCategory.ENERGY_HEATING", "Heating General"),
        vertical: AssessmentVertical.HEATING,
        consumptionType: ConsumptionType.ENERGY_HEATING,
    },
    {
        label: t(
            "consumptionTypeCategory.ENERGY_HEATING_INCINERATION",
            "Heating Incineration"
        ),
        vertical: AssessmentVertical.HEATING,
        consumptionType: ConsumptionType.ENERGY_HEATING_INCINERATION,
    },
    {
        label: t(
            "consumptionTypeCategory.ENERGY_HEATING_FUELS_LIQUID_OIL",
            "Heating Oil"
        ),
        vertical: AssessmentVertical.HEATING,
        consumptionType: ConsumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL,
    },
    {
        label: t(
            "consumptionTypeCategory.ENERGY_HEATING_REMOTE",
            "Heating Remote"
        ),
        vertical: AssessmentVertical.HEATING,
        consumptionType: ConsumptionType.ENERGY_HEATING_REMOTE,
    },
    {
        label: t(
            "consumptionTypeCategory.ENERGY_HEATING_FUELS_GAS_LIQUIFIEDNATURALGAS",
            "Liquefied Natural Gas"
        ),
        vertical: AssessmentVertical.HEATING,
        consumptionType:
            ConsumptionType.ENERGY_HEATING_FUELS_GAS_LIQUIFIEDNATURALGAS,
    },
    {
        label: t(
            "consumptionTypeCategory.ENERGY_HEATING_FUELS_GAS_NATURALGAS",
            "Natural Gas"
        ),
        vertical: AssessmentVertical.HEATING,
        consumptionType: ConsumptionType.ENERGY_HEATING_FUELS_GAS_NATURALGAS,
    },
    {
        label: t("consumptionTypeCategory.ENERGY", "Other Energy"),
        vertical: AssessmentVertical.ENERGY,
        consumptionType: ConsumptionType.ENERGY,
    },
    {
        label: t("consumptionTypeCategory.WATER", "Water"),
        vertical: AssessmentVertical.WATER,
        consumptionType: ConsumptionType.WATER,
    },
    {
        label: t("consumptionTypeCategory.WASTE_BATTERY", "Batteries"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_BATTERY,
    },
    {
        label: t("consumptionTypeCategory.WASTE_BULKY", "Bulky"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_BULKY,
    },
    {
        label: t("consumptionTypeCategory.WASTE_CARDBOARD", "Cardboard"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_CARDBOARD,
    },
    {
        label: t(
            "consumptionTypeCategory.WASTE_CONSTRUCTION",
            "Construction waste such as asphalt, concrete, iron, gypsum, etc."
        ),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_CONSTRUCTION,
    },
    {
        label: t(
            "consumptionTypeCategory.WASTE_ENVIRONMENTALLY_HAZARDOUS_BUSINESS",
            "Environmentally hazardous waste from businesses"
        ),
        vertical: AssessmentVertical.WASTE,
        consumptionType:
            ConsumptionType.WASTE_ENVIRONMENTALLY_HAZARDOUS_BUSINESS,
    },
    {
        label: t("consumptionTypeCategory.WASTE_FOOD", "Food"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_FOOD,
    },
    {
        label: t(
            "consumptionTypeCategory.WASTE_FOOD_DRINK_CARTON",
            "Food and drink cartons"
        ),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_FOOD_DRINK_CARTON,
    },
    {
        label: t("consumptionTypeCategory.WASTE_GARDEN", "Garden"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_GARDEN,
    },
    {
        label: t("consumptionTypeCategory.WASTE_GIFT_PAPER", "Gift paper"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_GIFT_PAPER,
    },
    {
        label: t("consumptionTypeCategory.WASTE_GLASS", "Glass"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_GLASS,
    },
    {
        label: t(
            "consumptionTypeCategory.WASTE_HAZARDOUS_HOUSEHOLD",
            "Hazardous household"
        ),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_HAZARDOUS_HOUSEHOLD,
    },
    {
        label: t("consumptionTypeCategory.WASTE_METAL", "Metal"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_METAL,
    },
    {
        label: t("consumptionTypeCategory.WASTE_PAPER", "Paper"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_PAPER,
    },
    {
        label: t("consumptionTypeCategory.WASTE_PLASTIC_HARD", "Plastic hard"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_PLASTIC_HARD,
    },
    {
        label: t("consumptionTypeCategory.WASTE_PLASTIC_SOFT", "Plastic soft"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_PLASTIC_SOFT,
    },
    {
        label: t("consumptionTypeCategory.WASTE_RESIDUAL", "Residual"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_RESIDUAL,
    },
    {
        label: t(
            "consumptionTypeCategory.WASTE_SMALL_ELECTRONICS",
            "Small electronics"
        ),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_SMALL_ELECTRONICS,
    },
    {
        label: t("consumptionTypeCategory.WASTE_TEXTILES", "Textiles"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_TEXTILES,
    },
    {
        label: t("consumptionTypeCategory.WASTE_WATER", "Waste Water"),
        vertical: AssessmentVertical.WASTE,
        consumptionType: ConsumptionType.WASTE_WATER,
    },
];

export const formatCreateManualEntries = (
    data: ManualEntryInputs,
    locationId: string
): CreateManualConsumptionInput[] => {
    return data.periods.map((period) => {
        return {
            ...period,
            locationId,
            emissionUnit: period.emissionUnit,
            identifier: data.identifier,
            source: AssessmentDataType.ACTUAL,
            consumptionType: data.consumptionType,
        };
    });
};

export const formatUpdateManualEntry = (
    updatedData: Partial<ManualEntryInputs>,
    assessmentId: string
): UpdateManualConsumptionInput => {
    const period = updatedData.periods?.[0];

    return {
        assessmentId,
        emissionUnit: period?.emissionUnit,
        consumptionType: updatedData.consumptionType,
        identifier: updatedData.identifier,
        fields: period && {
            from: period.from,
            to: period.to,
            consumption: period.consumption,
            file: period.file,
        },
    };
};

export const getConsumptionTypeUnits = (
    category: ConsumptionType,
    t: TFunction
) => {
    if (!category) {
        return [];
    }

    const units = {
        liter: {
            value: EmissionUnit.LITER,
            label: t("common.units.liter.label", "liter"),
        },
        m3: {
            value: EmissionUnit.M_3,
            label: t("common.units.m3.label", "m³"),
        },
        kwh: {
            value: EmissionUnit.KWH,
            label: t("common.units.kwh.label", "kWh"),
        },
        gcal: {
            value: EmissionUnit.GCAL,
            label: t("common.units.gcal.label", "Gcal"),
        },
        gj: { value: EmissionUnit.GJ, label: t("common.units.gj.label", "GJ") },
        kg: { value: EmissionUnit.KG, label: t("common.units.kg.label", "kg") },
    };

    return match(category)
        .with(ConsumptionType.WATER, () => [units.liter, units.m3])
        .with(ConsumptionType.ENERGY_HEATING_REMOTE, () => [
            units.kwh,
            units.gcal,
            units.gj,
            units.m3,
        ])
        .with(ConsumptionType.ENERGY_HEATING_INCINERATION, () => [
            units.kwh,
            units.kg,
            units.m3,
        ])
        .with(ConsumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL, () => [
            units.liter,
        ])
        .with(P.string.includes("NATURALGAS"), () => [units.m3, units.kwh])
        .with(P.string.includes("WASTE"), () => [units.kg])
        .otherwise(() => [units.kg]);
};

export const resetFormUnitsOnCategoryChange = (
    fields: Period[],
    units: { label: string; value: EmissionUnit }[],
    setValue: UseFormSetValue<ManualEntryInputs>
) => {
    fields.forEach((field, index) => {
        if (
            units.length &&
            !units.find((unit) => unit.value === field.emissionUnit)
        ) {
            setValue(`periods.${index}.emissionUnit`, units[0].value);
        }
    });
};

const removeAssessmentWithSameId = (
    assessments: ManualAssessment[],
    editId: string
) => {
    return assessments.filter((assessment) => assessment.id !== editId);
};

const filterAssessmentsWithSameManualConsumptionType = (
    assessments: ManualAssessment[],
    consumptionType: ConsumptionType
) => {
    return assessments.filter(
        (assessment) => assessment.consumptionType === consumptionType
    );
};

export const areEntriesOverlapping = (
    entries: ManualEntryInputs,
    assessments: ManualAssessment[]
) => {
    if (entries.editId) {
        assessments = removeAssessmentWithSameId(assessments, entries.editId);
    }
    assessments = filterAssessmentsWithSameManualConsumptionType(
        assessments,
        entries.consumptionType
    );

    const entriesPeriodIntervals = getIntervals(entries.periods);
    const areEntriesOverlappingOtherEntries = areIntervalsOverlapping(
        entriesPeriodIntervals
    );

    const assessmentIntervals = getIntervals(assessments);
    const areEntriesOverlappingExistingAssessment = areIntervalsOverlapping(
        assessmentIntervals,
        entriesPeriodIntervals
    );

    return (
        areEntriesOverlappingOtherEntries ||
        areEntriesOverlappingExistingAssessment
    );
};
