import { useQuery } from "@apollo/client";
import { useTheme, Card } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading/Loading";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import {
    getYearMonthlyVerticalAssessments,
    getYearOverYearVerticalAssessments,
} from "containers/AssetPage/AssetOverview/AssetOverview.helpers";
import { ASSESSMENT_OVERVIEW_QUERY } from "containers/AssetPage/AssetOverview/assetOverviewQuery";
import {
    GetAllAssessmentsQuery,
    GetAllAssessmentsQueryVariables,
    AssessmentAggregation,
    AssessmentDataType,
} from "graphql-types/graphql";
import {
    assessmentVerticalToColor,
    assessmentVerticalToTitle,
} from "utils/assessment";
import { formatNumberLocale } from "utils/report.helpers";
import { useReportingYearContext } from "utils/userContext";

import { generateColumnDefinitions } from "./AssessmentOverviewStatsLayout.helpers";
import AssessmentChart from "./Chart/AssessmentChart";
import AssessmentTable from "./Table/AssessmentTable";
import { AssessmentStats, OrderedAssessmentVerticals } from "./types";

export const AssessmentOverviewStatsContent = ({
    loading,
    overviewAssessments,
    barStats,
    columns,
    isYearly,
}: {
    loading: boolean;
    overviewAssessments: AssessmentStats[];
    barStats: { key: string; fill?: string }[];
    columns: GridColDef[];
    isYearly: boolean;
}) => {
    const { t } = useTranslation();

    if (loading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    if (!overviewAssessments) {
        return t(
            "assessment.common.noAssessmentsOnDate",
            "No assessments on selected date"
        );
    }

    if (!overviewAssessments.length) {
        return t(
            "assessment.common.noData",
            "No available data for this period."
        );
    }

    return (
        <>
            <AssessmentChart
                assessmentStats={overviewAssessments}
                barStats={barStats}
                isYearly={isYearly}
            />
            <AssessmentTable
                assessmentStats={overviewAssessments}
                columns={columns}
            />
        </>
    );
};

const AssessmentOverviewStatsLayout = ({
    locationId,
    activeTab,
    isYearly,
}: {
    locationId: string;
    activeTab: AssessmentDataType;
    isYearly: boolean;
}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { activeYear } = useSelectedYearContext();
    const reportingYear = useReportingYearContext();

    const periodRange = isYearly
        ? {
              from: DateTime.fromFormat(activeYear.toString(), "yyyy")
                  .startOf("year")
                  .toFormat("yyyy-MM-dd"),
              to: DateTime.fromFormat(activeYear.toString(), "yyyy")
                  .endOf("year")
                  .toFormat("yyyy-MM-dd"),
          }
        : {
              from: DateTime.fromFormat(reportingYear.toString(), "yyyy")
                  .startOf("year")
                  .toFormat("yyyy-MM-dd"),
          };

    const variables = {
        ...periodRange,
        locationId,
        aggregation: isYearly
            ? AssessmentAggregation.MONTH
            : AssessmentAggregation.YEAR,
        ignoreProxy: activeTab === AssessmentDataType.ESTIMATE,
        verticals: OrderedAssessmentVerticals,
        assessmentFilter: { dataType: activeTab },
    };

    const { data, loading, previousData } = useQuery<
        GetAllAssessmentsQuery,
        GetAllAssessmentsQueryVariables
    >(ASSESSMENT_OVERVIEW_QUERY, { variables });

    const [assessmentGroups, overviewAssessments] = useMemo(() => {
        const location = data?.location ?? previousData?.location;

        if (!location) {
            return [[], []];
        }

        const assessmentGroups =
            location.assessmentGroups
                .map(({ vertical, assessments }) => ({
                    color: assessmentVerticalToColor(vertical),
                    vertical,
                    assessments: [...assessments],
                }))
                .filter((cat) =>
                    OrderedAssessmentVerticals.includes(cat.vertical)
                )
                .sort(
                    (a, b) =>
                        OrderedAssessmentVerticals.indexOf(a.vertical) -
                        OrderedAssessmentVerticals.indexOf(b.vertical)
                ) || [];

        const saleDate = location.saleDate
            ? new Date(location.saleDate)
            : undefined;
        const purchaseDate = location.purchaseDate
            ? new Date(location.purchaseDate)
            : undefined;

        const overviewAssessments = !assessmentGroups.length
            ? []
            : isYearly
            ? getYearMonthlyVerticalAssessments(
                  activeYear,
                  assessmentGroups,
                  location.projectedAssessments,
                  t,
                  saleDate,
                  purchaseDate
              )
            : getYearOverYearVerticalAssessments(
                  reportingYear,
                  assessmentGroups,
                  location.projectedAssessments,
                  t
              );

        return [assessmentGroups, overviewAssessments];
    }, [data, previousData, activeYear, reportingYear, t, isYearly]);

    const barStats = useMemo(
        () =>
            assessmentGroups.flatMap((category) => ({
                key: `data.${category.vertical}.emission`,
                fill: category.color
                    ? theme.palette[category.color]?.main
                    : theme.palette.primary.main,
            })),
        [theme, assessmentGroups]
    );
    const columns = useMemo(() => {
        const verticalColumns = assessmentGroups
            .map((group) => ({
                field: `data.${group.vertical}`,
                headerName: assessmentVerticalToTitle(group.vertical, t),
                color: group.color,
            }))
            .flatMap((def) => generateColumnDefinitions(def, t));

        return [
            {
                field: "tableRowLabel",
                headerName: isYearly
                    ? t("common.timeframe.year", "Year")
                    : t("common.timeframe.date", "Date"),
                flex: 1,
            },
            ...verticalColumns,
            {
                field: "total",
                headerName: t("common.total", "Total"),
                valueFormatter: (value: any) =>
                    `${formatNumberLocale(value, t)} kg CO2e`,
                flex: 1,
                disableExport: true,
            },
            {
                field: "totalValue",
                headerName: t("common.total", "Total"),
                valueGetter: (_, row) => row.total,
                valueFormatter: (value: any) => (value ? value : 0),
            },
            {
                field: "totalUnit",
                headerName: `Total - (${t(
                    "dataPage.gridTable.consumptionUnit",
                    "Consumption Unit"
                )})`,
                valueGetter: (_, row) => row.total,
                valueFormatter: (value: any) => (value ? "kg CO2e" : "-"),
            },
        ] as GridColDef[];
    }, [t, assessmentGroups, isYearly]);

    return (
        <Card sx={{ p: 4 }}>
            <AssessmentOverviewStatsContent
                loading={loading}
                overviewAssessments={overviewAssessments}
                barStats={barStats}
                columns={columns}
                isYearly={isYearly}
            />
        </Card>
    );
};

export default AssessmentOverviewStatsLayout;
