import { GridColDef } from "@mui/x-data-grid";
import { TFunction } from "i18next";
import _, { values } from "lodash";

import { DataStatusCell } from "components/Cells/DataStatusCell";
import { DownloadCell } from "components/Cells/DownloadCell";
import EpcClassificationsCell from "components/Cells/EpcClassificationsCell";
import NationalBuildingIdsCell from "components/Cells/NationalBuildingIdsCell";
import { TableHeader } from "components/Table";
import { getFormattedConsumptionWithUnit } from "containers/AssetPage/AssetDetails/AssetDetails.helpers";
import { formatNumberLocale } from "utils/report.helpers";
import { customNumberSorter } from "utils/sorters";
import { DASH_SEPARATOR } from "utils/strings.helpers";

export const getSumFormattedEPCAreaRow = (
    items: { area: number }[]
): number => {
    return items.reduce((acc, item) => acc + item.area, 0);
};

export const getScoreDistribution = (
    sources: { score: string[]; area: number }[],
    totalArea: number,
    t: TFunction
) => {
    const grades = sources
        .flatMap((source) => ({
            score: source.score,
            area: source.area,
        }))
        .filter((source) => source.score.length > 0);

    const activeAreaByScore = _.chain(grades)
        .sortBy((item) => item.score)
        .groupBy((item) => item.score)
        .map((items, score) => {
            const scoreArea = getSumFormattedEPCAreaRow(items);

            return [
                EpcClassificationsCell([score]),
                items.length.toString(),
                `${formatNumberLocale((scoreArea / totalArea) * 100, t, {
                    min: 2,
                    max: 2,
                })}%`,
            ];
        })
        .value();

    return values(activeAreaByScore);
};

export const dateSorter = (a: string, b: string) => {
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;

    a = a.split("/").reverse().join("");
    b = b.split("/").reverse().join("");
    return a.localeCompare(b);
};

export const getEPCsOverviewColumns = (t: TFunction): GridColDef[] => {
    return [
        {
            field: "epc",
            headerName: t("dataLocationCategory.epc", "EPC #"),
            flex: 0.5,
        },
        {
            field: "score",
            headerName: t("dataLocationCategory.score", "Score"),
            flex: 0.5,
            sortComparator: (a, b) => (a[0] || "").localeCompare(b[0] || ""),
            renderCell: (params) =>
                EpcClassificationsCell(params.value as string[]),
        },
        {
            field: "category",
            headerName: t("dataLocationCategory.category", "Category"),
            sortable: false,
            flex: 1,
        },
        {
            field: "address",
            headerName: t("dataLocationCategory.address", "Address"),
            flex: 1.5,
        },
        {
            field: "nationalIdentifiers",
            headerName: t(
                "dataLocationCategory.nationalBuildingId",
                "National Building ID"
            ),
            flex: 1,
            sortable: false,
            renderCell: (params) =>
                NationalBuildingIdsCell(params.value as string[]),
        },
        {
            field: "from",
            headerName: t("common.timeframe.from", "From"),
            sortComparator: dateSorter,
            flex: 0.8,
        },
        {
            field: "to",
            headerName: t("common.timeframe.to", "To"),
            sortComparator: dateSorter,
            flex: 0.8,
        },
        {
            field: "status",
            flex: 0.5,
            headerName: t("dataLocationCategory.status", "Status"),
            renderCell: ({ value }) => DataStatusCell({ status: value, t }),
            width: 70,
        },

        {
            field: "area",
            flex: 1,
            headerName: t("dataLocationCategory.area", "Total Area"),
            sortComparator: customNumberSorter,
            renderCell: ({ value }) =>
                value
                    ? formatNumberLocale(value, t, {
                          max: 0,
                      })
                    : DASH_SEPARATOR,
            width: 70,
        },
        {
            field: "totalElectricityConsumption",
            flex: 1.5,
            headerName: t(
                "dataLocationCategory.totalElectricityConsumption",
                "Total electricity consumption"
            ),
            width: 70,
            renderCell: ({ value }) =>
                getFormattedConsumptionWithUnit(t, value.value, value.unit),
            sortable: false,
        },
        {
            field: "totalHeatingConsumption",
            flex: 1.5,
            headerName: t(
                "dataLocationCategory.totalHeatingConsumption",
                "Total heating consumption"
            ),
            width: 70,
            renderCell: ({ value }) =>
                getFormattedConsumptionWithUnit(t, value.value, value.unit),
            sortable: false,
        },

        {
            field: "epcLinks",
            sortable: false,
            headerName: t("dataLocationCategory.source", "Source"),
            width: 95,
            renderCell: ({ value }) =>
                Array.isArray(value)
                    ? value.map((link) =>
                          DownloadCell({
                              downloadLink: link,
                              key: link,
                          })
                      )
                    : null,
            renderHeader: (params) =>
                TableHeader({
                    params,
                    infoText: t(
                        "dataLocationCategory.epcSourceHeaderInfo",
                        "For some types of EPCs it will be necessary to login to the provider to be able to access the source. You will be redirected to the provider's login page instead in these cases."
                    ),
                }),
        },
    ];
};
