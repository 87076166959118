import { range } from "lodash";
import { DateTime } from "luxon";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
} from "react";
import { useSearchParams } from "react-router-dom";

import { getCurrentYear } from "utils/date.utils";
import { useBrowserStorage } from "utils/useBrowserStorage";
import { useReportingYearContext } from "utils/userContext";

export const ACTIVE_YEAR_SEARCH_PARAMS = "year";

interface InputProps {
    children: React.ReactNode;
}

type YearContext = {
    activeYear: number;
    activeFrom: string;
    activeTo: string;
    setActiveYear: (value: number, keepSearchParams?: boolean) => void;
};

const SelectedYearContext = createContext({} as YearContext);

export const useSelectedYearContext = () => {
    const context = useContext(SelectedYearContext);

    if (!context) {
        throw new Error(
            "useSelectedYearContext must be used within a YearContextProvider"
        );
    }

    return context;
};

const getValidSelectedYear = (
    reportingYear: number,
    searchParamsYear: string | null
) => {
    const activeYear = searchParamsYear ? parseInt(searchParamsYear, 10) : null;

    const currentYear = getCurrentYear();
    const availableYears = range(reportingYear, currentYear + 1);

    // eslint-disable-next-line no-extra-boolean-cast
    return Boolean(
        activeYear && !isNaN(activeYear) && availableYears.includes(activeYear)
    )
        ? activeYear
        : undefined;
};

function SelectedYearProvider(props: InputProps) {
    const reportingYear = useReportingYearContext();

    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamsYear = useMemo(
        () => searchParams.get(ACTIVE_YEAR_SEARCH_PARAMS),
        [searchParams]
    );

    const [activeYear, setActiveYear] = useBrowserStorage<number>(
        ACTIVE_YEAR_SEARCH_PARAMS,
        getValidSelectedYear(reportingYear, searchParamsYear) ??
            getCurrentYear(),
        getValidSelectedYear(reportingYear, searchParamsYear)
    );

    const updateActiveYear = useCallback(
        (value: number, keepSearchParams = true) => {
            if (keepSearchParams) {
                setSearchParams((prev) => {
                    prev.set(ACTIVE_YEAR_SEARCH_PARAMS, value.toString());
                    return prev;
                });
            } else {
                setSearchParams(
                    () =>
                        new URLSearchParams({
                            [ACTIVE_YEAR_SEARCH_PARAMS]: value.toString(),
                        })
                );
            }
            setActiveYear(value);
        },
        [setSearchParams, setActiveYear]
    );

    useEffect(() => {
        const year =
            getValidSelectedYear(reportingYear, activeYear.toString()) ??
            getCurrentYear();
        updateActiveYear(year);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportingYear]);

    const { activeFrom, activeTo } = useMemo(() => {
        const date = `${activeYear}-01-01`;
        const parsedFrom = DateTime.fromFormat(date, "yyyy-MM-dd", {
            zone: "local",
        });
        return {
            activeFrom: date,
            activeTo: parsedFrom.endOf("year").toFormat("yyyy-MM-dd"),
        };
    }, [activeYear]);

    const value = {
        activeYear,
        setActiveYear: updateActiveYear,
        activeFrom,
        activeTo,
    };

    const { children } = props;

    return (
        <SelectedYearContext.Provider value={value}>
            {children}
        </SelectedYearContext.Provider>
    );
}

export default SelectedYearProvider;
