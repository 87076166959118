import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "recharts";

import { DataQualityBarData } from "components/DataQuality/dataQuality.types";

import { formatDataQualityToDataQualityItems } from "../../../DataQuality/DataQuality.helpers";
import { DataQualitySummary } from "../../../DataQuality/DataQualitySummary";
import { TooltipContainer, HeaderBox } from "../tooltip.styles";

function DataQualityChartTooltip(props: TooltipProps<number, string>) {
    const tooltip = _.first(props.payload)?.payload as DataQualityBarData;
    const { t } = useTranslation();

    const dataQualityItems = useMemo(
        () =>
            tooltip?.dataQuality
                ? formatDataQualityToDataQualityItems(tooltip.dataQuality, t)
                : [],
        [tooltip, t]
    );

    if (!tooltip) {
        return <></>;
    }

    const currentYear = new Date().getFullYear();
    const isCurrentYear = currentYear.toString() === tooltip.period;

    return (
        <TooltipContainer>
            <HeaderBox>
                {tooltip.period}
                {isCurrentYear &&
                    t("portfolioDashboard.yearSelect.ytd", " YTD")}
            </HeaderBox>
            <DataQualitySummary
                dataQuality={dataQualityItems}
                hasFullTitle={false}
            />
        </TooltipContainer>
    );
}

export default DataQualityChartTooltip;
