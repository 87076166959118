import React from "react";

function NotFoundPage() {
    return (
        <div>
            <p>The page you're looking for doesn't exist.</p>
        </div>
    );
}

export default NotFoundPage;
