import ApartmentIcon from "@mui/icons-material/Apartment";
import ClearIcon from "@mui/icons-material/Clear";
import {
    Box,
    styled,
    Typography,
    Tabs,
    Tab,
    Button,
    Paper,
} from "@mui/material";
import React from "react";

import { AssetAlertFragment } from "graphql-types/graphql";

import AssetAlertCell from "../AssetList/AssetAlertCell";

const HeaderContainer = styled(Paper)(({ theme }) => ({
    display: "flex",
    borderRadius: "5px",
    height: theme.spacing(27.15),
    marginBottom: theme.spacing(4),

    justifyContent: "space-between",
    padding: theme.spacing(6.25),
}));

const TypeBox = styled(Box)(({ theme }) => ({
    width: "100vw",
    display: "flex",
    alignItems: "center",
    transition: "all 0.2s ease-out",
}));

const LogoBox = styled(Box)(({ theme }) => ({
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    backgroundColor: "#dde7ff",
    justifyContent: "center",
    alignItems: "center",
    border: "solid 1px #dee8ff",
    borderRadius: theme.spacing(2.5),
    boxShadow: "0px 0px 2px 2px rgba(0,0,0,0.1)",
    padding: theme.spacing(1.75),
}));

const Building = styled(ApartmentIcon)(({ theme }) => ({
    fill: theme.palette.primary.main,
}));

const GoBackBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    borderRadius: theme.spacing(2.5),
    color: theme.palette.primary.main,
    border: "solid 2px currentColor",
}));

const GoBackButton = styled(Button)(({ theme }) => ({
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),

    "&:hover": {
        backgroundColor: "transparent",
    },

    "&:focus": {
        outline: "none",
    },
}));

const TextContainer = styled(Box)(({ theme }) => ({
    paddingLeft: theme.spacing(6.25),
}));

const HeaderTabs = styled(Tabs)(({ theme }) => ({
    width: "80%",
    display: "flex",
    alignItems: "center",
    overflow: "visible",
    transition: "all 0.4s ease-out",

    "& .MuiTabs-flexContainer": {
        gap: theme.spacing(4),
        justifyContent: "flex-end",
    },

    "& .MuiTabs-scroller": {
        overflow: "visible !important",
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    "&:focus": {
        outline: "none",
    },
    "&.Mui-selected": {
        color: theme.palette.common.black,
    },
}));

interface HeaderProps {
    onBack?: () => void;
    onChange?: (event: React.ChangeEvent<object>, newValue: number) => void;
    title: string;
    description?: string;
    tabs?: { labels: string[]; value: number };
    assetAlerts?: AssetAlertFragment[] | null;
}

const PageHeader = ({
    onChange,
    onBack,
    tabs,
    title,
    description,
    assetAlerts,
}: HeaderProps) => {
    return (
        <HeaderContainer>
            <TypeBox>
                {onBack ? (
                    <GoBackBox>
                        <GoBackButton onClick={onBack}>
                            <ClearIcon color="primary" />
                        </GoBackButton>
                    </GoBackBox>
                ) : (
                    <LogoBox>
                        <Building />
                    </LogoBox>
                )}
                <TextContainer>
                    {description ? (
                        <>
                            <Typography variant="h5" color="grey.400">
                                {title}
                            </Typography>
                            <Typography variant="h4">{description}</Typography>
                        </>
                    ) : (
                        <Typography variant="h4">{title}</Typography>
                    )}
                </TextContainer>
            </TypeBox>
            {tabs && (
                <HeaderTabs
                    value={tabs.value}
                    onChange={onChange}
                    TabIndicatorProps={{
                        style: {
                            height: "5px",
                            borderRadius: "5px",
                        },
                    }}
                >
                    {tabs.labels.map((val, i) => (
                        <StyledTab key={i} label={val} />
                    ))}
                    {assetAlerts && (
                        <AssetAlertCell assetAlerts={assetAlerts} />
                    )}
                </HeaderTabs>
            )}
        </HeaderContainer>
    );
};

export default PageHeader;
