import { Box, styled } from "@mui/material";
import { Outlet } from "react-router-dom";

import { BenchmarksProvider } from "utils/benchmarksContext";

const AssessmentsLayout = styled(Box)(({ theme }) => ({
    padding: theme.spacing(12.5, 12.5, 0),
    maxWidth: "100vw",

    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(24, 12.5, 0),
    },
}));

const AssessmentRoute = () => {
    return (
        <BenchmarksProvider>
            <AssessmentsLayout>
                <Outlet />
            </AssessmentsLayout>
        </BenchmarksProvider>
    );
};

export default AssessmentRoute;
