import { useQuery } from "@apollo/client";

import {
    ChangeLogEventType,
    GetChangeLogsQuery,
    GetChangeLogsQueryVariables,
} from "graphql-types/graphql";

import { CHANGE_LOGS_QUERY } from "./changeLogQueries";

export type ChangeLogType = GetChangeLogsQuery["getChangeLogs"][0];

export const useChangeLogQuery = (recordId?: string, skip = false) => {
    const { data, loading } = useQuery<
        GetChangeLogsQuery,
        GetChangeLogsQueryVariables
    >(CHANGE_LOGS_QUERY(!!recordId), {
        variables: {
            recordId,
            eventType: ChangeLogEventType.ASSET_UPDATED,
        },
        skip,
    });

    return {
        data: data?.getChangeLogs.map((log) => log) ?? [],
        loading,
    };
};
