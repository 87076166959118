import { useTheme } from "@mui/material";
import { TFunction } from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    Bar,
    CartesianGrid,
    Cell,
    ComposedChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

import {
    getFormattedConvertedGramsToMassUnit,
    getYearLabel,
} from "./charts.helper";
import ChartTooltip, { ChartDataItem } from "./ChartTooltip";
import { getCurrentYear } from "../../../utils/date.utils";
import { getConvertedGramsToMassUnit } from "../../../utils/report.helpers";
import { UnitMass } from "../../AssessmentStats/types";
import { getNoDataGraphText } from "../helpers/emptyGraphs";
import { SummaryYearOverview } from "../types";

type Props = {
    assessmentSummaries: SummaryYearOverview[];
};

type ChartData = {
    label: string | number;
    projectedValue: number | null;
} & ChartDataItem;

export function getTotalEmissionsChartData(
    t: TFunction,
    locationsByYear: SummaryYearOverview[]
) {
    const totalEmissionsChartData = locationsByYear.map(
        (summedYear): ChartData => {
            const isCurrentYear = getCurrentYear() === summedYear.year;

            const value = summedYear.emission;
            const projectedValue = isCurrentYear
                ? summedYear.projectedEmission
                : null;

            return {
                label: getYearLabel(summedYear.year, t),
                title: t(
                    "portfolioDashboard.hoverBox.totalEmissions",
                    "Total Emissions"
                ),
                value: getConvertedGramsToMassUnit(value, UnitMass.TON),
                formattedValue: getFormattedConvertedGramsToMassUnit(
                    value,
                    UnitMass.TON,
                    t,
                    false
                ),
                projectedValue: projectedValue
                    ? getConvertedGramsToMassUnit(projectedValue, UnitMass.TON)
                    : null,
                formattedProjectedValue: projectedValue
                    ? getFormattedConvertedGramsToMassUnit(
                          projectedValue,
                          UnitMass.TON,
                          t,
                          false
                      )
                    : null,
                header: summedYear.year,
                assetCount: summedYear.assetCount,
                totalArea: summedYear.totalArea,
            };
        }
    );

    return totalEmissionsChartData;
}

function TotalEmissions(props: Props) {
    const { assessmentSummaries } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    const totalEmissionsChartData = useMemo(() => {
        return getTotalEmissionsChartData(t, assessmentSummaries);
    }, [t, assessmentSummaries]);

    return totalEmissionsChartData.length > 0 ? (
        <ResponsiveContainer width="100%" height={446}>
            <ComposedChart
                data={totalEmissionsChartData}
                style={{ cursor: "pointer" }}
            >
                <CartesianGrid
                    stroke={theme.palette.grey.A100}
                    strokeDasharray="100% 0.5"
                    vertical={false}
                    dy={10}
                />

                <YAxis
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    tick={{ fontSize: "10px" }}
                    label={{
                        value: `${t(
                            "portfolioDashboard.chartHelpers.tonsCO2",
                            "tons CO2e"
                        )}`,
                        fill: theme.palette.grey[600],
                        fontSize: "12px",
                        angle: -90,
                        position: "insideBottomLeft",
                        textAnchor: "start",
                        dx: 15,
                        props: { fontSize: "6px" },
                    }}
                />

                <Tooltip cursor={false} content={ChartTooltip} />

                <Bar
                    legendType="none"
                    radius={[5, 5, 0, 0]}
                    barSize={40}
                    name="Projected Emissions"
                    dataKey="projectedValue"
                    fill={theme.palette.primary.light}
                    xAxisId={0}
                />

                <Bar
                    radius={[5, 5, 0, 0]}
                    barSize={40}
                    dataKey="value"
                    xAxisId={1}
                >
                    {totalEmissionsChartData.map((entry, index) => (
                        <Cell
                            fill={theme.palette.primary.main}
                            key={`cell-${index}`}
                        />
                    ))}
                </Bar>

                <XAxis
                    dataKey="label"
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    xAxisId={1}
                    dy={5}
                    tick={{ fontSize: "14px" }}
                />
                <XAxis
                    dataKey="label"
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    xAxisId={0}
                    hide
                />
            </ComposedChart>
        </ResponsiveContainer>
    ) : (
        getNoDataGraphText(t)
    );
}

export default TotalEmissions;
