import { GetForecastAssessmentsQuery } from "graphql-types/graphql";

type organization = NonNullable<
    GetForecastAssessmentsQuery["me"]["organization"]
>;

export type ForecastLocation = NonNullable<organization["getLocations"]>[0];

export interface LocationWithFilteringLabel {
    id: string;
    origin: string;
    name: string;
    externalId: string;
    allEmissions: AssessmentsSummaryUnion[];
}

export interface Locations extends ForecastLocation {
    allEmissions: AssessmentsSummaryUnion[];
}

export type AssessmentsSummaryUnion =
    | ForecastLocation["projectedAssessmentSummaries"][0]
    | ForecastLocation["assessmentSummaries"][0]
    | NonNullable<ForecastLocation["targetEmissions"]>[0];

export enum ChartType {
    totalEmissions = "totalEmissions",
    projectedEmissions = "projectedEmissions",
    targetEmissions = "targetEmissions",
    strandedAsset = "strandedAsset",
}
