import { Button, Card, CardContent, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AssessmentDataType, ReportFramework } from "graphql-types/graphql";

import {
    getTranslatedReportDescription,
    getTranslatedReportTitle,
} from "../Report/reportPageHelpers";

const Content = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(3, 6),
    display: "grid",
    gap: theme.spacing(4),
    gridTemplateRows: "220px min-content",
}));

const ReportTitle = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(3, 6),
    fontWeight: 700,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

const ReportListItem = ({
    reportType,
    updatedDate,
}: {
    reportType: ReportFramework;
    updatedDate: string;
}) => {
    const { t } = useTranslation(["report"]);

    const reportPath = {
        pathname: "/reports/" + reportType,
        search: new URLSearchParams({
            filter: AssessmentDataType.COMBINED,
        }).toString(),
    };

    return (
        <Card sx={{ maxWidth: "320px" }}>
            <ReportTitle>{getTranslatedReportTitle(t, reportType)}</ReportTitle>
            <Content>
                <Typography>
                    {getTranslatedReportDescription(t, reportType)}
                </Typography>
                <Typography variant="body2" color="grey.400">
                    {t(
                        "reportsList.item.date",
                        "Last updated: {{ updatedDate }}",
                        { updatedDate }
                    )}
                </Typography>
                <Link to={reportPath}>
                    <Button variant="contained" size="small">
                        {t("reportsList.item.button.label", "View Report")}
                    </Button>
                </Link>
            </Content>
        </Card>
    );
};

export default ReportListItem;
