import { useQuery } from "@apollo/client";
import {
    Button,
    CircularProgress,
    Container,
    MenuItem,
    TextField,
    Typography,
    Alert,
} from "@mui/material";
import { GridRowModel, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { clsx } from "clsx";
import { TFunction } from "i18next";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    AssessmentDataType,
    GetEmissionsQuery,
    GetLocationsQuery,
} from "graphql-types/graphql";

import { ParsedCsvRow } from "./dataUploadTypes";
import { useSubmitAssessments } from "./Hooks";
import { GET_EMISSIONS_QUERY, GET_LOCATIONS_QUERY } from "./uploadQueries";
import { parseResults } from "./utilities";
import GoToSecretPageButton from "../../components/GoToSecretPageButton/GoToSecretPageButton";
import PapaParse from "../../components/PapaParse/PapaParse";
import { Table, TablePagination } from "../../components/Table";
import {
    FormControls,
    GridTableContainer,
    HeaderBox,
    SuccessBox,
    UploadContainer,
} from "../../styling/box";

const availableAssessmentTypes = Object.values(AssessmentDataType).filter(
    (type) => type !== AssessmentDataType.COMBINED
);

const translateAssessmentDataTypes = (
    t: TFunction,
    type: AssessmentDataType
) => {
    switch (type) {
        case AssessmentDataType.ESTIMATE:
            return t("dropdown.epcData", "EPC Data", { ns: "report" });
        case AssessmentDataType.ACTUAL:
            return t("dropdown.meterData", "Meter Data", { ns: "report" });
    }
};

const DataUploadPage = () => {
    const { t } = useTranslation(["translation", "report"]);
    const [parsedCsvRows, setParsedCsvRows] = useState<ParsedCsvRow[]>([]);
    const [assessmentDataType, setAssessmentDataType] =
        useState<null | AssessmentDataType>(null);
    const [uploadedFile, setUploadedFile] = useState<File>();
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [hasUnauthorizedServerError, setHasUnauthorizedServerError] =
        useState<boolean>(false);

    const [handleAssessmentUpload] = useSubmitAssessments();

    const removeUpload = (event: Event) => {
        event.preventDefault();
        setParsedCsvRows([]);
        setSubmitted(false);
    };

    const handleUpload = (parsedCsvRows: Array<ParsedCsvRow>) => {
        setParsedCsvRows(parsedCsvRows);
    };

    const handleFile = (file: any) => {
        setUploadedFile(file);
    };

    const baseColumns: GridColDef[] = useMemo(
        () => [
            {
                field: "AssetID",
                headerName: t("dataUploadPage.assetId", "Asset ID"),
                width: 200,
                cellClassName: (params: GridCellParams) =>
                    clsx({
                        error: params.row.errors.assetID,
                    }),
            },
            {
                field: "StartDate",
                headerName: t("dataUploadPage.startDate", "Start date"),
                width: 150,
                cellClassName: (params: GridCellParams) =>
                    clsx({
                        error: params.row.errors.start,
                    }),
            },
            {
                field: "EndDate",
                headerName: t("dataUploadPage.endDate", "End date"),
                width: 150,
                cellClassName: (params: GridCellParams) =>
                    clsx({
                        error: params.row.errors.end,
                    }),
            },
            {
                field: "ConsumptionType",
                headerName: t(
                    "dataUploadPage.consumptionType",
                    "Consumption type"
                ),
                width: 200,
                cellClassName: (params: GridCellParams) =>
                    clsx({
                        error: params.row.errors.consumptionType,
                    }),
            },
            {
                field: "Consumption",
                headerName: t("dataUploadPage.consumption", "Consumption"),
                width: 150,
                cellClassName: (params: GridCellParams) =>
                    clsx({
                        error: params.row.errors.consumption,
                    }),
            },
            {
                field: "Unit",
                headerName: t("dataUploadPage.unit", "Unit"),
                width: 150,
                cellClassName: (params: GridCellParams) =>
                    clsx({
                        error: params.row.errors.unit,
                    }),
            },
        ],
        [t]
    );

    const columns: GridColDef[] = useMemo(() => {
        if (assessmentDataType === AssessmentDataType.ACTUAL) {
            return baseColumns.concat([
                {
                    field: "MeterID",
                    headerName: t("dataUploadPage.meterId", "Meter ID"),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.data.meterId,
                        }),
                },
            ]);
        } else if (assessmentDataType === AssessmentDataType.ESTIMATE) {
            return baseColumns.concat([
                {
                    field: "Area",
                    headerName: t("dataUploadPage.area", "Area"),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.area,
                        }),
                },
                {
                    field: "Address",
                    headerName: t("dataUploadPage.address", "Address"),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.address,
                        }),
                },
                {
                    field: "Zip",
                    headerName: t("dataUploadPage.zipCode", "Zip"),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.address,
                        }),
                },
                {
                    field: "City",
                    headerName: t("dataUploadPage.city", "City"),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.address,
                        }),
                },
                {
                    field: "BuildingType",
                    headerName: t(
                        "dataUploadPage.buildingType",
                        "Building Type"
                    ),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.buildingType,
                        }),
                },
                {
                    field: "YearOfConstruction",
                    headerName: t(
                        "dataUploadPage.yearOfConstruction",
                        "Year of Construction"
                    ),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.yearOfConstruction,
                        }),
                },
                {
                    field: "BuildingOwnership",
                    headerName: t(
                        "dataUploadPage.buildingOwnership",
                        "% of Building Ownership"
                    ),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.buildingOwnership,
                        }),
                },
                {
                    field: "EPCIdentifier",
                    headerName: t(
                        "dataUploadPage.epcIdentifier",
                        "EPC Identifier"
                    ),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.data.identifier,
                        }),
                },
                {
                    field: "EPCClassification",
                    headerName: t(
                        "dataUploadPage.epcClassification",
                        "EPC Classification"
                    ),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.data.classification,
                        }),
                },
                {
                    field: "ConsumptionSource",
                    headerName: t(
                        "dataUploadPage.consumptionSource",
                        "Consumption Source"
                    ),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.data.source,
                        }),
                },
                {
                    field: "BuildingNumbers",
                    headerName: t(
                        "dataUploadPage.buildingNumbers",
                        "Building Numbers"
                    ),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.data.buildingNumbers,
                        }),
                },
                {
                    field: "NationalIdentifiers",
                    headerName: t(
                        "dataUploadPage.nationalIdentifier",
                        "National identifiers"
                    ),
                    width: 150,
                    cellClassName: (params: GridCellParams) =>
                        clsx({
                            error: params.row.errors.data.nationalIdentifier,
                        }),
                },
            ]);
        }
        return baseColumns;
    }, [baseColumns, t, assessmentDataType]);

    const { data } = useQuery<GetLocationsQuery>(GET_LOCATIONS_QUERY);
    const { data: emissionTypesData } =
        useQuery<GetEmissionsQuery>(GET_EMISSIONS_QUERY);

    const [locations, emissionTypes] = useMemo(() => {
        if (!data || !emissionTypesData) return [null, null];

        return [
            data.me?.organization?.locations?.edges.map((e) => e.node),
            emissionTypesData.emissions.edges.map((edge) => edge.node),
        ];
    }, [data, emissionTypesData]);

    const parsedResults = useMemo(() => {
        if (
            !locations ||
            !emissionTypes ||
            !parsedCsvRows ||
            !assessmentDataType
        )
            return;

        return parseResults(
            locations,
            emissionTypes,
            parsedCsvRows,
            assessmentDataType
        );
    }, [locations, emissionTypes, parsedCsvRows, assessmentDataType]);

    const handleSubmit = useCallback(() => {
        setLoading(true);

        if (!parsedResults || !uploadedFile || !assessmentDataType) {
            return;
        }

        handleAssessmentUpload(
            { file: uploadedFile },
            { assessmentType: assessmentDataType },
            { assessments: parsedResults.assessments },
            { locationMetadata: parsedResults.locationMetaData }
        )
            .then((result) => {
                console.log(result);
                setSubmitted(true);
                setLoading(false);
                setParsedCsvRows([]);
            })
            .catch((error) => {
                console.error(error);
                setHasUnauthorizedServerError(
                    error?.message.includes(
                        "Request failed with status code 401"
                    )
                );
            });
    }, [
        handleAssessmentUpload,
        parsedResults,
        uploadedFile,
        assessmentDataType,
    ]);

    const dataTypeOptions = useMemo(
        () =>
            availableAssessmentTypes.map((type) => ({
                value: type,
                title: translateAssessmentDataTypes(t, type),
            })),
        [t]
    );

    const hasErrors = useMemo(
        () =>
            parsedResults &&
            parsedResults.rows.filter((row) => row.hasErrors).length > 0,
        [parsedResults]
    );
    const rows: GridRowModel[] = parsedResults?.rows || [];

    if (!assessmentDataType) {
        return (
            <Container>
                <GoToSecretPageButton />
                <HeaderBox>
                    <Typography variant="h2">
                        {t(
                            "dataUploadPage.chooseAssessmentType",
                            "Choose assessment type"
                        )}
                    </Typography>
                </HeaderBox>
                {availableAssessmentTypes.map((type) => (
                    <Button
                        variant="contained"
                        color="primary"
                        key={type}
                        onClick={() => setAssessmentDataType(type)}
                    >
                        {translateAssessmentDataTypes(t, type)}
                    </Button>
                ))}
            </Container>
        );
    }

    return (
        <Container>
            <GoToSecretPageButton />
            <HeaderBox>
                <Typography variant="h2">
                    {t("dataUploadPage.title", "Upload data")}
                </Typography>
                <TextField
                    variant="outlined"
                    size="small"
                    style={{ width: 180 }}
                    value={assessmentDataType}
                    onChange={(e) =>
                        setAssessmentDataType(
                            e.target.value as AssessmentDataType
                        )
                    }
                    select
                >
                    {dataTypeOptions.map(({ title, value }) => (
                        <MenuItem key={value} value={value}>
                            {title}
                        </MenuItem>
                    ))}
                </TextField>
            </HeaderBox>
            <UploadContainer>
                <PapaParse
                    removeUpload={removeUpload}
                    handleUpload={handleUpload}
                    handleFile={handleFile}
                />
            </UploadContainer>

            {hasErrors && (
                <Alert variant="filled" severity="error">
                    {t(
                        "dataUploadPage.errorMessage",
                        "There is an error in one or more rows. Please fix the errors and upload the file again ☺️"
                    )}
                </Alert>
            )}
            {submitted && !parsedCsvRows.length && (
                <SuccessBox>
                    <Alert>
                        {t(
                            "dataUploadPage.successMessage",
                            "You successfully upload the data!"
                        )}
                    </Alert>
                </SuccessBox>
            )}
            {parsedCsvRows.length > 0 && (
                <>
                    <GridTableContainer>
                        <Table
                            rows={rows}
                            columns={columns}
                            sortable={true}
                            slotProps={{
                                pagination: {
                                    ActionsComponent: TablePagination,
                                },
                            }}
                        />
                    </GridTableContainer>
                    <FormControls>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={hasErrors || hasUnauthorizedServerError}
                        >
                            {t("dataUploadPage.submit", "Submit data")}
                            {loading && (
                                <CircularProgress
                                    size="small"
                                    color="secondary"
                                />
                            )}
                        </Button>
                    </FormControls>
                </>
            )}
            {hasUnauthorizedServerError && (
                <Alert variant="filled" severity="error">
                    {t(
                        "dataUploadPage.unauthorizedError",
                        'The server returned an "unauthorized" error. Please make sure that the source file has public access and upload the file again ☺️'
                    )}
                </Alert>
            )}
        </Container>
    );
};

export default DataUploadPage;
