import _ from "lodash";

import {
    AssessmentDataType,
    EmissionDetails,
    TargetEmissionDetails,
} from "graphql-types/graphql";

import { ForecastLocation, AssessmentsSummaryUnion } from "../types";

const hasAssessmentDataType = (
    type: AssessmentDataType,
    e: EmissionDetails | TargetEmissionDetails
) => {
    switch (type) {
        case AssessmentDataType.ESTIMATE:
            return e.epc.ownedEmission !== null;
        case AssessmentDataType.ACTUAL:
            return e.meter.ownedEmission !== null;
        default:
            return e.bestEffort.ownedEmission !== null;
    }
};

export const getLocationEmissionsOnType = (
    locations: ForecastLocation[],
    type: AssessmentDataType
) => {
    return _.chain(locations)
        .map((l) => {
            const emission = _.chain(l.assessmentSummaries)
                .filter((a) =>
                    hasAssessmentDataType(type, a.emissionDataGrams.emission)
                )
                .value();

            const projectedEmission: AssessmentsSummaryUnion[] = _.chain(
                l.projectedAssessmentSummaries
            )
                .filter((a) =>
                    hasAssessmentDataType(type, a.emissionDataGrams.emission)
                )
                .value();

            const targetEmissions = _.chain(l.targetEmissions)
                .filter((a) => hasAssessmentDataType(type, a.emissionGrams))
                .value();

            return {
                ...l,
                allEmissions: _.concat(
                    emission,
                    projectedEmission,
                    targetEmissions
                ),
            };
        })
        .compact()
        .value();
};
