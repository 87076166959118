import { TFunction } from "i18next";
import _ from "lodash";
import { DateTime, Interval } from "luxon";

import { UnitMass } from "components/AssessmentStats/types";
import { DataQualityEnum } from "components/DataQuality/DataQuality.helpers";
import { getFormattedConvertedGramsToMassUnit } from "components/PortfolioDashboard/Charts/charts.helper";
import { AssetCoverageAssessmentFragment } from "graphql-types/graphql";
import { formatNumberLocale, getUnitByKey } from "utils/report.helpers";

import { DataCoverageAssessments } from "./AssetBestEffortTimeline";
import { aggregateSumBy } from "../AssetOverview/AssetOverview.utils";

export const getDataQualityByPriority = (
    priority: number | null | undefined,
    maxHighPriority: number,
    maxMediumPriority: number
) => {
    if (!priority) {
        return DataQualityEnum.LOW;
    }

    if (priority <= maxHighPriority) {
        return DataQualityEnum.HIGH;
    }

    if (priority <= maxMediumPriority) {
        return DataQualityEnum.MEDIUM;
    }

    return DataQualityEnum.LOW;
};
export const getBestEffortAssessmentsSources = (
    assessments: AssetCoverageAssessmentFragment[],
    maxHighPriority: number,
    maxMediumPriority: number
): Record<number, DataCoverageAssessments> =>
    _.chain(assessments)
        .groupBy((a) => DateTime.fromISO(a.from).month)
        .map((assessments, month) => ({
            [month]: _.chain(assessments)
                .sortBy((a) => a.bestEffortPriority)
                .map((a) => ({
                    source: a.source,
                    dataQuality: getDataQualityByPriority(
                        a.bestEffortPriority,
                        maxHighPriority,
                        maxMediumPriority
                    ),
                    sources: _.uniqBy(
                        assessments
                            .sort(
                                (a, b) =>
                                    (a.bestEffortPriority ?? 0) -
                                    (b.bestEffortPriority ?? 0)
                            )
                            .flatMap((a) => ({
                                source: a.source,
                                quality: getDataQualityByPriority(
                                    a.bestEffortPriority,
                                    maxHighPriority,
                                    maxMediumPriority
                                ),
                            })),
                        (a) => a.source
                    ),
                }))
                .first()
                .value(),
        }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
        .value();

export const getMonthlyAssessmentCoverageData = (
    assessments: AssetCoverageAssessmentFragment[],
    t: TFunction
) =>
    _.chain(assessments)
        .groupBy((a) => DateTime.fromISO(a.from).month)
        .map((assessments, month) => {
            const dateRanges = Interval.merge(
                assessments.map((a) =>
                    Interval.fromDateTimes(
                        DateTime.fromISO(a.from),
                        DateTime.fromISO(a.to).minus({ days: 1 })
                    )
                )
            )
                .filter((i) => i.isValid)
                .map((i) => i.toFormat("dd/MM/yyyy"));

            const ownedEmission = getFormattedConvertedGramsToMassUnit(
                aggregateSumBy(
                    assessments,
                    (item) => item.emissionDataGrams?.ownedEmission
                ) || 0,
                UnitMass.KILOGRAM,
                t,
                false
            );
            const assetConsumption = formatNumberLocale(
                aggregateSumBy(
                    assessments,
                    (item) => item.consumptionData?.ownedConsumption
                ) || 0,
                t
            );

            const assessment = _.first(assessments);
            const formattedConsumptionUnit = getUnitByKey(
                assessment?.unit ?? undefined,
                t
            );

            return {
                [month]: {
                    source: assessment?.source,
                    emissions: ownedEmission,
                    consumptions: `${assetConsumption} ${formattedConsumptionUnit}`,
                    dateRanges,
                },
            };
        })
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
        .value();

export const isNextMonthInAssessments = (
    month: number,
    assessments: Record<number, AssetCoverageAssessmentFragment>
) => Boolean(assessments[month + 1]);

export const isSourceInParentMonth = (
    month: number,
    assessments: Record<number, AssetCoverageAssessmentFragment>,
    parentAssessments: Record<number, AssetCoverageAssessmentFragment>
) =>
    Boolean(
        parentAssessments[month] &&
            assessments[month] &&
            parentAssessments[month].source === assessments[month].source
    );
