import { gql } from "@apollo/client";

export const changeLogQueryName = "getChangeLogs";

const CHANGE_LOG_FRAGMENT = gql`
    fragment ChangeLogFragment on ChangeLog {
        id
        eventCreatedAt
        eventPayload {
            user {
                name
                email
            }
            changes {
                field
                oldValue
                newValue
            }
        }
        updatedBy {
            name
        }
    }
`;

const CHANGE_LOG_LABELS = gql`
    fragment ChangeLogLabels on ChangeLog {
        recordLabels {
            label
            value
        }
    }
`;

export const CHANGE_LOGS_QUERY = (hideLabels: boolean) => {
    const skip = `@skip(if:${hideLabels})`;

    return gql`
    query GetChangeLogs($recordId: String, $eventType: ChangeLogEventType!) {
        getChangeLogs(recordId: $recordId, eventType: $eventType) {
            ...ChangeLogFragment
            ...ChangeLogLabels ${skip}
        }
    }

    ${CHANGE_LOG_FRAGMENT}
    ${CHANGE_LOG_LABELS}
`;
};
