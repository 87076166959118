import { Box, useTheme } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "recharts";

import {
    AboveBenchmarkArrow,
    ArrowBox,
    AssetText,
    AssetViewBox,
    AverageTotalBox,
    BelowBenchmarkArrow,
    DataAmount,
    DataAmountBox,
    DataBox,
    GreyHeader,
    GreyHeaderBox,
    HoverTitleBox,
} from "./tooltip.styles";
import {
    BENCHMARK_LABEL,
    formatSummedEmission,
    setStroke,
} from "../helpers/chartHelpers";

export type Tooltip = {
    title: string;
    label: string;
    year: number;
    total: number;
    assetCount?: number;
    percentage: number;
};

function BenchmarkPerformanceTooltip(props: TooltipProps<number, string>) {
    const tooltip = _.first(props.payload)?.payload as Tooltip;
    const { t } = useTranslation();
    const theme = useTheme();

    if (!tooltip) {
        return <></>;
    }

    const currentYear = new Date().getFullYear();
    const isCurrentYear = currentYear === tooltip.year;

    const benchmarkPerformanceTooltipLabel = (label: string) => {
        const formattedPerformance = setStroke(tooltip.percentage);

        switch (true) {
            case label === BENCHMARK_LABEL:
                return (
                    <HoverTitleBox
                        style={{ color: theme.palette.primary.main }}
                    >
                        {t(
                            "portfolioDashboard.hoverBox.benchmark",
                            "Benchmark"
                        )}{" "}
                        {isCurrentYear &&
                            t("portfolioDashboard.yearSelect.ytd", " YTD")}
                    </HoverTitleBox>
                );
            case parseInt(label, 10) > 100:
                return (
                    <HoverTitleBox>
                        <AboveBenchmarkArrow />
                        <Box style={{ color: theme.palette.error.main }}>
                            {formattedPerformance + "%"}
                        </Box>
                    </HoverTitleBox>
                );
            default:
                return (
                    <HoverTitleBox>
                        <BelowBenchmarkArrow />
                        <Box style={{ color: theme.palette.success.main }}>
                            {formattedPerformance + "%"}
                        </Box>
                    </HoverTitleBox>
                );
        }
    };

    return (
        <AverageTotalBox>
            <Box>
                <ArrowBox>
                    {benchmarkPerformanceTooltipLabel(tooltip.label)}
                </ArrowBox>
            </Box>
            <DataBox>
                <GreyHeaderBox>
                    <GreyHeader>{tooltip.title}</GreyHeader>
                </GreyHeaderBox>

                <DataAmountBox>
                    <DataAmount>
                        {formatSummedEmission(tooltip.total, t)}
                    </DataAmount>
                </DataAmountBox>
            </DataBox>
            {tooltip.assetCount && (
                <>
                    <DataBox>
                        <GreyHeaderBox>
                            <GreyHeader>
                                {t("common.portfolio", "Portfolio")}
                            </GreyHeader>
                        </GreyHeaderBox>
                        <DataAmountBox style={{ marginBottom: "20px" }}>
                            <DataAmount>
                                {t(
                                    "portfolioDashboard.chartHelpers.keyWithCount",
                                    "{{count}} Assets",
                                    {
                                        count: tooltip.assetCount,
                                    }
                                )}
                            </DataAmount>
                        </DataAmountBox>
                    </DataBox>
                    <AssetViewBox>
                        <AssetText>
                            {t(
                                "portfolioDashboard.hoverBox.clickAssets",
                                "Click the bar to view assets"
                            )}
                        </AssetText>
                    </AssetViewBox>
                </>
            )}
        </AverageTotalBox>
    );
}

export default BenchmarkPerformanceTooltip;
