import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { createYearRange } from "utils/date.utils";
import { useReportingYearContext } from "utils/userContext";

import { useSelectedYearContext } from "./YearContext";
import YearSelector from "./YearSelector";

function OrganizationYearSelector() {
    const { activeYear, setActiveYear } = useSelectedYearContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const reportingYear = useReportingYearContext();
    const availableYears = useMemo(
        () => createYearRange(reportingYear),
        [reportingYear]
    );

    useEffect(() => {
        if (searchParams.get("year") === null) {
            setSearchParams((prev) => {
                prev.set("year", activeYear.toString());
                return prev;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <YearSelector
            availableYears={availableYears}
            selectedYear={activeYear}
            onYearChange={setActiveYear}
        />
    );
}

export default OrganizationYearSelector;
