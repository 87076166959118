import { useMutation, useQuery } from "@apollo/client";
import { Container } from "@mui/material";
import _, { cloneDeep, flatten, set } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading/Loading";
import {
    AddLocationIntegrationMutation,
    AddOrganizationIntegrationMutation,
    CustomerLocationFragment,
    DeleteLocationIntegrationMutation,
    GetDexmaMeterLocationsQuery,
    GetEntolabsMeterLocationsQuery,
    GetHoforMeterLocationsQuery,
    GetOisMeterLocationsQuery,
    GetTechemMeterLocationsQuery,
    GetVitaniMeterLocationsQuery,
    IntegrationType,
    LocationIntegrationInput,
    SupportedIntegrationForOnboarding,
} from "graphql-types/graphql";

import GenericModalStep from "./GenericModalStep";
import IntegrationCreationResult from "./IntegrationCreationResult";
import {
    LocationIntegrationsSelection,
    LocationMapping,
    SelectionsState,
} from "./sharedModalTypes";
import {
    getMutationPayloadForIntegration,
    reformatOISLocationIntegrationsData,
} from "./utils/integrationUtils";
import {
    getDisplayValueForLocation,
    getInitialMeterLocationDropdownValues,
    getMappedMeterLocationRows,
    getMeterLocationDropdownValue,
    getMeterLocationForSelectedValue,
    TableComponent,
} from "./utils/tableUtils";
import {
    ADD_LOCATION_INTEGRATION_MUTATION,
    DELETE_LOCATION_INTEGRATION,
} from "../graphql/locationIntegrationOnboardingMutation";
import { ADD_ORGANIZATION_INTEGRATION_MUTATION } from "../graphql/organizationIntegrationOnboardingMutation";
import {
    MeterLocationsRequest,
    MeterLocation,
    DropDownItem,
    OisMeterLocation,
} from "../types";
import {
    integrationTypeToGqlRequestMap,
    UNSUPPORTED_INTEGRATION_TYPE,
} from "../utils";

type Props = {
    integrationType: SupportedIntegrationForOnboarding;
};

const DEFAULT_STEP_STATE: SelectionsState = {
    locationIntegrationSelections: [],
    selectedMeterLocationDropdownValues: {},
};

const getLocationIntegrationsMutationPayload = (
    selectedMappings: LocationMapping[],
    integrationType: SupportedIntegrationForOnboarding
) => {
    const mapping =
        integrationType === SupportedIntegrationForOnboarding.OIS
            ? _.uniqBy(selectedMappings, (s) => s.originId)
            : selectedMappings;

    return mapping.map((locationMapping) =>
        getMutationPayloadForIntegration(integrationType, locationMapping)
    );
};

const parseMeterLocationsPayload = (
    data: MeterLocationsRequest,
    integration: SupportedIntegrationForOnboarding
) => {
    switch (integration) {
        case SupportedIntegrationForOnboarding.DEXMA:
            return [
                ...(data as GetDexmaMeterLocationsQuery)
                    .locationsForDexmaIntegration.meterLocations,
            ];
        case SupportedIntegrationForOnboarding.TECHEM:
            return [
                ...(data as GetTechemMeterLocationsQuery)
                    .locationsForTechemIntegration.meterLocations,
            ];
        case SupportedIntegrationForOnboarding.VITANI:
            return [
                ...(data as GetVitaniMeterLocationsQuery)
                    .locationsForVitaniIntegration.meterLocations,
            ];
        case SupportedIntegrationForOnboarding.HOFOR:
            return [
                ...(data as GetHoforMeterLocationsQuery)
                    .locationsForHoforIntegration.meterLocations,
            ];
        case SupportedIntegrationForOnboarding.OIS: {
            const oisData = data as GetOisMeterLocationsQuery;
            const locations = oisData.me.organization?.getLocations ?? [];

            return [...locations];
        }
        case SupportedIntegrationForOnboarding.ENTOLABS:
            return [
                ...(data as GetEntolabsMeterLocationsQuery)
                    .locationsForEntolabsIntegration.meterLocations,
            ];
        default:
            throw new Error(UNSUPPORTED_INTEGRATION_TYPE);
    }
};

const parseInitialLocationIntegrationRequest = (
    data: MeterLocationsRequest,
    integration: SupportedIntegrationForOnboarding
) => {
    switch (integration) {
        case SupportedIntegrationForOnboarding.DEXMA:
            return (
                data as GetDexmaMeterLocationsQuery
            ).locationsForDexmaIntegration.initialLocationIntegrationsState?.map(
                (a) => {
                    return {
                        meterLocations: a.meterLocations,
                        customerLocation: a.customerLocation,
                        rowIndex: a.rowIndex,
                    };
                }
            );

        case SupportedIntegrationForOnboarding.TECHEM:
            return (
                data as GetTechemMeterLocationsQuery
            ).locationsForTechemIntegration.initialLocationIntegrationsState?.map(
                (a) => {
                    return {
                        meterLocations: a.meterLocations,
                        customerLocation: a.customerLocation,
                        rowIndex: a.rowIndex,
                    };
                }
            );

        case SupportedIntegrationForOnboarding.VITANI:
            return (
                data as GetVitaniMeterLocationsQuery
            ).locationsForVitaniIntegration.initialLocationIntegrationsState?.map(
                (a) => {
                    return {
                        meterLocations: a.meterLocations,
                        customerLocation: a.customerLocation,
                        rowIndex: a.rowIndex,
                    };
                }
            );
        case SupportedIntegrationForOnboarding.HOFOR:
            return (
                data as GetHoforMeterLocationsQuery
            ).locationsForHoforIntegration.initialLocationIntegrationsState?.map(
                (a) => {
                    return {
                        meterLocations: a.meterLocations,
                        customerLocation: a.customerLocation,
                        rowIndex: a.rowIndex,
                    };
                }
            );

        case SupportedIntegrationForOnboarding.OIS:
            return (
                data as GetOisMeterLocationsQuery
            ).me.organization?.getLocations?.map((a, i) => {
                return {
                    meterLocations: [a],
                    customerLocation: a,
                    rowIndex: i,
                };
            });

        case SupportedIntegrationForOnboarding.ENTOLABS:
            return (
                data as GetEntolabsMeterLocationsQuery
            ).locationsForEntolabsIntegration.initialLocationIntegrationsState?.map(
                (a) => {
                    return {
                        meterLocations: a.meterLocations,
                        customerLocation: a.customerLocation,
                        rowIndex: a.rowIndex,
                    };
                }
            );

        default:
            return [];
    }
};

const LocationIntegrationStep = (props: Props) => {
    const { integrationType } = props;
    const { t } = useTranslation();

    const [selectionsStateBag, setSelectionsStateBag] =
        useState<SelectionsState>(cloneDeep(DEFAULT_STEP_STATE));

    const [modalSubmitted, setModalSubmitted] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [mutationLoading, setMutationLoading] = useState(false);

    const [selectedMappings, setSelectedMappings] = useState<LocationMapping[]>(
        []
    );
    const [deselectedMappings, setDeselectedMappings] = useState<
        LocationMapping[]
    >([]);

    const { meterLocationsQuery: query } =
        integrationTypeToGqlRequestMap[integrationType];

    const { data: rawMeterLocationsData, loading: meterLocationsQueryLoading } =
        useQuery<MeterLocationsRequest>(query, {
            variables: {
                types: [
                    integrationType,
                    integrationType === SupportedIntegrationForOnboarding.OIS &&
                        IntegrationType.EPC,
                ],
            },
        });

    const isOis = integrationType === SupportedIntegrationForOnboarding.OIS;

    const meterLocationsData = useMemo(() => {
        const isRawMeterLocationsUndefinedOnLoad = !rawMeterLocationsData;
        if (isRawMeterLocationsUndefinedOnLoad || !isOis) {
            return rawMeterLocationsData;
        }

        return reformatOISLocationIntegrationsData(
            rawMeterLocationsData as GetOisMeterLocationsQuery
        );
    }, [rawMeterLocationsData, isOis]);

    const hasOisOrganizationIntegration = useMemo(() => {
        if (isOis) {
            return (
                meterLocationsData as GetOisMeterLocationsQuery
            )?.me.organization?.integrations.some(
                (i) => i.type === IntegrationType.OIS
            );
        }
    }, [meterLocationsData, isOis]);

    const [addLocationIntegration] =
        useMutation<AddLocationIntegrationMutation>(
            ADD_LOCATION_INTEGRATION_MUTATION,
            {
                refetchQueries: [query],
            }
        );
    const [deleteLocationIntegration] =
        useMutation<DeleteLocationIntegrationMutation>(
            DELETE_LOCATION_INTEGRATION,
            {
                refetchQueries: [query],
            }
        );
    const [addOrganizationIntegration] =
        useMutation<AddOrganizationIntegrationMutation>(
            ADD_ORGANIZATION_INTEGRATION_MUTATION,
            { refetchQueries: [query] }
        );

    useMemo(() => {
        if (!meterLocationsData) {
            return [];
        }

        const initialLocationIntegration =
            parseInitialLocationIntegrationRequest(
                meterLocationsData,
                integrationType
            ) as LocationIntegrationsSelection[];

        if (selectionsStateBag.locationIntegrationSelections.length === 0) {
            setSelectionsStateBag((prev) => {
                return {
                    ...prev,
                    locationIntegrationSelections: _.sortBy(
                        initialLocationIntegration,
                        (a) => getDisplayValueForLocation(a.customerLocation)
                    ),
                };
            });

            const meterLocationSeparation = _.sortBy(
                initialLocationIntegration,
                (a) => getDisplayValueForLocation(a.customerLocation)
            )
                .filter((a) => a.meterLocations.length > 0)
                .map(({ meterLocations, rowIndex }) => ({
                    meterLocations,
                    rowIndex,
                }))
                .flat();

            const alreadySelectedIntegration =
                getInitialMeterLocationDropdownValues(
                    meterLocationSeparation,
                    integrationType
                );

            setSelectionsStateBag((prev) => {
                return {
                    ...prev,
                    selectedMeterLocationDropdownValues:
                        alreadySelectedIntegration,
                };
            });
        }

        return initialLocationIntegration;
    }, [
        integrationType,
        meterLocationsData,
        selectionsStateBag.locationIntegrationSelections.length,
    ]);

    const customerLocations = useMemo(() => {
        if (!meterLocationsData) {
            return [];
        }

        const initialLocationIntegration =
            parseInitialLocationIntegrationRequest(
                meterLocationsData,
                integrationType
            ) as LocationIntegrationsSelection[];

        return (
            _.sortBy(initialLocationIntegration, (a) =>
                getDisplayValueForLocation(a.customerLocation)
            ).map((a) => {
                return {
                    ...a.customerLocation,
                    rowIndex: a.rowIndex,
                };
            }) || []
        );
    }, [meterLocationsData, integrationType]);

    const meterLocations: MeterLocation[] = useMemo(() => {
        if (!meterLocationsData) {
            return [];
        }
        return parseMeterLocationsPayload(meterLocationsData, integrationType);
    }, [meterLocationsData, integrationType]);

    const meterLocationsCustomerLocationsRowMappings = useMemo(() => {
        const meterLocationDropdownValues = meterLocations.flatMap((l) =>
            getMeterLocationDropdownValue(l, integrationType)
        );

        const handleStateUpdate = (
            meterLocations: MeterLocation[],
            customerLocation: CustomerLocationFragment,
            rowIndex: number,
            newSelectedMeterLocationDropdownItems: DropDownItem[],
            selectedDropdownValue: LocationMapping | null,
            deselectedDropdownValue: LocationMapping | null,
            deselectedRowDropdownTitle?: string
        ) => {
            const state = { ...selectionsStateBag };

            set(state.locationIntegrationSelections, rowIndex, {
                meterLocations,
                customerLocation,
                rowIndex,
            });

            if (deselectedDropdownValue) {
                setDeselectedMappings((prev) => [
                    ...prev,
                    deselectedDropdownValue,
                ]);

                const filteredSelectedMappings = selectedMappings.filter(
                    (selected) =>
                        !(
                            selected.originId ===
                                deselectedDropdownValue.originId &&
                            selected.assetId ===
                                deselectedDropdownValue.assetId
                        )
                );

                setSelectedMappings(() => filteredSelectedMappings);
            }

            if (selectedDropdownValue) {
                setSelectedMappings((prev) => [...prev, selectedDropdownValue]);

                const filteredDeselectedMappings = deselectedMappings.filter(
                    (deselected) => {
                        return !(
                            deselected.originId ===
                                selectedDropdownValue.originId &&
                            deselected.assetId ===
                                selectedDropdownValue.assetId
                        );
                    }
                );

                setDeselectedMappings(filteredDeselectedMappings);
            }

            newSelectedMeterLocationDropdownItems.map(
                (dropdownItem) =>
                    (state.selectedMeterLocationDropdownValues[
                        dropdownItem.value
                    ] = rowIndex)
            );

            if (deselectedRowDropdownTitle) {
                if (integrationType === SupportedIntegrationForOnboarding.OIS) {
                    const keys = Object.keys(
                        state.selectedMeterLocationDropdownValues
                    );

                    keys.forEach((key) => {
                        const k = key.split(",");

                        if (k.includes(deselectedRowDropdownTitle)) {
                            const newKeys = k.filter(
                                (a) => a !== deselectedRowDropdownTitle
                            );
                            const row =
                                state.selectedMeterLocationDropdownValues[key];

                            delete state.selectedMeterLocationDropdownValues[
                                key
                            ];
                            state.selectedMeterLocationDropdownValues[
                                newKeys.join(",")
                            ] = row;
                        }
                    });
                } else {
                    delete state.selectedMeterLocationDropdownValues[
                        deselectedRowDropdownTitle
                    ];
                }
            }

            setSelectionsStateBag(state);
        };

        const handleIntegrationDates = (
            from: Date | null,
            to: Date | null,
            values: string[],
            integrationType: SupportedIntegrationForOnboarding
        ) => {
            values.forEach((value) => {
                const isValueAlreadySelected = selectedMappings.some(
                    (selected) => value.includes(selected.originId)
                );

                if (isValueAlreadySelected) {
                    const index = selectedMappings.findIndex((selected) =>
                        value.includes(selected.originId)
                    );

                    setSelectedMappings((prev) => {
                        prev[index].startedAt = from ?? prev[index].startedAt;
                        prev[index].endedAt = to ?? prev[index].endedAt;

                        return [...prev];
                    });
                } else {
                    const integrationToUpdate = _.chain(
                        selectionsStateBag.locationIntegrationSelections
                    )
                        .flatMap(
                            (location) => location.customerLocation.integrations
                        )
                        .find(
                            (integration) =>
                                integration.type ===
                                    IntegrationType[integrationType] &&
                                value.includes(integration.originId)
                        )
                        .value();

                    const data = getMeterLocationForSelectedValue(
                        value,
                        integrationType,
                        meterLocations
                    );

                    const newIntegration = {
                        ...integrationToUpdate,
                        data,
                        startedAt: from ?? integrationToUpdate?.startedAt,
                        endedAt: to ?? integrationToUpdate?.endedAt,
                    };

                    setSelectedMappings((prev) => [...prev, newIntegration]);
                }
            });
        };

        return getMappedMeterLocationRows(
            meterLocations,
            meterLocationDropdownValues,
            customerLocations,
            integrationType,
            handleStateUpdate,
            selectionsStateBag,
            handleIntegrationDates
        );
    }, [
        meterLocations,
        customerLocations,
        integrationType,
        selectionsStateBag,
        selectedMappings,
        deselectedMappings,
    ]);

    const onSubmit = useCallback(async () => {
        const locationIntegrationsPayload: LocationIntegrationInput[] = flatten(
            getLocationIntegrationsMutationPayload(
                selectedMappings,
                integrationType
            )
        );

        setMutationLoading(true);

        await deleteLocationIntegration({
            variables: {
                input: deselectedMappings.map((mapping) => ({
                    assetId: mapping.assetId,
                    originId: mapping.originId,
                    type: integrationType,
                })),
            },
        })
            .then((res) => {
                const result = res.data?.deleteLocationIntegration;

                setIsSuccess(Boolean(result));
            })
            .catch(() => setIsSuccess(false))
            .finally(() => {
                addLocationIntegration({
                    variables: {
                        input: locationIntegrationsPayload,
                    },
                })
                    .then((res) => {
                        const result = res.data?.addLocationIntegration;

                        // An OIS organization integration will be created automatically if none has been activated on the onboarding homepage
                        if (
                            integrationType ===
                                SupportedIntegrationForOnboarding.OIS &&
                            !hasOisOrganizationIntegration
                        ) {
                            addOrganizationIntegration({
                                variables: {
                                    input: {
                                        organizationIntegration: {
                                            type: integrationType,
                                        },
                                    },
                                },
                            });
                        }

                        setIsSuccess(Boolean(result));
                    })
                    .catch(() => setIsSuccess(false))
                    .finally(() => {
                        setMutationLoading(false);
                        setModalSubmitted(true);
                    });
            });
    }, [
        addOrganizationIntegration,
        addLocationIntegration,
        deleteLocationIntegration,
        deselectedMappings,
        integrationType,
        selectedMappings,
        hasOisOrganizationIntegration,
    ]);

    const selectAllHandler = useCallback(
        (isSelectAll: boolean) => {
            if (
                !meterLocationsData ||
                integrationType !== SupportedIntegrationForOnboarding.OIS
            )
                return;

            const locationValues = parseInitialLocationIntegrationRequest(
                meterLocationsData,
                integrationType
            )?.map(({ meterLocations, rowIndex }) => ({
                meterLocations,
                rowIndex,
            })) as LocationIntegrationsSelection[];

            const selectionMapping: LocationMapping[] = [];

            const allLocations = locationValues.flatMap((a) =>
                a.meterLocations.map((b) => {
                    const location = b as OisMeterLocation;
                    const keys = location.integrations
                        .filter((i) => i.type === IntegrationType.EPC)
                        .map((a) => a.originId);

                    selectionMapping.push(
                        ...keys.map((key) => ({
                            originId: key,
                            assetId: location.id,
                        }))
                    );

                    return { [`${keys}`]: a.rowIndex };
                })
            );

            const stateObject = Object.assign(
                {},
                ...Object.values(allLocations)
            );

            if (!isSelectAll) {
                setSelectionsStateBag({
                    ...selectionsStateBag,
                    selectedMeterLocationDropdownValues: {},
                });

                setDeselectedMappings(selectionMapping);
                setSelectedMappings([]);
            } else {
                setSelectionsStateBag({
                    ...selectionsStateBag,
                    selectedMeterLocationDropdownValues: stateObject,
                });

                setDeselectedMappings([]);
                setSelectedMappings(selectionMapping);
            }
        },
        [integrationType, meterLocationsData, selectionsStateBag]
    );

    const body = useMemo(() => {
        const onRetrySelection = () => {
            setModalSubmitted(false);
            setIsSuccess(false);
        };

        const integrationCreationResultOverview = modalSubmitted && (
            <IntegrationCreationResult
                onRetry={onRetrySelection}
                isSuccess={isSuccess}
            />
        );

        const integrationCreationMultiSelector = (
            <TableComponent
                integrationType={integrationType}
                rows={meterLocationsCustomerLocationsRowMappings}
            />
        );

        return (
            integrationCreationResultOverview ||
            integrationCreationMultiSelector
        );
    }, [
        modalSubmitted,
        isSuccess,
        integrationType,
        meterLocationsCustomerLocationsRowMappings,
    ]);

    if (meterLocationsQueryLoading || mutationLoading) {
        return (
            <Container>
                <Loading description={t("loading.title", "Loading")} />
            </Container>
        );
    }

    return (
        <GenericModalStep
            header={t(
                "integrationOnboarding.meterLocationListHeader",
                "Assign your assets to {{integrationType}}",
                {
                    integrationType,
                }
            )}
            body={body}
            disableSubmitBtn={Boolean(
                selectionsStateBag.locationIntegrationSelections.length === 0
            )}
            onSubmit={!modalSubmitted ? onSubmit : null}
            onSelectAll={
                integrationType === SupportedIntegrationForOnboarding.OIS
                    ? selectAllHandler
                    : undefined
            }
        />
    );
};

export default LocationIntegrationStep;
