import { Box, Typography, styled, useTheme } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ReferenceLine,
    Bar,
    CartesianGrid,
    Cell,
    ComposedChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/types";

import BenchmarkPerformanceTooltip from "./BenchmarkPerformanceTooltip";
import { getActiveBenchmarkFromPerformanceData } from "./charts.helper";
import { BenchmarkPerformanceChartData } from "./charts.types";
import { formatNumberLocale } from "../../../utils/report.helpers";
import { useSelectedYearContext } from "../../YearSelector/YearContext";
import { BENCHMARK_LABEL } from "../helpers/chartHelpers";
import PortfolioPerformanceSidepanel from "../Sidepanel/PortfolioPerformanceSidepanel";

type SideBarState = {
    locations: any[];
    performance: number;
};

type Props = {
    performanceChartData: BenchmarkPerformanceChartData[];
};

const LabelText = styled(Typography)(() => ({
    fontSize: "10px",
}));

function BenchmarkPerformanceChart(props: Props) {
    const { performanceChartData } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const { activeYear } = useSelectedYearContext();

    const [benchmarkHover, setBenchmarkHover] = useState(false);

    const handleMouseMove = useCallback((data: any) => {
        const { activeLabel } = data;

        setBenchmarkHover(activeLabel === "Benchmark");
    }, []);

    const activeBenchmark = useMemo(
        () =>
            getActiveBenchmarkFromPerformanceData(
                performanceChartData,
                activeYear
            ),
        [performanceChartData, activeYear]
    );

    const [sideBarState, setSideBarState] = useState<SideBarState | null>(null);
    const hideSidepanel = useCallback(
        () => setSideBarState(null),
        [setSideBarState]
    );

    const handleBarClick = useCallback((data: CategoricalChartState) => {
        if (!data || !data.activePayload || data.activePayload.length === 0)
            return;

        const { sidePanelData, percentage } = data.activePayload[0].payload;

        if (!sidePanelData) return;

        setSideBarState({
            locations: sidePanelData,
            performance: percentage,
        });
    }, []);

    const customXAxis = ({ x, y, payload }: any) => {
        let color = theme.palette.text.primary;
        let value = payload.value;

        const isBenchmark = payload.value === BENCHMARK_LABEL;

        switch (true) {
            case isBenchmark:
                value = activeBenchmark
                    ? formatNumberLocale(activeBenchmark, t) + " kg CO2e / m²"
                    : null;
                break;
            case parseInt(value, 10) < 101:
                color = theme.palette.success.main;
                value = value + "%";
                break;
            case parseInt(value, 10) > 100:
                value = value - 100 + "%";
                color = theme.palette.error.main;
                break;
        }

        return (
            <foreignObject x={x - 20} y={y} width={40} height={100}>
                <Box style={{ color: color }}>
                    <LabelText
                        style={{ textAlign: isBenchmark ? "left" : "center" }}
                    >
                        {value}
                    </LabelText>
                </Box>
            </foreignObject>
        );
    };
    return (
        <>
            <ResponsiveContainer width="100%" height={446}>
                <ComposedChart
                    barGap={-30}
                    data={performanceChartData}
                    style={{ cursor: "pointer" }}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    onClick={handleBarClick}
                    onMouseMove={handleMouseMove}
                >
                    <CartesianGrid
                        stroke={theme.palette.grey.A100}
                        strokeDasharray="100% 0.5"
                        vertical={false}
                    />

                    <Tooltip
                        cursor={false}
                        content={BenchmarkPerformanceTooltip}
                    />

                    <ReferenceLine
                        x={"Benchmark"}
                        width={3}
                        stroke={benchmarkHover ? "#1c1c1c" : "#bababa"}
                        strokeWidth={3}
                        ifOverflow="extendDomain"
                        xAxisId={1}
                        isFront
                    />

                    <Bar
                        radius={[5, 5, 0, 0]}
                        barSize={40}
                        dataKey="value"
                        xAxisId={1}
                    >
                        {performanceChartData.map((entry, index) => {
                            return (
                                <Cell
                                    fill={theme.palette.primary.main}
                                    key={index}
                                />
                            );
                        })}
                    </Bar>
                    <XAxis
                        dataKey="label"
                        tickLine={false}
                        stroke={theme.palette.grey[400]}
                        xAxisId={1}
                        tick={customXAxis}
                        height={60}
                    />
                </ComposedChart>
            </ResponsiveContainer>
            <PortfolioPerformanceSidepanel
                overallPercentage={sideBarState ? sideBarState.performance : 0}
                buildingData={sideBarState ? sideBarState.locations : []}
                location={Boolean(sideBarState)}
                onClose={hideSidepanel}
            />
        </>
    );
}

export default BenchmarkPerformanceChart;
