import { gql } from "@apollo/client";

export const FORECAST_DASHBOARD_QUERY = gql`
    query GetForecastAssessments($assetGroupId: ID) {
        me {
            id
            organization {
                id
                baselineYear
                targetSetting {
                    id
                    type
                    targetYear
                    reduction
                    pathwayName
                }
                getLocations(assetGroupId: $assetGroupId) {
                    id
                    name
                    purchaseDate
                    saleDate
                    externalId
                    displayName
                    shortAddress
                    longAddress
                    ownership
                    management
                    legalOwner
                    ownedArea
                    assessmentSummaries {
                        id
                        from
                        to
                        emissionDataGrams {
                            emission {
                                meter {
                                    ownedEmission
                                }
                                epc {
                                    ownedEmission
                                }
                                bestEffort {
                                    ownedEmission
                                }
                            }
                            emissionPerArea {
                                meter {
                                    ownedEmission
                                }
                                epc {
                                    ownedEmission
                                }
                                bestEffort {
                                    ownedEmission
                                }
                            }
                        }
                    }
                    projectedAssessmentSummaries {
                        id
                        from
                        to
                        emissionDataGrams {
                            emission {
                                meter {
                                    ownedEmission
                                }
                                epc {
                                    ownedEmission
                                }
                                bestEffort {
                                    ownedEmission
                                }
                            }
                            emissionPerArea {
                                meter {
                                    ownedEmission
                                }
                                epc {
                                    ownedEmission
                                }
                                bestEffort {
                                    ownedEmission
                                }
                            }
                        }
                    }
                    targetEmissions {
                        from
                        to
                        emissionGrams {
                            meter {
                                isStranded
                                ownedEmission
                            }
                            epc {
                                isStranded
                                ownedEmission
                            }
                            bestEffort {
                                isStranded
                                ownedEmission
                            }
                        }
                    }
                    integrations {
                        id
                        type
                        originId
                        data {
                            ... on IntegrationEpcLocationIntegrationData {
                                municipality
                                property
                                building
                            }
                        }
                    }
                }
            }
        }
        pathways {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;
