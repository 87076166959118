import { Button, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
    useAssetGroups,
    AssetGroupDialog,
    AssetGroupModal,
} from "components/AssetsGroup";
import { AssetGroupOption, SelectorAction } from "components/AssetsGroup/types";
import { useOrganizationContext } from "utils/userContext";

import { S } from "./asset-group-selector.styles";
import { SelectorMenuItem, Selector } from "./components";
import { useActiveAssetGroup } from "../hooks/useActiveAssetGroup";

export function AssetGroupSelector() {
    const { assetGroups, deleteAssetGroup } = useAssetGroups();
    const organization = useOrganizationContext();

    const [activeAssetGroup, setActiveGroupId] = useActiveAssetGroup();
    const { t } = useTranslation();

    const [openModal, setOpenModal] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const organizationOption = useMemo(() => {
        if (!organization) {
            return null;
        }

        return {
            id: organization.id,
            name: organization.name,
            count: organization.allLocationsCount,
        };
    }, [organization]);

    const options = organizationOption
        ? [organizationOption, ...assetGroups]
        : [];

    const handleChangeAssetGroup = (filterId: string) => {
        const selectedGroup = options.find((group) => group.id === filterId);

        if (!selectedGroup) {
            return;
        }

        const groupId =
            selectedGroup.id === organization?.id ? null : selectedGroup.id;

        setActiveGroupId(groupId);
    };

    const [modifyAssetGroup, setModifyAssetGroup] =
        useState<AssetGroupOption | null>(null);

    const onCreateAssetGroup = () => {
        setOpenModal(true);
    };

    const onCloseModal = () => {
        setOpenModal(false);
        setModifyAssetGroup(null);
    };

    const onCloseDialog = (isDeleteAction: boolean) => {
        if (isDeleteAction && modifyAssetGroup) {
            const onSuccess = () => {
                if (modifyAssetGroup.id === activeAssetGroup.id) {
                    setActiveGroupId(null);
                }

                return t(
                    "assetGroup.deleteSuccess",
                    "Asset group deleted successfully"
                );
            };

            toast.promise(deleteAssetGroup(modifyAssetGroup.id), {
                success: onSuccess(),
                error: t(
                    "assetGroup.deleteError",
                    "Failed to delete asset group"
                ),
            });
        }

        setOpenDialog(false);
        setModifyAssetGroup(null);
    };

    const handleModifyAssetGroup = (
        assetGroupId: string,
        action: SelectorAction
    ) => {
        const selectedGroup = options.find(
            (group) => group.id === assetGroupId
        );

        if (!selectedGroup) {
            return;
        }

        setModifyAssetGroup(selectedGroup);

        switch (action) {
            case "edit":
                setOpenModal(true);
                break;
            case "delete":
                setOpenDialog(true);
                break;
        }
    };

    const hasGroups = options.length === 1;
    const assetGroupIds = options.map((group) => group.id);

    return (
        <>
            <Selector
                onChange={handleChangeAssetGroup}
                activeAssetGroup={activeAssetGroup}
                isAssetGroup={activeAssetGroup.id !== organizationOption?.id}
            >
                {options.map((assetGroup, index) => (
                    <SelectorMenuItem
                        key={assetGroup.id}
                        value={assetGroup.id}
                        position={index}
                        option={assetGroup}
                        options={assetGroupIds}
                        isActive={assetGroup.id === activeAssetGroup.id}
                        onMenuAction={handleModifyAssetGroup}
                    />
                ))}

                <S.CTASection>
                    {hasGroups && (
                        <Typography fontSize="small" textAlign="center">
                            {t(
                                "filter.noGroups",
                                "You currently don't have any groups"
                            )}
                        </Typography>
                    )}

                    <Button
                        fullWidth
                        variant="text"
                        onClick={onCreateAssetGroup}
                    >
                        {t("assetGroup.createAssetGroup", "Create Asset Group")}
                    </Button>
                </S.CTASection>
            </Selector>

            <AssetGroupModal
                isOpen={openModal}
                onClose={onCloseModal}
                assetGroupId={
                    openModal && modifyAssetGroup?.id
                        ? modifyAssetGroup.id
                        : null
                }
            />

            <AssetGroupDialog
                isOpen={openDialog}
                groupName={modifyAssetGroup?.name || ""}
                handleClose={onCloseDialog}
            />
        </>
    );
}
