import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

import { S } from "./chartTooltip.styles";

type SectionItem = {
    label: string;
    value: string;
    iconColor?: string;
    textColor?: string;
};

type SubHeader = {
    label: string;
    value: string;
};

export type SectionProps = {
    header: string | ReactNode;
    items: SectionItem[];
    subHeaders?: SubHeader[];
};

const ChartTooltipSection = (props: SectionProps) => {
    const { header, subHeaders, items } = props;
    return (
        <Box px={5}>
            <S.Header>{header}</S.Header>
            {subHeaders?.map(({ label, value }, index) => (
                <Box key={index} gap={1}>
                    <Typography color="text.secondary">{label}:</Typography>
                    <S.Header>{value}</S.Header>
                </Box>
            ))}

            {items.map(({ label, value, iconColor, textColor }) => (
                <Box key={label} display="flex" alignItems="center" gap={1}>
                    {iconColor && <S.Icon htmlColor={iconColor} />}
                    <Typography
                        color={textColor ? textColor : "text.secondary"}
                    >
                        {label}:
                    </Typography>
                    {value}
                </Box>
            ))}
        </Box>
    );
};

export default ChartTooltipSection;
