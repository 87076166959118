import { useQuery } from "@apollo/client";
import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getDataQualityChartData } from "components/DataQuality/DataQuality.helpers";
import DataQualityBarCharts from "components/DataQuality/DataQualityBars/DataQualityBarChart";
import Loading, { LoadingOverlay } from "components/Loading/Loading";
import { getNoDataGraphText } from "components/PortfolioDashboard/helpers/emptyGraphs";
import { DATA_QUALITY_QUERY } from "components/PortfolioDashboard/Queries/dataQualityQuery";
import {
    AssessmentDataType,
    GetDataQualityQuery,
    GetDataQualityQueryVariables,
} from "graphql-types/graphql";
import { getCurrentYear } from "utils/date.utils";
import { useReportingYearContext } from "utils/userContext";

type Props = {
    activeTab: AssessmentDataType;
};

export const DataQualityChart = (props: Props) => {
    const { activeTab } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const reportingYear = useReportingYearContext();

    const variables: GetDataQualityQueryVariables = useMemo(() => {
        const currentYear = getCurrentYear();

        return {
            from: `${reportingYear}-01-01`,
            to: `${currentYear}-12-31`,
            assessmentDataType: activeTab,
        };
    }, [activeTab, reportingYear]);

    const { data, loading, previousData } = useQuery<
        GetDataQualityQuery,
        GetDataQualityQueryVariables
    >(DATA_QUALITY_QUERY, {
        variables,
    });

    const dataQualityForOrganization = useMemo(() => {
        const dataLoader = data ?? previousData;

        return dataLoader?.me.organization?.dataQuality ?? [];
    }, [data, previousData]);

    const dataQualityChartData = useMemo(
        () => getDataQualityChartData({ dataQualityForOrganization, theme }),
        [dataQualityForOrganization, theme]
    );

    return (
        <>
            {loading && (
                <LoadingOverlay>
                    <Loading description="" />
                </LoadingOverlay>
            )}

            {dataQualityChartData.data.length > 0 && (
                <DataQualityBarCharts
                    dataQualityData={dataQualityChartData}
                    containerHeight={446}
                />
            )}

            {!loading &&
                dataQualityChartData.data.length === 0 &&
                getNoDataGraphText(t)}
        </>
    );
};
