import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import React, { createContext, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import {
    Location,
    AssetAlertFragment,
    GetAssetAddressQuery,
    GetAssetAddressQueryVariables,
    CountryCode,
} from "graphql-types/graphql";

import AssessmentDataTypeContextProvider from "./AssessmentDataTypeContext";
import { ASSET_ADDRESS_QUERY } from "./AssetOverview/assetOverviewQuery";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useSelectedYearContext } from "../../components/YearSelector/YearContext";
import { DASH_SEPARATOR } from "../../utils/strings.helpers";

type AssetPageParams = {
    locationId: string;
};

enum AssetPageTabs {
    ASSET_OVERVIEW,
    ASSET_DETAILS,
    ASSET_INVENTORY,
    ASSET_DATA,
}

type AssetContextType = {
    displayName: string;
    ownedArea: Location["ownedArea"];
    countryCode: Location["countryCode"];
};

export const AssetContext = createContext<AssetContextType>({
    displayName: DASH_SEPARATOR,
    ownedArea: null,
    countryCode: CountryCode.DK,
});

function AssetPage() {
    const { t } = useTranslation();

    const { locationId } = useParams<AssetPageParams>();
    const routerLocation = useLocation();

    const { activeYear } = useSelectedYearContext();
    const navigate = useNavigate();

    const headerValue = useMemo(() => {
        const path = routerLocation.pathname.split("/");
        const lastPath = path[path.length - 1];
        switch (lastPath) {
            case "details":
                return AssetPageTabs.ASSET_DETAILS;
            case "inventory":
                return AssetPageTabs.ASSET_INVENTORY;
            case "data-sources":
                return AssetPageTabs.ASSET_DATA;
            default:
                return AssetPageTabs.ASSET_OVERVIEW;
        }
    }, [routerLocation]);

    const { data } = useQuery<
        GetAssetAddressQuery,
        GetAssetAddressQueryVariables
    >(ASSET_ADDRESS_QUERY, {
        variables: { locationId: locationId ?? "" },
        skip: !locationId,
    });

    const [displayName, assetAlerts] = useMemo(() => {
        if (!data) {
            return [DASH_SEPARATOR, []];
        }

        const { displayName, assetAlerts } = data.location;
        return [displayName || DASH_SEPARATOR, assetAlerts ?? []];
    }, [data]);

    const handleChangeHeader = useCallback(
        (_: React.ChangeEvent<object>, newValue: AssetPageTabs) => {
            switch (newValue) {
                case AssetPageTabs.ASSET_OVERVIEW:
                    navigate("");
                    break;
                case AssetPageTabs.ASSET_DETAILS:
                    navigate("details");
                    break;
                case AssetPageTabs.ASSET_INVENTORY:
                    navigate("inventory");
                    break;
                case AssetPageTabs.ASSET_DATA:
                    navigate("data-sources");
                    break;
            }
        },
        [navigate]
    );

    const assetAlertsFromSelectedYear = useMemo(() => {
        const alerts = assetAlerts?.filter(
            (d) =>
                activeYear >= DateTime.fromISO(d.from).year &&
                activeYear <= DateTime.fromISO(d.to).year
        );

        if (!alerts) {
            return [];
        }

        const alertsForSelectedYear = alerts.reduce(
            (accumulator: AssetAlertFragment[], currentItem) => {
                // Check if there's already an item with the same type and null data
                const duplicateItem = accumulator.find(
                    (item) =>
                        item.type === currentItem.type && item.data === null
                );

                // If there's no duplicate with the same typeId and null data, add the current item
                if (!duplicateItem) {
                    accumulator.push(currentItem);
                }

                return accumulator;
            },
            []
        );

        return alertsForSelectedYear;
    }, [assetAlerts, activeYear]);

    return (
        <AssessmentDataTypeContextProvider>
            <AssetContext.Provider
                value={{
                    displayName,
                    ownedArea: data?.location.ownedArea,
                    countryCode: data?.location.countryCode,
                }}
            >
                <PageHeader
                    title={t("pageheader.assetlist", "Asset List")}
                    onChange={handleChangeHeader}
                    description={displayName}
                    onBack={() => navigate("/assetlist")}
                    tabs={{
                        labels: [
                            t("pageheader.emissions", "Emissions"),
                            t("pageheader.assetdetails", "Asset Details"),
                            t("pageheader.inventory", "Inventory"),
                            t("pageheader.data", "Data Sources"),
                        ],
                        value: headerValue,
                    }}
                    assetAlerts={assetAlertsFromSelectedYear}
                />
                <Outlet />
            </AssetContext.Provider>
        </AssessmentDataTypeContextProvider>
    );
}

export default AssetPage;
