import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { Box, Typography, styled } from "@mui/material";

export const AverageTotalBox = styled(Box)(({ theme }) => ({
    width: theme.spacing(55.5),
    height: "fit-content",
    padding: theme.spacing(6.25),
    borderRadius: theme.spacing(0.75),
    boxShadow: "0 2px 5px 0 rgba(0,0,0,0.1)",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    position: "relative",
}));

export const TooltipContainer = styled(Box)(({ theme }) => ({
    width: theme.spacing(55.5),
    height: "fit-content",
    padding: theme.spacing(6.25),
    borderRadius: theme.spacing(0.75),
    boxShadow: "0 2px 5px 0 rgba(0,0,0,0.1)",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    position: "relative",
}));

export const HeaderBox = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 700,
    width: "100%",
    paddingBottom: theme.spacing(6.25),
}));

export const TotalBox = styled(Box)(({ theme }) => ({
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    borderRadius: "2px",
    marginRight: theme.spacing(2),
}));

export const DataBox = styled(Box)(() => ({
    width: "100%",
    height: "fit-content",
}));

export const GreyHeaderBox = styled(Box)(() => ({
    height: "fit-content",
    width: "100%",
    display: "flex",
    alignItems: "center",
}));

export const GreyHeader = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 500,
    lineHeight: 1.33,
    color: theme.palette.grey[500],
}));

export const DataAmountBox = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6.25),
    height: "fit-content",
    width: "100%",
    display: "flex",
    alignItems: "center",
}));

export const DataAmount = styled(Box)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    lineHeight: 1.11,
    color: theme.palette.text.primary,
    display: "flex",

    alignItems: "center",
    verticalAlign: "middle",
}));

export const ArrowBox = styled(Box)(() => ({
    display: "inline-flex",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
}));

export const ProjectedBox = styled(Box)(({ theme }) => ({
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    borderRadius: "2px",
    marginRight: theme.spacing(2),
}));

export const AssetViewBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",

    justifyContent: "center",
    height: theme.spacing(11),
    width: "100%",
    borderTop: "1px solid rgba(189, 196, 198, 0.5)",
    position: "absolute",
    bottom: "0px",
    left: 0,
}));

export const AssetText = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h6.fontSize,
    fontStyle: "italic",
    color: "#8e8e8e",
}));

export const HoverTitleBox = styled(Box)(({ theme }) => ({
    display: "flex",
    marginBottom: theme.spacing(6.25),
}));
export const BelowBenchmarkArrow = styled(() => (
    <ArrowDownward color="success" />
))(({ theme }) => ({
    fontSize: theme.spacing(4),
    verticalAlign: "middle",
    display: "block",
    margin: "auto",
}));
export const AboveBenchmarkArrow = styled(() => <ArrowUpward color="error" />)(
    ({ theme }) => ({
        fontSize: theme.spacing(4),
        verticalAlign: "middle",
        display: "block",
        margin: "auto",
    })
);
