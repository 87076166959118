import { WarningRounded } from "@mui/icons-material";
import { Box, Paper, styled } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssessmentDataType, IntegrationType } from "graphql-types/graphql";
import { convertToRem } from "utils/maths";

import BarChart from "./Charts/BarChart";
import ForecastDashboardHeader from "./ForecastDashboardHeader";
import { getYearlyEmissionSummary } from "./helpers/chartHelpers";
import { getLocationEmissionsOnType } from "./helpers/utilities";
import { ForecastLocation, LocationWithFilteringLabel } from "./types";
import { DASH_SEPARATOR } from "../../utils/strings.helpers";
import DisclaimerPopover from "../DisclaimerPopover/DisclaimerPopover";
import useTargetSettings from "../TargetSettings/useTargetSettings";

type Props = {
    locations: ForecastLocation[];
    baselineYear: number | null;
};

const WarningIcon = styled(WarningRounded)(({ theme }) => ({
    paddingRight: theme.spacing(1),
}));

const ChartSubtitleContainer = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    alignItems: "center",
    display: "flex",
}));

const ChartDisclaimer = () => {
    const { t } = useTranslation();

    const DisclaimerMessage = useMemo(
        () => (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
            >
                <span>{t("forecastDashboard.chartDisclaimer.message1")}</span>
                <span>{t("forecastDashboard.chartDisclaimer.message2")}</span>
                <span>{t("forecastDashboard.chartDisclaimer.message3")}</span>
            </Box>
        ),
        [t]
    );
    return (
        <ChartSubtitleContainer
            color="grey.700"
            sx={{ fontSize: convertToRem(14), display: "flex", gap: "0.25rem" }}
        >
            <WarningIcon color="warning" />{" "}
            <b>
                {t("forecastDashboard.chartDisclaimer.note", "Please Note. ")}
            </b>
            {t(
                "forecastDashboard.chartDisclaimer.disclaimer",
                "Do not use for strategy or certification purposes."
            )}
            <DisclaimerPopover
                element={DisclaimerMessage}
                linkText={t(
                    "forecastDashboard.chartDisclaimer.readmore",
                    "read more"
                )}
            />
        </ChartSubtitleContainer>
    );
};

const ForecastDashboardTabs = (props: Props) => {
    const { locations, baselineYear } = props;
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(AssessmentDataType.COMBINED);
    const { areTargetSettingsShown } = useTargetSettings();

    const [filteringId, setFilteringId] = useState("");

    const activeIntegrationTypes = useMemo(() => [], []);

    const yearlyEmissionSummary = useMemo(
        () => getLocationEmissionsOnType(locations, activeTab),
        [activeTab, locations]
    );

    const filteredYearlyEmissionSummary = useMemo(() => {
        return filteringId
            ? _.filter(yearlyEmissionSummary, (l) => l.id === filteringId)
            : yearlyEmissionSummary;
    }, [yearlyEmissionSummary, filteringId]);

    const handleChange = useCallback(
        (value: AssessmentDataType) => setActiveTab(value),
        [setActiveTab]
    );

    const locationsWithFilteringLabel = useMemo(() => {
        return _.chain(yearlyEmissionSummary)
            .map((l) => {
                const origins = l.integrations
                    .filter((i) => i?.type === IntegrationType.EPC)
                    ?.map((e) => e.originId);

                const totalOrigins = origins.length;
                const totalOriginsLabel =
                    totalOrigins < 2 ? "" : ` [+${totalOrigins - 1}]`;

                return {
                    id: l.id,
                    name: l.displayName || DASH_SEPARATOR,
                    origin: totalOrigins
                        ? `${origins[0]}${totalOriginsLabel}`
                        : "",
                    externalId: l.externalId || "",
                    allEmissions: l.allEmissions,
                };
            })
            .sort((a, b) => {
                let aName = a.name.toUpperCase();
                let bName = b.name.toUpperCase();

                if (aName.startsWith("AA")) aName = aName.replace("AA", "A");
                if (bName.startsWith("AA")) bName = bName.replace("AA", "A");

                return aName.localeCompare(bName, "da-DK");
            })
            .value();
    }, [yearlyEmissionSummary]);

    const yearlyEmissionData = useMemo(
        () =>
            getYearlyEmissionSummary(
                filteredYearlyEmissionSummary,
                t,
                activeTab,
                areTargetSettingsShown
            ),
        [filteredYearlyEmissionSummary, activeTab, areTargetSettingsShown, t]
    );

    const handleFilterSelection = (l: LocationWithFilteringLabel) => {
        setFilteringId(l?.id || "");
    };

    return (
        <Box>
            <ForecastDashboardHeader
                activeTab={activeTab}
                baselineYear={baselineYear}
                handleChange={handleChange}
                activeIntegrationTypes={activeIntegrationTypes}
            />
            <Paper style={{ padding: 32 }}>
                <BarChart
                    chartData={yearlyEmissionData}
                    locationsWithFilteringLabel={locationsWithFilteringLabel}
                    onFilterSelection={handleFilterSelection}
                    hasTargetSettings={areTargetSettingsShown}
                />
            </Paper>

            <ChartDisclaimer />
        </Box>
    );
};

export default ForecastDashboardTabs;
