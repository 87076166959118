import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import MonthChart from "./MonthChart";
import {
    getAssetEmissionSummariesEmissionByMonth,
    getIntensityEmissionByMonth,
    getIntensityEmissionByMonthChartData,
    EmissionSummaryLocation,
} from "./utils/portfolioIntensityByMonthChart.utils";
import { useSelectedYearContext } from "../../YearSelector/YearContext";

type Props = {
    emissionSummariesAssets?: EmissionSummaryLocation[];
};

function PortfolioIntensityByMonthChart(props: Props) {
    const { emissionSummariesAssets = [] } = props;

    const { t } = useTranslation();

    const { activeYear } = useSelectedYearContext();

    const assetEmissionsByMonth = useMemo(() => {
        return _.chain(emissionSummariesAssets)
            .flatMap((asset) => getAssetEmissionSummariesEmissionByMonth(asset))
            .compact()
            .value();
    }, [emissionSummariesAssets]);

    const intensityEmissionsByMonth = useMemo(() => {
        return getIntensityEmissionByMonth(assetEmissionsByMonth);
    }, [assetEmissionsByMonth]);

    const intensityEmissionByMonthChartData = useMemo(() => {
        return getIntensityEmissionByMonthChartData(
            intensityEmissionsByMonth,
            activeYear,
            t
        );
    }, [intensityEmissionsByMonth, activeYear, t]);

    return (
        <MonthChart
            chartData={intensityEmissionByMonthChartData}
            yAxisLabel={`${t(
                "portfolioDashboard.chartHelpers.kgCO2em2",
                "kg CO2e / m²"
            )}`}
        />
    );
}

export default PortfolioIntensityByMonthChart;
