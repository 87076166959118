import { useTheme } from "@mui/material";
import {
    Bar,
    CartesianGrid,
    Cell,
    ComposedChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

import ChartTooltip, { ChartDataItem } from "./ChartTooltip";

export type MonthChartData = {
    label: string | null;
} & ChartDataItem;

type Props = {
    chartData: MonthChartData[];
    yAxisLabel: string;
};

function MonthChart(props: Props) {
    const { chartData, yAxisLabel } = props;

    const theme = useTheme();

    return (
        <ResponsiveContainer width="100%" height={446}>
            <ComposedChart data={chartData} style={{ cursor: "pointer" }}>
                <CartesianGrid
                    stroke={theme.palette.grey.A100}
                    strokeDasharray="100% 0.5"
                    vertical={false}
                />

                <YAxis
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    tick={{ fontSize: "10px" }}
                    label={{
                        value: yAxisLabel,
                        fill: theme.palette.grey[600],
                        fontSize: "12px",
                        angle: -90,
                        position: "insideBottomLeft",
                        textAnchor: "start",
                        dx: 15,
                        props: { fontSize: "6px" },
                    }}
                />

                <Tooltip cursor={false} content={ChartTooltip} />

                <Legend
                    verticalAlign="bottom"
                    wrapperStyle={{ fontSize: "0.85rem", color: "grey" }}
                />

                <Bar
                    legendType="none"
                    radius={[5, 5, 0, 0]}
                    barSize={40}
                    dataKey="value"
                    xAxisId={1}
                >
                    {chartData.map((index) => (
                        <Cell
                            fill={theme.palette.primary.main}
                            key={`cell-${index}`}
                        />
                    ))}
                </Bar>

                <XAxis
                    dataKey="label"
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    xAxisId={1}
                    dy={5}
                    tick={{ fontSize: "14px" }}
                />
                <XAxis
                    dataKey="label"
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    xAxisId={0}
                    hide
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export default MonthChart;
