import _ from "lodash";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "recharts";

import CurrentYearToolTip from "./CurrentYearToolTip";
import {
    DataAmount,
    DataAmountBox,
    DataBox,
    GreyHeader,
    GreyHeaderBox,
    TooltipContainer,
    TotalBox,
    HeaderBox,
} from "./tooltip.styles";
import { formatAreaLocale } from "../../../utils/report.helpers";

export type ChartDataItem = {
    title: string;
    header: number | string | null;
    value: number;
    formattedValue: string | null;
    formattedProjectedValue?: string | null;
    assetCount?: number;
    totalArea?: number;
};

function ChartTooltip(props: TooltipProps<number, string>) {
    const tooltip = _.first(props.payload)?.payload as ChartDataItem;
    const { t } = useTranslation();

    if (!tooltip) {
        return <> </>;
    }

    const currentYear = new Date().getFullYear();
    const isCurrentYear = currentYear === tooltip.header;

    return isCurrentYear ? (
        <CurrentYearToolTip item={tooltip} />
    ) : (
        <TooltipContainer>
            <HeaderBox>{tooltip.header}</HeaderBox>
            <DataBox>
                <GreyHeaderBox>
                    <TotalBox />
                    <GreyHeader>{tooltip.title}</GreyHeader>
                </GreyHeaderBox>
                <DataAmountBox>
                    <DataAmount>{tooltip.formattedValue}</DataAmount>
                </DataAmountBox>
            </DataBox>
            {tooltip.assetCount && (
                <DataBox>
                    <GreyHeaderBox>
                        <GreyHeader>
                            {t(
                                "portfolioDashboard.hoverBox.assetNumber",
                                "Number of Assets"
                            )}
                        </GreyHeader>
                    </GreyHeaderBox>
                    <DataAmountBox>
                        <DataAmount>{tooltip.assetCount}</DataAmount>
                    </DataAmountBox>
                </DataBox>
            )}
            {tooltip.totalArea && (
                <DataBox>
                    <GreyHeaderBox>
                        <GreyHeader>
                            {t(
                                "portfolioDashboard.hoverBox.totalArea",
                                "Total Area"
                            )}
                        </GreyHeader>
                    </GreyHeaderBox>
                    <DataAmountBox>
                        <DataAmount>
                            {formatAreaLocale(tooltip.totalArea, t)}{" "}
                            {t("portfolioDashboard.chartHelpers.sqm", "m²")}
                        </DataAmount>
                    </DataAmountBox>
                </DataBox>
            )}
        </TooltipContainer>
    );
}

export default ChartTooltip;
