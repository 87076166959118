import { CheckCircle, Download } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TFunction } from "i18next";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { getReportFileName } from "components/Report/ReportContentHelpers";
import { downloadBuffer } from "utils/file.utils";

import { ReportEntity } from "./ReportsSaved";

export const downloadReport = async (report: ReportEntity, t: TFunction) => {
    const endpoint = `report/download/${report.id}`;
    const fileName = getReportFileName(t, report);

    return downloadBuffer(endpoint, fileName);
};

function DownloadReport({ report }: { report: ReportEntity }) {
    const { t } = useTranslation();

    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = useCallback(async () => {
        setIsDownloading(true);
        toast.promise(
            downloadReport(report, t).then(() => setIsDownloading(false)),
            {
                pending: t("labels.loading", "Loading..."),
                success: {
                    render() {
                        return t(
                            "reportsList.success.download",
                            "Report downloaded successfully"
                        );
                    },
                    type: "default",
                    closeButton: false,
                    icon: <CheckCircle />,
                },
                error: t(
                    "reportsList.errors.download",
                    "An error occurred trying to download the report. Please try again later."
                ),
            }
        );
    }, [t, report]);

    return (
        <>
            <IconButton
                disabled={isDownloading}
                sx={{ mx: "auto" }}
                onClick={() => handleDownload()}
            >
                <Download color="primary" fontSize="medium" />
            </IconButton>
        </>
    );
}

export default DownloadReport;
