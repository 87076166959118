import { useQuery } from "@apollo/client";
import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DATA_QUALITY_QUERY } from "components/PortfolioDashboard/Queries/dataQualityQuery";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { Chart } from "glue/Chart";
import {
    AssessmentAggregation,
    AssessmentDataType,
    GetDataQualityQuery,
    GetDataQualityQueryVariables,
} from "graphql-types/graphql";
import { getCurrentYear } from "utils/date.utils";
import { useReportingYearContext } from "utils/userContext";

import { DataQualityTooltip } from "./components/DataQualityTooltip";
import { getDataQualityChartData } from "./dataQualityChart.helpers";

type Props = {
    activeTab: AssessmentDataType;
    byMonth?: boolean;
};

export const MuiDataQualityChart = (props: Props) => {
    const { activeTab, byMonth } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const { activeYear } = useSelectedYearContext();
    const reportingYear = useReportingYearContext();
    const currentYear = getCurrentYear();

    const variables: GetDataQualityQueryVariables = useMemo(() => {
        return {
            from: `${byMonth ? activeYear : reportingYear}-01-01`,
            to: `${byMonth ? activeYear : currentYear}-12-31`,
            assessmentDataType: activeTab,
            aggregation: byMonth ? AssessmentAggregation.MONTH : undefined,
        };
    }, [activeTab, currentYear, activeYear, reportingYear, byMonth]);

    const { data, previousData } = useQuery<
        GetDataQualityQuery,
        GetDataQualityQueryVariables
    >(DATA_QUALITY_QUERY, {
        variables,
    });

    const dataQualityForOrganization = useMemo(() => {
        const dataLoader = data ?? previousData;

        return dataLoader?.me.organization?.dataQuality ?? [];
    }, [data, previousData]);

    const { series, xAxis, yAxis } = getDataQualityChartData({
        dataQualityForOrganization,
        byMonth,
        theme,
        t,
    });

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            yAxis={yAxis}
            legend={{ hidden: false }}
            customTooltip={DataQualityTooltip}
        />
    );
};
