import { PaletteColor, Theme } from "@mui/material";
import interpolate from "color-interpolate";
import { TFunction } from "i18next";
import { camelCase, chain, startCase } from "lodash";
import { DateTime } from "luxon";

import {
    DataQuality,
    DataQualityDistribution,
    DataQualityFragment,
    IntegrationType,
} from "graphql-types/graphql";
import { getPercentage } from "utils/maths";

import {
    DataQualityBarData,
    DataQualityChartData,
    DataQualityGradient,
} from "./dataQuality.types";
import { DataQualityItem } from "./DataQualitySummary";

export enum DataQualityEnum {
    LOW = "low",
    MEDIUM = "medium",
    HIGH = "high",
}

export const getDataQualityTitle = (
    type: DataQualityEnum,
    full: boolean,
    t: TFunction
) => {
    switch (type) {
        case DataQualityEnum.LOW:
            return full
                ? t("dataQuality.lowQuality", "Low Quality", {
                      ns: "translation",
                  })
                : t("dataQuality.low", "Low", { ns: "translation" });
        case DataQualityEnum.MEDIUM:
            return full
                ? t("dataQuality.mediumQuality", "Medium Quality", {
                      ns: "translation",
                  })
                : t("dataQuality.medium", "Medium", { ns: "translation" });
        case DataQualityEnum.HIGH:
            return full
                ? t("dataQuality.highQuality", "High Quality", {
                      ns: "translation",
                  })
                : t("dataQuality.high", "High", { ns: "translation" });
    }
};

export const getDataQualityColor = (
    type: DataQualityEnum,
    palette: Theme["palette"]
) => {
    switch (type) {
        case DataQualityEnum.LOW:
            return palette.error.main;
        case DataQualityEnum.MEDIUM:
            return palette.warning.main;
        case DataQualityEnum.HIGH:
            return palette.success.main;
    }
};

export const sumDataQualities = (dataQuality: DataQualityFragment) => {
    const { highQuality, mediumQuality, lowQuality } = dataQuality;

    return {
        sumHighQuality: highQuality.reduce(
            (acc, curr) => acc + curr.assessmentDays,
            0
        ),
        sumMediumQuality: mediumQuality.reduce(
            (acc, curr) => acc + curr.assessmentDays,
            0
        ),
        sumLowQuality: lowQuality.reduce(
            (acc, curr) => acc + curr.assessmentDays,
            0
        ),
    };
};

export const getDistributionPalette = (
    distribution: number,
    palette: PaletteColor
) => {
    const colorRange = {
        min: palette.dark,
        max: palette.light,
    };

    const range = interpolate([colorRange.min, colorRange.max]);
    return range(distribution / 100);
};

export const getDataQualityGradient = (
    dataQuality: readonly DataQualityDistribution[],
    palette: PaletteColor,
    total: number
) => {
    // Spread to create a new array as dataQuality items are readonly
    return [...dataQuality]
        .sort((a, b) => a.priorityDistribution - b.priorityDistribution)
        .map(({ priorityDistribution, assessmentDays, sources }) => ({
            source: sources.join(", "),
            distribution: priorityDistribution,
            count: assessmentDays,
            color: getDistributionPalette(priorityDistribution, palette),
            percentage: getPercentage(assessmentDays, total),
        }));
};

export const getQualityGradient = ({
    dataQuality,
    theme,
}: {
    dataQuality: DataQuality;
    theme: Theme;
}): {
    low: DataQualityGradient[];
    medium: DataQualityGradient[];
    high: DataQualityGradient[];
} => {
    const { highQuality, mediumQuality, lowQuality } = dataQuality;

    const { sumHighQuality, sumMediumQuality, sumLowQuality } =
        sumDataQualities(dataQuality);
    const total = sumHighQuality + sumMediumQuality + sumLowQuality;

    return {
        low: getDataQualityGradient(lowQuality, theme.palette.error, total), // from dark to light
        medium: getDataQualityGradient(
            mediumQuality,
            theme.palette.warning,
            total
        ).reverse(), // from light to dark

        high: getDataQualityGradient(
            highQuality,
            theme.palette.success,
            total
        ).reverse(), // from light to dark
    };
};

export const mapDataQualitySources = (source: string, t: TFunction) => {
    switch (source) {
        case "EPC_PROXY":
            return t("dataQuality.source.proxyEpc", "Proxy EPC", {
                ns: "translation",
            });
        case "EPC_MANUAL":
            return t("dataQuality.source.manualEpc", "Manual EPC", {
                ns: "translation",
            });
        case "EPC":
            return t("dataQuality.source.Epc", "EPC", { ns: "translation" });
        case "Uncategorized":
            return t("dataQuality.source.uncategorized", "Uncategorized", {
                ns: "translation",
            });
        case IntegrationType.MANUAL:
            return t("dataQuality.source.manualMeters", "Manual Meters", {
                ns: "translation",
            });
        default:
            return source === "OIS" ? source : getPascalCaseName(source);
    }
};

export const getDataQualityItem = (
    quality: readonly DataQualityDistribution[],
    t: TFunction
) => {
    const sortedQuality = [...quality].sort(
        (a, b) => a.priorityDistribution - b.priorityDistribution
    );
    const sources = [...quality]
        .sort((a, b) => a.priorityDistribution - b.priorityDistribution)
        .flatMap((item) => item.sources)
        .map((source) => ({
            name: mapDataQualitySources(source, t),
        }));

    return {
        value: sortedQuality.reduce(
            (acc, curr) => acc + curr.assessmentDays,
            0
        ),
        sources: sources.length
            ? sources
            : [{ name: mapDataQualitySources("Uncategorized", t) }],
    };
};

export function formatDataQualityToDataQualityItems(
    dataQuality: DataQualityFragment,
    t: TFunction
): DataQualityItem[] {
    const { highQuality, mediumQuality, lowQuality } = dataQuality;

    return [
        highQuality.length && {
            type: DataQualityEnum.HIGH,
            ...getDataQualityItem(highQuality, t),
        },
        mediumQuality.length && {
            type: DataQualityEnum.MEDIUM,
            ...getDataQualityItem(mediumQuality, t),
        },
        lowQuality.length && {
            type: DataQualityEnum.LOW,
            ...getDataQualityItem(lowQuality, t),
        },
    ].filter(Boolean) as DataQualityItem[];
}

const getPascalCaseName = (name: string) => {
    return startCase(camelCase(name)).replace(/ /g, "");
};

const weights = {
    [DataQualityEnum.HIGH]: 100,
    [DataQualityEnum.MEDIUM]: 10,
    [DataQualityEnum.LOW]: 1,
};

const getWeightByDataQuality = (dataQuality: DataQualityFragment) => {
    const { sumHighQuality, sumMediumQuality, sumLowQuality } =
        sumDataQualities(dataQuality);

    const items = [
        { type: DataQualityEnum.HIGH, value: sumHighQuality },
        { type: DataQualityEnum.MEDIUM, value: sumMediumQuality },
        { type: DataQualityEnum.LOW, value: sumLowQuality },
    ];

    const total = items.reduce((acc, item) => acc + item.value, 0);

    return items.reduce((acc, curr) => {
        const percentage = (curr.value / total) * 100;
        const weight = weights[curr.type] || 0;

        return acc + percentage * weight;
    }, 0);
};

export const sortByDataQuality = (
    a: DataQualityFragment,
    b: DataQualityFragment
) => {
    const pos1 = getWeightByDataQuality(a);
    const pos2 = getWeightByDataQuality(b);

    return pos1 - pos2;
};

export const getDataQualityChartData = ({
    dataQualityForOrganization,
    theme,
    byMonth = false,
}: {
    dataQualityForOrganization: readonly DataQualityFragment[];
    theme: Theme;
    byMonth?: boolean;
}): DataQualityChartData => {
    const sources: DataQualityChartData["sources"] = [];
    const colors: DataQualityChartData["colors"] = {};

    const dataQualityChartData = chain(dataQualityForOrganization)
        .sortBy("from")
        .map((dataQuality) => {
            const date = DateTime.fromISO(dataQuality.from);
            const qualityGradients = getQualityGradient({
                dataQuality,
                theme,
            });

            const data: DataQualityBarData = {
                period: byMonth
                    ? date.monthShort || date.month.toString()
                    : date.year.toString(),
                dataQuality,
            };

            Object.values(qualityGradients).forEach((gradients) => {
                gradients.forEach((gradient) => {
                    const source = gradient.source;

                    if (!sources.includes(source)) {
                        sources.push(source);
                    }
                    colors[source] = gradient.color;
                    data[source] = gradient.percentage;
                });
            });

            return data;
        })
        .value();

    return {
        colors,
        sources,
        data: dataQualityChartData,
    };
};
