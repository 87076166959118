import { useTranslation } from "react-i18next";

import { ChartDataItem } from "./ChartTooltip";
import {
    AverageTotalBox,
    DataAmount,
    DataAmountBox,
    DataBox,
    GreyHeader,
    GreyHeaderBox,
    ProjectedBox,
    TotalBox,
    HeaderBox,
} from "./tooltip.styles";

type Props = {
    item: ChartDataItem;
};

function CurrentYearToolTip(props: Props) {
    const { item: tooltip } = props;
    const { t } = useTranslation();

    return (
        <AverageTotalBox>
            <HeaderBox>
                {tooltip.header}{" "}
                {t("portfolioDashboard.yearSelect.ytd", " YTD")}
            </HeaderBox>
            <DataBox>
                <GreyHeaderBox>
                    <ProjectedBox />
                    <GreyHeader>
                        {t(
                            "portfolioDashboard.hoverBox.projected",
                            "Projected"
                        )}{" "}
                        {tooltip.title}
                    </GreyHeader>
                </GreyHeaderBox>
                <DataAmountBox>
                    <DataAmount>{tooltip.formattedProjectedValue}</DataAmount>
                </DataAmountBox>
            </DataBox>
            <DataBox>
                <GreyHeaderBox>
                    <TotalBox />
                    <GreyHeader>
                        {tooltip.title}{" "}
                        {t("portfolioDashboard.hoverBox.ytd", "YTD")}
                    </GreyHeader>
                </GreyHeaderBox>
                <DataAmountBox>
                    <DataAmount>{tooltip.formattedValue}</DataAmount>
                </DataAmountBox>
            </DataBox>
        </AverageTotalBox>
    );
}

export default CurrentYearToolTip;
