import { List, ListItem } from "@mui/material";

import { BenchmarkMarkers } from "components/types";

import { BenchmarkItemToggle } from "./BenchmarkItemToggle";

type Props = {
    benchmarkMarkers: BenchmarkMarkers;
    onToggleMarkerVisibility: (markerId: string, isVisible: boolean) => void;
};

export function BenchmarkListToggle(props: Props) {
    const { benchmarkMarkers, onToggleMarkerVisibility } = props;

    const items = benchmarkMarkers.map((benchmarkMarker) => (
        <ListItem key={benchmarkMarker.id} disablePadding>
            <BenchmarkItemToggle
                benchmarkMarker={benchmarkMarker}
                onToggleMarkerVisibility={onToggleMarkerVisibility}
            />
        </ListItem>
    ));

    return <List>{items}</List>;
}
