import React, { useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";

import ReportDateContextProvider from "components/Report/reportDateContext";
import { ReportFramework } from "graphql-types/graphql";

import Report from "../../components/Report/Report";

type ReportParams = {
    reportType: string;
};

const ReportPage = () => {
    const { reportType } = useParams<ReportParams>();

    const currentReport = useMemo(() => {
        return reportType && reportType.toLocaleUpperCase() in ReportFramework
            ? (reportType.toLocaleUpperCase() as ReportFramework)
            : null;
    }, [reportType]);

    return currentReport ? (
        <ReportDateContextProvider>
            <Report reportType={currentReport} />
        </ReportDateContextProvider>
    ) : (
        <Navigate to="/reports" replace />
    );
};

export default ReportPage;
