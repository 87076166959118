import { Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import TabsPanel from "components/TabsPanel/TabsPanel";

import { ReportsFramework } from "./ReportsFramework";
import { ReportsSaved } from "./ReportsSaved";

enum ReportTab {
    FRAMEWORK,
    SAVED_REPORTS,
}

const ReportsListPage = () => {
    const { t } = useTranslation(["report"]);

    const [searchParams, setSearchParams] = useSearchParams();

    const [activeTab, setActiveTab] = useState(
        searchParams.get("section") === "saved"
            ? ReportTab.SAVED_REPORTS
            : ReportTab.FRAMEWORK
    );

    const tabs = useMemo(
        () => [
            {
                title: t("reportsList.frameworks", "Frameworks", {
                    ns: "report",
                }),
            },
            {
                title: t("reportsList.savedReports", "Saved Reports", {
                    ns: "report",
                }),
            },
        ],
        [t]
    );

    const handleTabChange = (
        _: React.ChangeEvent<object>,
        newValue: number
    ) => {
        setActiveTab(newValue);
        setSearchParams({
            section:
                newValue === ReportTab.SAVED_REPORTS ? "saved" : "framework",
        });
    };

    const ActiveReportTab = useCallback(() => {
        switch (activeTab) {
            case ReportTab.FRAMEWORK:
                return <ReportsFramework />;
            case ReportTab.SAVED_REPORTS:
                return <ReportsSaved />;
        }
    }, [activeTab]);

    return (
        <>
            <Typography variant="h2" sx={{ mb: 4 }}>
                {t("reportsList.title", "Reports", { ns: "report" })}
            </Typography>
            <TabsPanel
                tabsData={tabs}
                activeTab={activeTab}
                onChange={handleTabChange}
            />

            <ActiveReportTab />
        </>
    );
};

export default ReportsListPage;
