import { Box } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useActiveAssetGroup } from "components/AssetsGroup/hooks/useActiveAssetGroup";
import { AssessmentDataType, ReportFramework } from "graphql-types/graphql";
import { useOrganizationContext } from "utils/userContext";

import ReportContent from "./ReportContent";
import { useReportDateContext } from "./reportDateContext";
import ReportFilter from "./ReportFilter";
import { ReportHeader } from "./ReportHeader";
import { ReportModal } from "./ReportModal";
import { ReportTab } from "./reportPageHelpers";

const Report = ({ reportType }: { reportType: ReportFramework }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [activeTab, setActiveTab] = useState(ReportTab.SUMMARY);
    const [openModal, setOpenModal] = useState(false);

    const reportDateContext = useReportDateContext();

    const [activeAssetGroup] = useActiveAssetGroup();
    const organization = useOrganizationContext();

    const setFilter = useCallback(
        (filterStr: string) =>
            setSearchParams((prevState) => {
                prevState.set("filter", filterStr);
                return prevState;
            }),
        [setSearchParams]
    );

    const dataType = useMemo(() => {
        const key = (searchParams.get("filter") ??
            AssessmentDataType.COMBINED) as keyof typeof AssessmentDataType;

        return AssessmentDataType[key] ?? AssessmentDataType.COMBINED;
    }, [searchParams]);

    const assetDisplay = {
        filteredAssets: activeAssetGroup.count,
        unfilteredAssets: organization?.allLocationsCount || 0,
    };

    return (
        <Box>
            <ReportHeader
                dataType={dataType}
                reportType={reportType}
                assetDisplay={assetDisplay}
                openModal={() => setOpenModal(true)}
            />
            <ReportFilter
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setSelectedFilter={setFilter}
                dataType={dataType}
            />
            <ReportContent
                activeTab={activeTab}
                dataType={dataType}
                reportType={reportType}
            />

            <ReportModal
                source={dataType}
                isOpen={openModal}
                reportType={reportType}
                dateContext={reportDateContext}
                onClose={() => setOpenModal(false)}
            />
        </Box>
    );
};

export default Report;
