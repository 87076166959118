import "react-toastify/dist/ReactToastify.css";

import { styled } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

const StyledToastContainer = styled(ToastContainer)(() => ({
    ".Toastify__progress-bar--default": {
        background: "none",
        backgroundColor: "currentColor",
    },

    " .Toastify__toast--default": {
        color: "#1E3B4D",
    },
}));

function Toast() {
    return (
        <StyledToastContainer
            position={toast.POSITION.BOTTOM_LEFT}
            closeOnClick
        />
    );
}

export default Toast;
