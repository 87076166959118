import { useTranslation } from "react-i18next";

import {
    AssetGroupFilterCategory,
    CrremCategory,
    PropertyType,
    LegacyBenchmarkType,
} from "graphql-types/graphql";

export function useFilterTranslations() {
    const { t } = useTranslation();

    const translations: Record<
        | AssetGroupFilterCategory
        | PropertyType
        | CrremCategory
        | LegacyBenchmarkType,
        string
    > = {
        [AssetGroupFilterCategory.COUNTRY]: t(
            "assetGroup.assetGroupCategory.Country",
            "Country"
        ),
        [AssetGroupFilterCategory.CITY]: t(
            "assetGroup.assetGroupCategory.City",
            "City"
        ),
        [AssetGroupFilterCategory.GENERALPROPERTYTYPES]: t(
            "assetGroup.assetGroupCategory.GeneralPropertyTypes",
            "General Property Types"
        ),
        [AssetGroupFilterCategory.CRREMPROPERTYTYPES]: t(
            "assetGroup.assetGroupCategory.CRREMPropertyTypes",
            "CRREM Property Types"
        ),
        [AssetGroupFilterCategory.NATIONALPROPERTYTYPES]: t(
            "assetGroup.assetGroupCategory.NationalPropertyTypes",
            "National Property Types"
        ),
        [AssetGroupFilterCategory.CONSTRUCTIONYEAR]: t(
            "assetGroup.assetGroupCategory.ConstructionYear",
            "Construction Year"
        ),
        [AssetGroupFilterCategory.PROPERTYSIZE]: t(
            "assetGroup.assetGroupCategory.PropertySize",
            "Property Size"
        ),
        [AssetGroupFilterCategory.LEGALOWNER]: t(
            "assetGroup.assetGroupCategory.LegalOwner",
            "Legal Owner"
        ),
        [AssetGroupFilterCategory.ID]: t(
            "assetGroup.assetGroupCategory.ID",
            "ID"
        ),
        [PropertyType.AGRICULTURAL]: t(
            "shared.propertyType.agricultural",
            "Agricultural"
        ),
        [PropertyType.COMMERCIAL_RETAIL]: t(
            "shared.propertyType.commercialRetail",
            "Commercial Retail"
        ),
        [PropertyType.CULTURAL_PUBLIC_SPACES]: t(
            "shared.propertyType.culturalPublicSpaces",
            "Culture Public Spaces"
        ),
        [PropertyType.EDUCATIONAL]: t(
            "shared.propertyType.educational",
            "Educational"
        ),
        [PropertyType.GOVERNMENT_CIVIC]: t(
            "shared.propertyType.governmentCivic",
            "Government Civic"
        ),
        [PropertyType.HEALTHCARE]: t(
            "shared.propertyType.healthcare",
            "Healthcare"
        ), // PropertyType & CRREM
        [PropertyType.HOSPITALITY_LODGING]: t(
            "shared.propertyType.hospitalityLodging",
            "Hospitality Lodging"
        ),
        [PropertyType.INDUSTRIAL]: t(
            "shared.propertyType.industrial",
            "Industrial"
        ),
        [PropertyType.LEISURE_RECREATION]: t(
            "shared.propertyType.leisureRecreation",
            "Leisure Recreation"
        ),
        [PropertyType.MIXED_USE]: t(
            "shared.propertyType.mixedUse",
            "Mixed Use"
        ), // PropertyType & CRREM
        [PropertyType.OFFICE_ADMINISTRATION]: t(
            "shared.propertyType.officeAdministration",
            "Office Administration"
        ),
        [PropertyType.OTHER]: t("shared.propertyType.other", "Other"),
        [PropertyType.RESIDENTIAL]: t(
            "shared.propertyType.residential",
            "Residential"
        ),
        [PropertyType.SPECIAL_USE]: t(
            "shared.propertyType.specialUse",
            "Special Use"
        ),
        [PropertyType.TECHNOLOGY_SCIENCE]: t(
            "shared.propertyType.technologyScience",
            "Technology Science"
        ),
        [PropertyType.TRANSPORT_INFRASTRUCTURE]: t(
            "shared.propertyType.transportInfrastructure",
            "Transport Infrastructure"
        ),
        [CrremCategory.HOTEL]: t("shared.crremCategory.hotel", "Hotel"),
        [CrremCategory.INDUSTRIAL_DISTRIBUTION_WAREHOUSE]: t(
            "shared.crremCategory.industrialDistributionWarehouse",
            "Industrial Distribution Warehouse"
        ),
        [CrremCategory.OFFICE]: t("shared.crremCategory.office", "Office"),
        [CrremCategory.LODGING_LEISURE_RECREATION]: t(
            "shared.crremCategory.lodgingLeisureRecreation",
            "Lodging Leisure Recreation"
        ),
        [CrremCategory.RETAIL_HIGH_STREET]: t(
            "shared.crremCategory.retailHighStreet",
            "Retail High Street"
        ),
        [CrremCategory.RETAIL_SHOPPING_CENTER]: t(
            "shared.crremCategory.retailShoppingCenter",
            "Retail Shopping Center"
        ),
        [CrremCategory.RETAIL_WAREHOUSE]: t(
            "shared.crremCategory.retailWarehouse",
            "Retail Warehouse"
        ),
        [CrremCategory.UNCATEGORIZED]: t(
            "shared.crremCategory.uncategorized",
            "Uncategorized"
        ),
        [LegacyBenchmarkType.BBR_PROPERTY_TYPE]: t(
            "shared.legacyBenchmarkTypes.bbrPropertyType",
            "BBR Property Type"
        ),
        [LegacyBenchmarkType.BUILDING_AGE]: t(
            "shared.legacyBenchmarkTypes.buildingAge",
            "Building Age"
        ),
        [LegacyBenchmarkType.BUILDING_SIZE]: t(
            "shared.legacyBenchmarkTypes.buildingSize",
            "Building Size"
        ),
        [LegacyBenchmarkType.CONSUMPTION_TYPE]: t(
            "shared.legacyBenchmarkTypes.consumptionType",
            "Consumption Type"
        ),
        [LegacyBenchmarkType.CRREM_PROPERTY_TYPE]: t(
            "shared.legacyBenchmarkTypes.crremPropertyType",
            "CRREM Property Type"
        ),
        [LegacyBenchmarkType.LEGACY_PROPERTY_TYPE]: t(
            "shared.legacyBenchmarkTypes.legacyPropertyType",
            "Legacy Property Type"
        ),
        [LegacyBenchmarkType.NATIONAL]: t(
            "shared.legacyBenchmarkTypes.national",
            "National"
        ),
        [LegacyBenchmarkType.REGION]: t(
            "shared.legacyBenchmarkTypes.region",
            "Region"
        ),
        [LegacyBenchmarkType.VERTICAL]: t(
            "shared.legacyBenchmarkTypes.vertical",
            "Vertical"
        ),
    };

    return {
        translations,
    };
}
