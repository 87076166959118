import { gql } from "@apollo/client";

export const DATA_OVERVIEW_EPC_QUERY = gql`
    query GetDataOverviewEPCAssessments($assetGroupId: ID) {
        me {
            id
            organization {
                id
                getLocations(assetGroupId: $assetGroupId) {
                    id
                    saleDate
                    assessments(
                        ignoreProxy: true
                        filter: { dataType: ESTIMATE }
                    ) {
                        id
                        to
                        from
                        vertical
                        unit
                        areaData {
                            totalArea
                        }
                        consumptionData {
                            totalConsumption
                        }
                        assessmentMetadata {
                            addresses
                            nationalBuildingIds
                        }
                        emissionDataGrams {
                            ownedEmission
                        }
                        origin {
                            id
                        }
                        integration {
                            id
                            type
                        }
                        data {
                            ... on IntegrationEpcAssessmentData {
                                epcIdentifier
                                classification
                                pdfLink
                                originalPdfLink
                            }
                            ... on IntegrationEpcUkAssessmentData {
                                lmkKey
                                classification
                                assessmentLink
                            }
                        }
                    }
                }
            }
        }
    }
`;
