import { GridRowModel } from "@mui/x-data-grid";
import _ from "lodash";
import { DateTime } from "luxon";

import {
    AssessmentCategory,
    AssessmentDataType,
    ConsumptionType,
    CreateAssessmentInput,
    CreateLocationMetadataInput,
    EmissionType,
    EmissionUnit,
    GetEmissionsQuery,
    GetLocationsQuery,
} from "graphql-types/graphql";
import { isValidNumber } from "utils/maths";
import { isValidUrl } from "utils/report.helpers";

import { ParsedCsvRow } from "./dataUploadTypes";

export type Asset = NonNullable<
    GetLocationsQuery["me"]["organization"]
>["locations"]["edges"][0]["node"];
export type Emission = GetEmissionsQuery["emissions"]["edges"][0]["node"];

export const parseDate = (date?: string) => {
    if (!date) {
        return undefined;
    }

    const dateWithoutTime = date.split("T")[0];

    const isValidDate =
        DateTime.fromFormat(dateWithoutTime, "yyyy-MM-dd").isValid ||
        DateTime.fromFormat(dateWithoutTime, "yyyy/MM/dd").isValid ||
        DateTime.fromFormat(dateWithoutTime, "yyyy.MM.dd").isValid;

    if (!isValidDate) {
        return undefined;
    }

    let dateFormat = "";
    if (date.includes("-")) {
        dateFormat = "yyyy-MM-dd";
    } else if (date.includes("/")) {
        dateFormat = "yyyy/MM/dd";
    } else if (date.includes(".")) {
        dateFormat = "yyyy.MM.dd";
    }

    return DateTime.fromFormat(dateWithoutTime, dateFormat).toFormat(
        "yyyy-MM-dd"
    );
};

const parseConsumption = (consumption?: string) => {
    if (!consumption) {
        return null;
    }

    if (consumption.length) {
        return parseFloat(consumption);
    }
    return null;
};

const isEmpty = (string?: string) => {
    return string?.length === 0;
};

const getLocationId = (locations: Asset[], assetID?: string) => {
    if (!assetID) {
        return null;
    }

    const location = locations?.find(
        (location) =>
            assetID === location.externalId ||
            assetID === location.id ||
            assetID === location.name ||
            assetID === location.shortAddress
    );

    if (!location) return null;

    const supplierLocations = location.supplierLocations?.length
        ? location.supplierLocations
        : null;

    return { id: location.id, supplierLocations };
};

export const getEmissionIdAndCategoryFromFractions = (
    type: string,
    unit: string,
    fractions: Emission[]
) => {
    const emissionUnit = getEmissionUnit(unit);

    let category: AssessmentCategory | undefined;
    let fraction: Emission | undefined;
    if (type === "elektricitet" || type === "electricity") {
        category = AssessmentCategory.ELECTRICITY_REMOTE;
        fraction = fractions.find(
            (fraction) =>
                fraction.unit === emissionUnit &&
                fraction.type === EmissionType.ELECTRICITY_OTHER
        );
    } else if (type === "district heating" || type === "fjernvarme") {
        category = AssessmentCategory.HEATING_REMOTE;
        fraction = fractions.find(
            (fraction) =>
                fraction.internalDescriptor?.includes("Fjernvarme") ||
                (fraction.internalDescriptor?.includes("HEATING_REMOTE") &&
                    fraction.type === EmissionType.HEATING_REMOTE &&
                    fraction.unit === emissionUnit)
        );
    } else if (type === "water" || type === "vand") {
        category = AssessmentCategory.WATER;
        fraction = fractions.find(
            (fraction) =>
                fraction.unit === emissionUnit &&
                fraction.type === EmissionType.WATER &&
                fraction.internalDescriptor?.includes("WATER")
        );
    } else if (type === "gas") {
        category = AssessmentCategory.HEATING_GENERATED;
        fraction = fractions.find(
            (fraction) =>
                fraction.unit === emissionUnit &&
                fraction.internalDescriptor?.includes("EPCFuelTypes.CityGas") &&
                fraction.type === EmissionType.HEATING_GAS
        );
    } else if (type === "naturgas" || type === "natural gas") {
        category = AssessmentCategory.HEATING_GENERATED;
        fraction = fractions.find(
            (fraction) =>
                fraction.unit === emissionUnit &&
                fraction.internalDescriptor?.includes(
                    "EPCFuelTypes.NaturalGas"
                ) &&
                fraction.type === EmissionType.HEATING_GAS
        );
    } else if (type === "køling" || type === "district chilled water") {
        category = AssessmentCategory.COOLING_REMOTE;
        fraction = fractions.find(
            (fraction) =>
                fraction.type === EmissionType.COOLING_REMOTE &&
                fraction.unit === emissionUnit &&
                fraction.internalDescriptor?.includes("COOLING_REMOTE")
        );
    } else if (type === "olie" || type === "oil" || type === "lpg") {
        category = AssessmentCategory.HEATING_GENERATED;
        fraction = fractions.find(
            (fraction) =>
                fraction.type === EmissionType.HEATING_OIL &&
                fraction.unit === emissionUnit &&
                fraction.internalDescriptor?.includes("EPCFuelType.FuelOil")
        );
    } else if (
        type === "liquified natural gas" ||
        type === "flydende naturgas"
    ) {
        category = AssessmentCategory.HEATING_GENERATED;
        fraction = fractions.find(
            (fraction) =>
                fraction.type === EmissionType.LIQUIFIED_NATURAL_GAS &&
                fraction.unit === emissionUnit
        );
    }
    return fraction ? { emissionId: fraction.id, category } : null;
};

export const getEmissionUnit = (unit: string | undefined) => {
    const lowercaseUnit = unit?.toLowerCase();

    switch (lowercaseUnit) {
        case "m3":
            return EmissionUnit.M_3;
        case "kwh":
            return EmissionUnit.KWH;
        case "gj":
            return EmissionUnit.GJ;
        case "liter":
            return EmissionUnit.LITER;
    }
};

const getEmissionIdFromSupplierLocations = (
    supplierLocations: Asset["supplierLocations"],
    emissionType: EmissionType,
    emissionUnit: EmissionUnit
) => {
    if (!supplierLocations) return null;

    return supplierLocations
        .find((sup) => sup.emissionType === emissionType)
        ?.supplier.emissions.find(
            (e) => e.unit === emissionUnit && e.type === emissionType
        )?.id;
};

export const getEmissionIdAndCategoryFromSuppliers = (
    supplierLocations: Asset["supplierLocations"],
    type: string,
    unit: string
) => {
    const emissionUnit = getEmissionUnit(unit);

    let emissionType: EmissionType | undefined;
    let category: AssessmentCategory | undefined;
    if (type === "elektricitet" || type === "electricity") {
        emissionType = EmissionType.ELECTRICITY_OTHER;
        category = AssessmentCategory.ELECTRICITY_REMOTE;
    } else if (type === "district heating" || type === "fjernvarme") {
        emissionType = EmissionType.HEATING_REMOTE;
        category = AssessmentCategory.HEATING_REMOTE;
    } else if (type === "water" || type === "vand") {
        emissionType = EmissionType.WATER;
        category = AssessmentCategory.WATER;
    } else if (type === "køling" || type === "district chilled water") {
        emissionType = EmissionType.COOLING_REMOTE;
        category = AssessmentCategory.COOLING_REMOTE;
    } else if (
        type === "naturgas" ||
        type === "natural gas" ||
        type === "gas"
    ) {
        emissionType = EmissionType.HEATING_GAS;
        category = AssessmentCategory.HEATING_GENERATED;
    } else if (type === "oil" || type === "olie" || type === "lpg") {
        emissionType = EmissionType.HEATING_OIL;
        category = AssessmentCategory.HEATING_GENERATED;
    } else if (
        type === "liquified natural gas" ||
        type === "flydende naturgas"
    ) {
        emissionType = EmissionType.LIQUIFIED_NATURAL_GAS;
        category = AssessmentCategory.HEATING_GENERATED;
    }

    if (!emissionType || !emissionUnit) {
        return null;
    }

    const emissionId = getEmissionIdFromSupplierLocations(
        supplierLocations,
        emissionType,
        emissionUnit
    );

    return emissionId ? { emissionId, category } : null;
};

export const getConsumptionType = (type?: string): ConsumptionType => {
    switch (type) {
        case "elektricitet":
        case "electricity":
            return ConsumptionType.ENERGY_ELECTRICITY;
        case "fjernvarme":
        case "district heating":
            return ConsumptionType.ENERGY_HEATING_REMOTE;
        case "vand":
        case "water":
            return ConsumptionType.WATER;
        case "gas":
            return ConsumptionType.ENERGY_HEATING_FUELS_GAS;
        case "naturgas":
        case "natural gas":
            return ConsumptionType.ENERGY_HEATING_FUELS_GAS_NATURALGAS;
        case "flydende naturgas":
        case "liquified natural gas":
            return ConsumptionType.ENERGY_HEATING_FUELS_GAS_LIQUIFIEDNATURALGAS;
        case "køling":
        case "district chilled water":
            return ConsumptionType.ENERGY_COOLING_REMOTE;
        case "olie":
        case "oil":
        case "lpg":
            return ConsumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL;
        default:
            return ConsumptionType.UNCATEGORIZED;
    }
};

const getEmissionIdAndCategory = (
    fractions: Emission[],
    supplierLocations?: Asset["supplierLocations"],
    consumptionType?: string,
    consumptionUnit?: string
) => {
    if (!consumptionUnit || !consumptionType) {
        return null;
    }

    const type = consumptionType.toLowerCase();
    const unit = consumptionUnit.toLowerCase();

    if (supplierLocations) {
        const supplierEmissionAndCategory =
            getEmissionIdAndCategoryFromSuppliers(
                supplierLocations,
                type,
                unit
            );

        return supplierEmissionAndCategory
            ? supplierEmissionAndCategory
            : getEmissionIdAndCategoryFromFractions(type, unit, fractions);
    }

    return getEmissionIdAndCategoryFromFractions(type, unit, fractions);
};

const parseAssessmentData = (
    parsedCsvRows: ParsedCsvRow,
    assessmentDataType: AssessmentDataType
) => {
    if (assessmentDataType === AssessmentDataType.ACTUAL) {
        return parsedCsvRows.MeterID
            ? { hasError: false, data: { meterId: parsedCsvRows.MeterID } }
            : null;
    } else if (assessmentDataType === AssessmentDataType.ESTIMATE) {
        return {
            hasError:
                !parsedCsvRows.EPCIdentifier ||
                !parsedCsvRows.EPCClassification,
            data:
                parsedCsvRows.EPCIdentifier && parsedCsvRows.EPCClassification
                    ? {
                          epcIdentifier: parsedCsvRows.EPCIdentifier,
                          classification: parsedCsvRows.EPCClassification,
                          pdfLink: parsedCsvRows.ConsumptionSource || "",
                          buildingNumbers: parsedCsvRows.BuildingNumbers
                              ? _.chain(parsedCsvRows.BuildingNumbers)
                                    .split(",")
                                    .map((building) => {
                                        const parsed = parseInt(building, 10);
                                        return _.isFinite(parsed)
                                            ? parsed
                                            : null;
                                    })
                                    .compact()
                                    .value()
                              : [],
                          nationalIdentifiers: parsedCsvRows.NationalIdentifiers
                              ? _.chain(parsedCsvRows.NationalIdentifiers)
                                    .split(",")
                                    .compact()
                                    .value()
                              : [],
                      }
                    : null,
        };
    }
};

export const parseResults = (
    locations: Asset[],
    fractions: Emission[],
    parsedCsvRows: ParsedCsvRow[],
    assessmentDataType: AssessmentDataType
) => {
    const assessments: Array<CreateAssessmentInput> = [];
    const locationMetaData: Array<CreateLocationMetadataInput> = [];

    const rows: GridRowModel[] = parsedCsvRows.map((result, index) => {
        const location = getLocationId(locations, result.AssetID);
        const emissionIdAndCategory = getEmissionIdAndCategory(
            fractions,
            location?.supplierLocations,
            result.ConsumptionType,
            result.Unit
        );

        const from = parseDate(result.StartDate);
        const to = parseDate(result.EndDate);
        const consumption = parseConsumption(result.Consumption);
        const consumptionType = getConsumptionType(
            result.ConsumptionType?.toLowerCase()
        );
        const emissionId = emissionIdAndCategory?.emissionId;
        const category = emissionIdAndCategory?.category;
        const locationId = location?.id;
        const unit = getEmissionUnit(result.Unit);
        const isEpc = assessmentDataType === AssessmentDataType.ESTIMATE;
        const ownedBuildingConsumptionPercentage =
            isEpc && result.BuildingOwnership
                ? parseFloat(result.BuildingOwnership)
                : null;
        const dataInfo = parseAssessmentData(result, assessmentDataType);

        const isFromBeforeOrEqualTo =
            from && to && new Date(from) <= new Date(to);

        const buildingArea = result.Area
            ? parseFloat(result.Area.toString())
            : undefined;
        const address = JSON.stringify({
            zip: result.Zip,
            city: result.City,
            street: result.Address,
        });

        const buildingUse = result.BuildingType;
        const constructionDate = parseDate(result.YearOfConstruction);

        if (locationId && from && typeof consumption === "number" && category) {
            //mapping assessment for upload
            assessments.push({
                locationId,
                from,
                to,
                consumption,
                consumptionType,
                emissionId,
                category,
                unit,
                ownedBuildingConsumptionPercentage,
                data: dataInfo?.data ? JSON.stringify(dataInfo.data) : null,
            });
            locationMetaData.push({
                locationId,
                externalId: result.NationalIdentifiers,
                nationalIdentifier: result.NationalIdentifiers,
                buildingArea,
                address,
                buildingUse,
                constructionDate,
            });
        }

        const errors = {
            assetID: isEmpty(result.AssetID) || !locationId,
            consumptionType: isEmpty(result.ConsumptionType) || !emissionId, // we use consumption type to match emission, if we don't get an emission id, the consumption type isn't valid
            start: isEmpty(result.StartDate) || !from || !isFromBeforeOrEqualTo,
            end: isEmpty(result.EndDate) || !to || !isFromBeforeOrEqualTo,
            consumption: !isValidNumber(result.Consumption),
            unit: isEmpty(result.Unit) || !unit,
            data: isEpc
                ? {
                      source:
                          !result.ConsumptionSource ||
                          !isValidUrl(result.ConsumptionSource),
                      identifier:
                          !result.EPCIdentifier ||
                          isEmpty(result.EPCIdentifier),
                      classification:
                          !result.EPCClassification ||
                          isEmpty(result.EPCClassification),
                  }
                : {
                      meterId: isEmpty(result.MeterID),
                  },
            buildingOwnership:
                isEpc && !isValidNumber(result.BuildingOwnership),
            area: isEpc && !result.Area,
        };

        const hasErrors = Object.values(errors)
            .filter((error) => typeof error === "boolean")
            .some((error) => error);
        const assetDataHasErrors = Object.values(errors.data).some(
            (error) => error
        );

        return {
            ...result,
            ...locationMetaData,
            id: index,
            errors,
            hasErrors: hasErrors || assetDataHasErrors,
        };
    });

    return { rows, assessments, locationMetaData };
};
