import { gql } from "@apollo/client";

export const GET_EMISSIONS_QUERY = gql`
    query GetEmissions {
        emissions(paging: { first: 50 }, filter: { supplierId: { is: null } }) {
            edges {
                node {
                    id
                    name
                    internalDescriptor
                    type
                    unit
                }
            }
        }
    }
`;

export const GET_LOCATIONS_QUERY = gql`
    query GetLocations {
        me {
            id
            organization {
                id
                locations {
                    edges {
                        node {
                            supplierLocations {
                                emissionType
                                supplier {
                                    emissions {
                                        type
                                        unit
                                        id
                                    }
                                }
                            }
                            externalId
                            id
                            name
                            shortAddress
                            displayName
                            buildings {
                                name
                                shortAddress
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_MANY_ASSESSMENTS = gql`
    mutation CreateManyAssessments($input: CreateManyAssessmentsInput!) {
        createManyAssessments(input: $input) {
            id
        }
    }
`;

export const CREATE_ASSESSMENT_ORIGIN = gql`
    mutation CreateAssessmentOrigin($input: CreateOneAssessmentOriginInput!) {
        createOneAssessmentOrigin(input: $input) {
            id
        }
    }
`;

export const UPLOAD_FILE = gql`
    mutation UploadFile($file: Upload!) {
        uploadFile(file: $file) {
            id
            name
            url
        }
    }
`;

export const UPSERT_MANY_LOCATION_METADATA = gql`
    mutation UpsertManyLocationMetadata(
        $input: [CreateLocationMetadataInput!]!
    ) {
        upsertManyLocationMetadata(input: $input) {
            locationId
        }
    }
`;
