import { Box, Typography, styled } from "@mui/material";
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import SubHeaderWithTabs from "components/SubHeaderWithTabs/SubHeaderWithTabs";
import { AssessmentDataType } from "graphql-types/graphql";
import { useBenchmarkMarker } from "hooks/useBenchmarkMarker";
import { BenchmarksContext } from "utils/benchmarksContext";

import Overview from "./Pages/Overview";
import YearlyBreakdown from "./Pages/YearlyBreakdown";
import { PortfolioPage } from "./types";
import Loading from "../Loading/Loading";

const MainPortfolioContainer = styled(Box)(() => ({
    display: "flex",
    // eslint-disable-next-line no-useless-computed-key
    ["@media(max-width: 800px)"]: {
        flexDirection: "column",
    },
}));

const PortfolioDashboard = () => {
    const { t } = useTranslation();
    const { convertToBenchmarkMarker } = useBenchmarkMarker();

    const [activeTab, setActiveTab] = useState(AssessmentDataType.COMBINED);
    const [activePortfolioPage, setActivePortfolioPage] = useState(
        PortfolioPage.YEARLY_BREAKDOWN
    );

    const { benchmarks, loading, markers } = useContext(BenchmarksContext);

    const benchmarkMarkers = convertToBenchmarkMarker(activeTab, benchmarks);

    if (loading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    const chartBenchmarkMarkers = [
        ...benchmarkMarkers,
        ...markers.legacyBenchmarks,
    ];

    return (
        <Box>
            <Box marginBottom={12}>
                <Typography variant="h2">
                    {t(
                        "portfolioDashboard.header.title",
                        "Your Portfolio Overview"
                    )}
                </Typography>
                <Typography variant="h5" color="grey.400" mt={2}>
                    {t("portfolioDashboard.header.subTitle", "Last Updated")}:{" "}
                    {DateTime.local().plus({ days: -1 }).toLocaleString()}
                </Typography>

                <SubHeaderWithTabs
                    tab={{
                        active: activePortfolioPage,
                        onChange: setActivePortfolioPage,
                    }}
                    assessmentType={{
                        active: activeTab,
                        onChange: setActiveTab,
                    }}
                />
            </Box>

            <MainPortfolioContainer>
                {activePortfolioPage === PortfolioPage.YEARLY_BREAKDOWN && (
                    <YearlyBreakdown
                        activeTab={activeTab}
                        benchmarks={benchmarks}
                    />
                )}
                {activePortfolioPage === PortfolioPage.OVERVIEW && (
                    <Overview
                        activeTab={activeTab}
                        benchmarkMarkers={chartBenchmarkMarkers}
                    />
                )}
            </MainPortfolioContainer>
        </Box>
    );
};

export default PortfolioDashboard;
