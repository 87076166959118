import { TFunction } from "i18next";
import _ from "lodash";

import { ColorProps } from "components/AssessmentStats/types";
import { AssessmentVertical } from "graphql-types/graphql";

export const MandatoryVerticals = [
    AssessmentVertical.ELECTRICITY,
    AssessmentVertical.HEATING,
    AssessmentVertical.WATER,
];

export const VerticalPriority = [
    ...MandatoryVerticals,
    AssessmentVertical.COOLING,
    AssessmentVertical.WASTE,
];

export const assessmentVerticalToTitle = (
    assessmentType: AssessmentVertical | null | undefined,
    t: TFunction
) => {
    switch (assessmentType) {
        case AssessmentVertical.HEATING:
            return t("assessmentOverview.heat.title", "Heat", {
                ns: "translation",
            });
        case AssessmentVertical.COOLING:
            return t("assessmentOverview.cooling.title", "Cooling", {
                ns: "translation",
            });
        case AssessmentVertical.ELECTRICITY:
            return t("assessmentOverview.electricity.title", "Electricity", {
                ns: "translation",
            });
        case AssessmentVertical.MATERIALS:
            return t("assessmentOverview.materials.title", "Materials", {
                ns: "translation",
            });
        case AssessmentVertical.TRANSPORT:
            return t("assessmentOverview.transport.title", "Transport", {
                ns: "translation",
            });
        case AssessmentVertical.WASTE:
            return t("assessmentOverview.waste.title", "Waste", {
                ns: "translation",
            });
        case AssessmentVertical.WATER:
            return t("assessmentOverview.water.title", "Water", {
                ns: "translation",
            });
        case AssessmentVertical.ENERGY:
            return t("assessmentOverview.energy.title", "Energy Combined", {
                ns: "translation",
            });
        case AssessmentVertical.UN_CATEGORIZED:
            return t(
                "assessmentOverview.uncategorized.title",
                "Uncategorized",
                { ns: "translation" }
            );
        default:
            return "";
    }
};

export const assessmentVerticalToColor = (
    key: AssessmentVertical
): ColorProps => {
    switch (key) {
        case AssessmentVertical.TRANSPORT:
            return "transport";
        case AssessmentVertical.WASTE:
            return "waste";
        case AssessmentVertical.MATERIALS:
            return "materials";
        case AssessmentVertical.HEATING:
            return "heat";
        case AssessmentVertical.ELECTRICITY:
            return "electricity";
        case AssessmentVertical.WATER:
            return "water";
        case AssessmentVertical.COOLING:
            return "cooling";
        case AssessmentVertical.ENERGY:
            return "energy";
        case AssessmentVertical.UN_CATEGORIZED:
            return "uncategorized";
    }
};

const sortedVerticals = _.uniq([
    ...VerticalPriority,
    ...Object.values(AssessmentVertical),
]);

export const verticalSorter = <T extends { vertical: AssessmentVertical }>(
    a: T,
    b: T
) => {
    return (
        sortedVerticals.indexOf(a.vertical) -
        sortedVerticals.indexOf(b.vertical)
    );
};
