import { useTheme } from "@mui/material";
import { TFunction } from "i18next";
import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    Bar,
    CartesianGrid,
    Cell,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

import {
    getFormattedConvertedGramsToMassUnit,
    getYearLabel,
} from "components/PortfolioDashboard/Charts/charts.helper";
import ChartTooltip, {
    ChartDataItem,
} from "components/PortfolioDashboard/Charts/ChartTooltip";
import {
    getBenchmarkMarkerColor,
    marketBenchmarkLegendLabel,
} from "components/PortfolioDashboard/Charts/utils";
import { BenchmarkMarker, BenchmarkMarkers } from "components/types";
import { AssessmentDataType, BenchmarkType } from "graphql-types/graphql";
import { getCurrentYear } from "utils/date.utils";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { UnitMass } from "../../AssessmentStats/types";
import {
    getTotalEmissionBySize,
    getTotalForecastedEmissionBySize,
} from "../helpers/chartHelpers";
import { getNoDataGraphText } from "../helpers/emptyGraphs";
import { SummaryYearOverview } from "../types";

type Props = {
    activeTab: AssessmentDataType;
    assessmentSummaries: SummaryYearOverview[];
    benchmarkMarkers: BenchmarkMarkers;
};

type ChartData = {
    label: string | number;
    projectedValue: number | null;
    benchmarks: BenchmarkMarker[];
} & ChartDataItem;

export function getPortfolioIntensityChartData(
    t: TFunction,
    activeDataType: AssessmentDataType,
    locationsByYear: SummaryYearOverview[],
    benchmarkMarkers: BenchmarkMarkers
) {
    const markers = benchmarkMarkers.filter(
        (marker) =>
            marker.benchmarkType === BenchmarkType.BASELINE ||
            marker.benchmarkType === BenchmarkType.MARKET
    ) as BenchmarkMarker[];

    const portfolioIntensityChartData = _.chain(locationsByYear)
        .map((summedYear): ChartData | null => {
            const totalEmissionPerArea = getTotalEmissionBySize(
                summedYear.locations,
                activeDataType
            );
            if (!totalEmissionPerArea) return null;

            const formattedTotalEmissionPerArea =
                getFormattedConvertedGramsToMassUnit(
                    totalEmissionPerArea,
                    UnitMass.KILOGRAM,
                    t
                );

            const isCurrentYear = getCurrentYear() === summedYear.year;
            const projectedTotalEmissionPerArea = isCurrentYear
                ? getTotalForecastedEmissionBySize(summedYear)
                : null;
            const formattedProjectedTotalEmissionPerArea =
                getFormattedConvertedGramsToMassUnit(
                    projectedTotalEmissionPerArea,
                    UnitMass.KILOGRAM,
                    t
                );

            const benchmarks = markers.map((marker) => {
                return {
                    ...marker,
                    value: marker.value ? _.round(marker.value, 2) : null,
                };
            });

            return {
                label: getYearLabel(summedYear.year, t),
                title: t(
                    "portfolioDashboard.hoverBox.portIntensity",
                    "Portfolio Intensity"
                ),
                value: getConvertedGramsToMassUnit(
                    totalEmissionPerArea,
                    UnitMass.KILOGRAM
                ),
                formattedValue: formattedTotalEmissionPerArea,
                projectedValue: projectedTotalEmissionPerArea
                    ? getConvertedGramsToMassUnit(
                          projectedTotalEmissionPerArea,
                          UnitMass.KILOGRAM
                      )
                    : null,
                formattedProjectedValue: formattedProjectedTotalEmissionPerArea,
                header: summedYear.year,
                assetCount: summedYear.assetCount,
                totalArea: summedYear.totalArea,
                benchmarks,
            };
        })
        .compact()
        .value();

    return portfolioIntensityChartData;
}

export const getBenchmarkChartMarkers = (
    benchmarkMarkers: BenchmarkMarkers,
    isAssetChart: boolean,
    t: TFunction
) => {
    const markers = benchmarkMarkers.filter(
        (marker) =>
            marker.benchmarkType === BenchmarkType.BASELINE ||
            marker.benchmarkType === BenchmarkType.MARKET
    ) as BenchmarkMarker[];

    return markers.map((marker, index) => {
        const strokeColor = getBenchmarkMarkerColor(marker);
        const markerLabel = marketBenchmarkLegendLabel(marker, isAssetChart, t);

        return (
            <Line
                key={marker.id}
                hide={!marker.isVisible}
                name={markerLabel}
                type="monotone"
                dot={false}
                activeDot={false}
                dataKey={`benchmarks[${index}].value`}
                strokeWidth={2}
                stroke={strokeColor}
            />
        );
    });
};

function PortfolioIntensity(props: Props) {
    const theme = useTheme();

    const { activeTab, assessmentSummaries, benchmarkMarkers } = props;
    const { t } = useTranslation();

    const portfolioIntensityChartData = useMemo(() => {
        return getPortfolioIntensityChartData(
            t,
            activeTab,
            assessmentSummaries,
            benchmarkMarkers
        );
    }, [activeTab, benchmarkMarkers, t, assessmentSummaries]);

    return portfolioIntensityChartData.length > 0 ? (
        <ResponsiveContainer width="100%" height={446}>
            <ComposedChart
                data={portfolioIntensityChartData}
                style={{ cursor: "pointer" }}
            >
                <CartesianGrid
                    stroke={theme.palette.grey.A100}
                    strokeDasharray="100% 0.5"
                    vertical={false}
                />

                <YAxis
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    tick={{ fontSize: "10px" }}
                    label={{
                        value: `${t(
                            "portfolioDashboard.chartHelpers.kgCO2em2",
                            "kg CO2e / m²"
                        )}`,
                        fill: theme.palette.grey[600],
                        fontSize: "12px",
                        angle: -90,
                        position: "insideBottomLeft",
                        textAnchor: "start",
                        dx: 15,
                        props: { fontSize: "6px" },
                    }}
                />

                <Tooltip cursor={false} content={ChartTooltip} />

                <Legend
                    verticalAlign="bottom"
                    wrapperStyle={{ fontSize: "0.85rem", color: "grey" }}
                />

                <Bar
                    legendType="none"
                    radius={[5, 5, 0, 0]}
                    barSize={40}
                    name="Projected Emissions"
                    dataKey="projectedValue"
                    fill={theme.palette.primary.light}
                    xAxisId={0}
                />

                <Bar
                    legendType="none"
                    radius={[5, 5, 0, 0]}
                    barSize={40}
                    dataKey="value"
                    xAxisId={1}
                >
                    {portfolioIntensityChartData.map((index) => (
                        <Cell
                            fill={theme.palette.primary.main}
                            key={`cell-${index}`}
                        />
                    ))}
                </Bar>

                {getBenchmarkChartMarkers(benchmarkMarkers, false, t)}

                <XAxis
                    dataKey="label"
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    xAxisId={1}
                    dy={5}
                    tick={{ fontSize: "14px" }}
                />
                <XAxis
                    dataKey="label"
                    tickLine={false}
                    stroke={theme.palette.grey[400]}
                    xAxisId={0}
                    hide
                />
            </ComposedChart>
        </ResponsiveContainer>
    ) : (
        getNoDataGraphText(t)
    );
}

export default PortfolioIntensity;
